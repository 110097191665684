import { DIAMOND_DETAILS_ERROR, DIAMOND_DETAILS_RESET, DIAMOND_DETAILS_SUCCESS, GET_DASHBOARD_DETAILS_ERROR, GET_DASHBOARD_DETAILS_RESET, GET_DASHBOARD_DETAILS_SUCCESS, GET_MASTERS_LIST_ERROR, GET_MASTERS_LIST_RESET, GET_MASTERS_LIST_SUCCESS, GET_SLOT_ERROR, GET_SLOT_RESET, GET_SLOT_SUCCESS, NEW_ARRIVAL_LIST_ERROR, NEW_ARRIVAL_LIST_RESET, NEW_ARRIVAL_LIST_SUCCESS } from "../common/constant";

let initialState = {
  masterList: {
    error: false,
    data: null,
    errors: null,
    success: false,
  },
  diamondDetails: {
    error: false,
    data: null,
    errors: null,
    success: false,
  },
  slotDetails: {
    error: false,
    data: null,
    errors: null,
    success: false,
  },
  getNewArrivalList: {
    error: false,
    data: null,
    errors: null,
    success: false,
    count: 0,
    details: null
  },
  getDashboardDetails: {
    error: false,
    data: null,
    errors: null,
    success: false,
  },
};

export default function masterReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MASTERS_LIST_SUCCESS:
      return { ...state, masterList: { ...state.masterList, success: true, error: false, errors: null, data: action.response }, };

    case GET_MASTERS_LIST_ERROR:
      return { ...state, masterList: { ...state.masterList, success: false, error: true, errors: action.msg } };

    case GET_MASTERS_LIST_RESET:
      return { ...state, masterList: { ...state.masterList, success: false, error: false, errors: null } };


    //  diamond details
    case DIAMOND_DETAILS_SUCCESS:
      return { ...state, diamondDetails: { ...state.diamondDetails, success: true, error: false, errors: null, data: action.response }, };

    case DIAMOND_DETAILS_ERROR:
      return { ...state, diamondDetails: { ...state.diamondDetails, success: false, error: true, errors: action.msg } };

    case DIAMOND_DETAILS_RESET:
      return { ...state, diamondDetails: { ...state.diamondDetails, success: false, error: false, errors: null } };

    //  slot details
    case GET_SLOT_SUCCESS:
      return { ...state, slotDetails: { ...state.slotDetails, success: true, error: false, errors: null, data: action.response }, };

    case GET_SLOT_ERROR:
      return { ...state, slotDetails: { ...state.slotDetails, success: false, error: true, errors: action.msg } };

    case GET_SLOT_RESET:
      return { ...state, slotDetails: { ...state.slotDetails, success: false, error: false, errors: null } };

    //  get New ArrivalList
    case NEW_ARRIVAL_LIST_SUCCESS:
      return { ...state, getNewArrivalList: { ...state.getNewArrivalList, success: true, error: false, errors: null, count: action.count, data: action.response, details: action.details }, };

    case NEW_ARRIVAL_LIST_ERROR:
      return { ...state, getNewArrivalList: { ...state.getNewArrivalList, success: false, error: true, errors: action.msg } };

    case NEW_ARRIVAL_LIST_RESET:
      return { ...state, getNewArrivalList: { ...state.getNewArrivalList, success: false, error: false, errors: null } };

    //  get dashboard
    case GET_DASHBOARD_DETAILS_SUCCESS:
      return { ...state, getDashboardDetails: { ...state.getDashboardDetails, success: true, error: false, errors: null, data: action.response }, };

    case GET_DASHBOARD_DETAILS_ERROR:
      return { ...state, getDashboardDetails: { ...state.getDashboardDetails, success: false, error: true, errors: action.msg } };

    case GET_DASHBOARD_DETAILS_RESET:
      return { ...state, getDashboardDetails: { ...state.getDashboardDetails, success: false, error: false, errors: null } };

    default:
      return state;
  }
}