import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import imageurl from "../images";
import { marketingUser } from "../userStore/action/authAction";
import { connect } from "react-redux";

const Footer = (props) => {
  const [show, setShow] = useState(false);
  const [isShown, setIsShown] = useState(true);
  const handleClose = () => {
    setShow(false);
    setIsShown(false);
    localStorage.setItem("promotionPopup", true);
  };
  const promotionPopup = localStorage.getItem("promotionPopup");

  useEffect(() => {
    setTimeout(function () {
      if (promotionPopup.toString() === "false") {
        setShow(true);
      }
    }, 5000);
  }, [promotionPopup]);

  useEffect(() => {
    props.marketingUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const marketingbanner = props?.marketinguser?.data;
  return (
    <>
      <footer className="container-fluid text-center pb-0">
        <img
          src={imageurl.logo}
          className="img-fluid logo"
          alt="Delight Diamonds"
        />

        <ul className="footer-links text-white">
          <li>
            <Link to="/about" className="footer-li">
              About Us
            </Link>
          </li>
          <li>
            <Link to="/contact" className="footer-li">
              Contact Us
            </Link>
          </li>
          <li>
            <Link to="/terms-condition" className="footer-li">
              Terms & Conditions
            </Link>
          </li>
          <li>
            <Link to="/privacy-policy" className="footer-li">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/return-policy" className="footer-li">
              Return Policies
            </Link>
          </li>
          <li>
            <Link to="/vendor/register" className="footer-li">
              Vendor
            </Link>
          </li>
          <li>
            <Link to="/merchant/register" className="footer-li">
              Merchant
            </Link>
          </li>
        </ul>

        <Container className="copyright-sec">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} Copyright. All Rights Reserved.
          </p>
          <div className="social-link">
            <a href="https://api.whatsapp.com/send?phone=+919825970060">
              <i className="fa-brands fa-whatsapp"></i>
            </a>
            <a href="https://www.facebook.com/delightdiamonds">
              <i className="fa-brands fa-facebook-f"></i>
            </a>
            <a href="https://www.facebook.com/delightdiamonds">
              <i className="fa-brands fa-twitter"></i>
            </a>
            <a href="https://www.facebook.com/delightdiamonds">
              <i className="fa-brands fa-youtube"></i>
            </a>
          </div>
        </Container>
      </footer>
      {marketingbanner && marketingbanner[0]?.status === 1 && isShown && (
        <>
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            centered
            className="promotional-popup"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="p-0">
              <img
                src={marketingbanner[0]?.image}
                className="img-fluid"
                alt="promotion"
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  profileReducer: state.authReducer.profile,
  policyReducer: state.authReducer.policy,
  loginReducer: state.authReducer.login,
  registerReducer: state.authReducer.register,
  marketinguser: state.authReducer.marketinguser,
});
const mapDispatchToProps = (dispatch) => ({
  marketingUser: () => dispatch(marketingUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
