import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import imageurl from "../images";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

const LabGrownDiamond = () => {
  return (
    <>
      <Header />
      <Container fluid className="banner">
        <Container>
          <h2 className="pageName" data-aos="zoom-out" data-aos-duration="1000">
            Lab Grown Diamonds
          </h2>
        </Container>
      </Container>
      <main>
        <section className="container">
          <Row className="align-items-center ">
            <Col lg={6}>
              <h2
                className="heading mb-4"
                data-aos="zoom-in-up"
                data-aos-duration="1000"
              >
                About Lab Grown Diamonds
              </h2>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                Lab-grown diamonds are those which are created in Labs and not
                mined naturally. But that doesn't mean that they're less
                precious or anything. In fact, they are as exquisite as the real
                ones. Also, they are affordable and leave no carbon footprint
                behind, which means that they are created without harming the
                environment in any manner.
              </p>

              <p data-aos="zoom-in-up" data-aos-duration="1000">
                When Lab Created Diamonds made their way into our lives, they
                changed the entire industrial market setup and the jewelry
                market. The best part about Lab-grown diamonds is that they can
                be marvellously shaped into any form desired without causing any
                harm to the stone.
              </p>
            </Col>
            <Col lg={6} data-aos="zoom-in-up" data-aos-duration="1000">
              <img
                src={imageurl.labGrown}
                className="img-fluid mb-4"
                alt="Lab grown"
                style={{ boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px" }}
              />
            </Col>
          </Row>

          {/* Diamond Comparision */}
          <h4 className="text-center mt-5 fw-semibold">
            Comparing Natural And Lab-made Diamonds
          </h4>
          <div className="table-responsive mt-4 col-xl-8 mx-auto">
            <table
              className="table"
              data-aos="flip-up"
              data-aos-duration="1000"
            >
              <thead>
                <tr>
                  <th>{/* Particulars */}</th>
                  <th>Lab Made Diamonds</th>
                  <th>Natural Diamonds</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Conflict-free diamonds</th>
                  <td>Yes</td>
                  <td>Unsure</td>
                </tr>
                <tr>
                  <th>Authentic diamond</th>
                  <td>100%</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <th>Origins</th>
                  <td>Made in Laboratory</td>
                  <td>Found in Mines</td>
                </tr>
                <tr>
                  <th>Environment Friendly</th>
                  <td>Yes</td>
                  <td>No</td>
                </tr>
                <tr>
                  <th>Quality</th>
                  <td>Flawlessly created</td>
                  <td>May/may not possess quality standards</td>
                </tr>
                <tr>
                  <th>Customizable</th>
                  <td>Yes</td>
                  <td>Unsure</td>
                </tr>
                <tr>
                  <th>Longevity</th>
                  <td>Forever</td>
                  <td>Forever</td>
                </tr>
                <tr>
                  <th>Artificial</th>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <th>Fading Brilliance/Shine</th>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <th>True Diamond</th>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <th>Affordability</th>
                  <td>Economical</td>
                  <td>Expensive</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section className="container">
          <h2
            className="heading"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            How are Lab Grown Diamonds Created
          </h2>
          <p data-aos="zoom-in" data-aos-duration="1000">
            Grown diamonds are made one of two ways - either through High
            Pressure High Temperature (HPHT), or through Chemical Vapor
            Deposition (CVD).
          </p>

          {/* HPHT Diamonds methods */}
          <h3 className="mt-5 fw-semibold">HPHT Diamonds Method</h3>
          <Row>
            <Col lg={5} data-aos="zoom-in-up" data-aos-duration="1000">
              <img
                src={imageurl.hphtDiamond}
                className="img-fluid mb-4"
                alt="hpht Diamond"
              />
            </Col>
            <Col lg={7}>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                How does nature form diamond crystals? Nature does it very deep
                underground. Diamond growing naturally takes place about 100
                miles below the earth’s surface.
              </p>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                Why? Because that’s where the conditions of extremely high
                pressure and high temperature needed for diamond formation are
                naturally found. We see diamonds closer to the surface of the
                earth when a rare kind of deep volcano brings them up to where
                humans can find and mine them.
              </p>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                The first successful synthetic diamond was made by imitating
                nature with High Pressure/High Temperature (HPHT) manufacturing.
                There are three basic manufacturing processes used to make HPHT
                diamonds: the belt press, the cubic press, and, therefore, the
                split-sphere (BARS) press.
              </p>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                The goal of every process is to make an environment of extremely
                high and temperature where diamond growth can occur. Each
                process starts with a little diamond seed, which is placed in
                carbon and put under very high pressure and temperature to grow
                the diamond.
              </p>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                The GE invention of the belt press uses an upper and lower anvil
                to use pressure over 1.5 million pounds per sq. inches and
                temperature above 2,000 degrees Celsius. During this
                environment, the pure carbon melts and begins to make a diamond
                around the beginner seed.
              </p>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                On December 16, 1954, a belt press was applied to make the first
                reproducible lab-grown diamond. The diamond was tiny and nowhere
                near clear enough to be utilized in jewelry, but it had been a
                start.
              </p>
            </Col>
          </Row>
          <p data-aos="zoom-in-up" data-aos-duration="1000">
            As a side note here, we should always mention that even today, most
            of the man-made diamonds that are produced aren’t of gem quality.
            They’re made for industrial applications where the amazing
            properties of a diamond (hardness, thermal conductivity, etc.) are
            used to make better tools and equipment.
          </p>
          <p data-aos="zoom-in-up" data-aos-duration="1000">
            Since the initial success with the belt press, HPHT technology has
            constantly been advancing. Belt press designs are dramatically
            scaled up, more modern cubic and split-sphere presses are
            introduced, which are more efficient, and bigger, more perfect gems
            are being produced.
          </p>

          <h4 className="mt-5 fw-semibold">
            The Elements And Material Utilized To Create A Lab Grown Loose
            Diamonds Are:
          </h4>
          <p data-aos="zoom-in-up" data-aos-duration="1000">
            A small diamond seed.
          </p>
          <p data-aos="zoom-in-up" data-aos-duration="1000">
            Highly refined graphite.
          </p>
          <p data-aos="zoom-in-up" data-aos-duration="1000">
            A high-quality enzyme mixture containing metals and powders.
          </p>
          <p data-aos="zoom-in-up" data-aos-duration="1000">
            When the machine is switched on, due to high pressure and high
            temperature, the catalyst mixture turns in to a molten form. Which
            then produces the refined graphite to dissolve in it, and forms
            various layers over the diamond seed. Then after the cooling is
            completed, an HPHT diamond has been formed.
          </p>
          <p data-aos="zoom-in-up" data-aos-duration="1000">
            Carry out the HPHT Diamond making method, and apply the required
            conditions, two kinds of machines or press designs are used.
          </p>
          <p data-aos="zoom-in-up" data-aos-duration="1000">
            Cubic Press- Creates high temperature and pressure with the help of
            pistons, which are located throughout the machine and supply
            pressure from different directions.
          </p>
          <p data-aos="zoom-in-up" data-aos-duration="1000">
            Belt Press- during this machine, high pressure is made by two
            strength pistons. These pistons apply equal pressure in opposite
            directions.
          </p>

          {/* CVD Diamonds Method */}
          <h3 className="mt-5 fw-semibold">CVD Diamonds Method</h3>
          <Row>
            <Col
              lg={5}
              data-aos="zoom-in-up"
              data-aos-duration="1000"
              style={{ marginTop: "40px" }}
            >
              <img
                src={imageurl.CVDImg}
                className="img-fluid mb-4"
                alt="CVD Icon"
              />
            </Col>
            <Col lg={7}>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                Chemical Vapor Deposition (CVD) may be a more recently developed
                technique by which diamonds are often grown from a hydrocarbon
                gas mixture. Within the CVD Diamond process, a thin slice of
                diamond seed (often an HPHT produced diamond) is placed during a
                sealed chamber and heated to around 800 degrees Celsius. The
                chamber is then filled with a carbon-rich gas (usually methane)
                alongside other gases. Then, the gases are ionized into plasma
                using microwaves, lasers, or another technique. The ionization
                split the molecular bonds within the gases, and the pure carbon
                adheres to the diamond seed and slowly builds up into a crystal,
                atom by atom, layer by layer.
              </p>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                The CVD Diamond process has a few advantages over the HPHT
                process. High pressures aren’t needed, and temperatures, while
                high, don’t get to be anywhere near as high as with HPHT. Also,
                CVD diamonds can be grown over larger areas by starting with a
                bigger diamond seed plate.
              </p>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                Finally, the CVD Diamond process allows for finer control over
                the environment within the growth chamber and, thus, the
                properties of the polished diamond.
              </p>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                Within six to 12 weeks, a sizeable rough diamond is formed. It
                is then cut, polished and graded by the same world- renowned
                labs that certify earth-mined diamonds.
              </p>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                Colorless and near-colorless CVD diamonds are usually type IIa,
                while only 2% of natural gem diamonds are type IIa. Type IIa
                diamonds are characterized by high purity (low nitrogen) and
                transparency in specific parts of the ultraviolet and infrared
                optical spectrum. Numerous UV detection instruments on the
                market can provide the first indication of a possible CVD
                diamond.
              </p>
            </Col>
          </Row>
          <p data-aos="zoom-in-up" data-aos-duration="1000">
            Intense research is ongoing around the world into the field of
            Chemical Vapour Deposition, and each day the technology is
            improving. CVD diamonds can be grown in a range of colors and sizes,
            with high clarity. The gemstones produced are as beautiful and
            durable as natural diamond, and they are expected to fill an
            important market niche in the near future.
          </p>

          <h4 className="mt-5 fw-semibold">
            CVD Diamond Method Also Has Some Kinds, Which Are:
          </h4>
          <ul>
            <li data-aos="zoom-in-up" data-aos-duration="1000">
              <p>Plasma Enhanced Chemical Vapour Decomposition(PECVD)</p>
            </li>
            <li data-aos="zoom-in-up" data-aos-duration="1000">
              <p>Microwave Plasma Chemical Vapour Decomposition(MPCVD)</p>
            </li>
            <li data-aos="zoom-in-up" data-aos-duration="1000">
              <p>Low-pressure Chemical Vapour Decomposition(LPCVD)</p>
            </li>
            <li data-aos="zoom-in-up" data-aos-duration="1000">
              <p>Ultra-High Vacuum Chemical Vapour Decomposition(UHVCVD)</p>
            </li>
          </ul>
        </section>

        {/* Diffrence between lab grown and NAtural */}
        <section className="container">
          <h2 className="heading">
            What's the difference between Lab-Grown Diamonds and Natural
            Diamonds?
          </h2>
          <Row className="align-items-center">
            <Col lg={6}>
              <p data-aos="zoom-in-up" data-aos-duration="1000">
                Both mined and lab-grown diamonds are made of pure carbon that
                is transformed into sparkling cubic crystalline stones. While
                their chemical foundation gives lab-grown diamonds the same
                optical properties as natural diamonds, lab-grown diamonds have
                no intrinsic rarity. Formed over millennia, natural diamonds
                acquire their scintillating shapes from the pressure of being
                compacted deep within the earth's crust. Because of this, all of
                the natural diamonds that will ever exist have already been
                created making them forever rarities. Laboratory-grown diamonds,
                on the other hand, can be produced limitlessly.
              </p>
            </Col>
            <Col lg={6} data-aos="zoom-in-up" data-aos-duration="1000">
              <img
                src={imageurl.LabVSNatural}
                className="img-fluid mb-4"
                alt="Lab grown & Natural"
              />
            </Col>
          </Row>
        </section>

        <section className="container">
          {/* Diamond Comparision */}
          <h2 className="heading">Comparing Natural And Lab-made Diamonds</h2>
          <div className="table-responsive mt-4">
            <table
              className="table"
              data-aos="flip-up"
              data-aos-duration="1000"
            >
              <thead>
                <tr>
                  <th>Properties</th>
                  <th>Natural diamonds</th>
                  <th>Laboratory-grown diamonds</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Chemical composition</th>
                  <td>C</td>
                  <td>C</td>
                </tr>
                <tr>
                  <th>Crystalline structure</th>
                  <td>Cubic</td>
                  <td>Cubic</td>
                </tr>
                <tr>
                  <th>Refractive index</th>
                  <td>2.42</td>
                  <td>2.42</td>
                </tr>
                <tr>
                  <th>Dispersion</th>
                  <td>0.044</td>
                  <td>0.044</td>
                </tr>
                <tr>
                  <th>Hardness</th>
                  <td>10</td>
                  <td>10</td>
                </tr>
                <tr>
                  <th>Density</th>
                  <td>3.42</td>
                  <td>3.42</td>
                </tr>
                <tr>
                  <th>Energy use</th>
                  <td>Around 57 kWh</td>
                  <td>Around 26 kWh</td>
                </tr>
                <tr>
                  <th>Scarcity</th>
                  <td>Very scarce, especially as this is</td>
                  <td>No real scarcity</td>
                </tr>
                <tr>
                  <th>Long-term value</th>
                  <td>Stable long-term value</td>
                  <td>Decreasing long-term value</td>
                </tr>
                <tr>
                  <th>Blue Fluorescence</th>
                  <td>Depending from Strong to none</td>
                  <td>None</td>
                </tr>
                <tr>
                  <th>Phosphorescence</th>
                  <td>No</td>
                  <td>Some</td>
                </tr>
                <tr>
                  <th>
                    Average price(Round, 1ct, G colour, excellent cut, VS2)
                  </th>
                  <td>$5500</td>
                  <td> $450</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        {/* Myths & Facts  */}
        <section className="container">
          <h5 data-aos="fade-right" data-aos-duration="1000">
            MYTH: Lab Grown Diamonds are artificial.
          </h5>
          <p data-aos="zoom-in-up" data-aos-duration="1000" className="mb-4">
            FACT: Although Lab Grown Diamonds are referred to as synthetic
            diamonds, in reality, they are as pure as the natural ones. These
            are called so because of the material/chemical that is used to
            imitate the natural ones. Lab Grown Diamonds consist of 100% carbon
            and in fact, they are born from carbon seeds of already existing
            natural diamonds.
          </p>

          <h5 data-aos="fade-right" data-aos-duration="1000">
            MYTH: Lab Grown Diamonds are not real diamonds.
          </h5>
          <p data-aos="zoom-in-up" data-aos-duration="1000" className="mb-4">
            FACT: There is no real difference between Natural Diamonds and
            Lab-made diamonds. Instead of a diamond being created under the
            surface of the earth due to extreme conditions, it is created in a
            Lab where the same conditions are mimicked. The place where a
            diamond is created only changes, whereas the chemical composition
            and authenticity remains the same.
          </p>

          <h5 data-aos="fade-right" data-aos-duration="1000">
            MYTH: Lab Grown Diamonds are not pure like Natural diamonds.
          </h5>
          <p data-aos="zoom-in-up" data-aos-duration="1000" className="mb-4">
            FACT: It is in fact reversed. Purity in natural diamonds is
            difficult to be found compared to the one grown in a Lab. Only 2% of
            the natural diamonds have the same level of purity as the ones grown
            in the Lab.
          </p>

          <h5 data-aos="fade-right" data-aos-duration="1000">
            MYTH: Lab Grown Diamonds are very cheap to manufacture.
          </h5>
          <p data-aos="zoom-in-up" data-aos-duration="1000" className="mb-4">
            FACT: There is a lot that goes while mining for diamonds under the
            earth. One has to literally go down 150kms under the earth to mine
            for diamonds. With so much digging and mining, the amount of
            resources that are wasted is unimaginable. Why undergo so much when
            one can easily create diamonds with the same chemical composition in
            a Lab. But to even create diamonds in a Lab is not easy as it takes
            skilled chemists, physicists and PhD holders to create the perfect
            environment for the diamond to form.
          </p>

          <h5 data-aos="fade-right" data-aos-duration="1000">
            MYTH: It is not easy to determine that a Lab Grown diamond is
            conflict-free.
          </h5>
          <p data-aos="zoom-in-up" data-aos-duration="1000" className="mb-4">
            FACT: Every man-made diamond is conflict-free. After being sourced
            from first-world countries, these diamonds are carefully cut and
            polished in modern Labs. On the other hand, natural diamonds are
            required to be vetted in order to make them conflict-free.
          </p>

          <h5 data-aos="fade-right" data-aos-duration="1000">
            MYTH: Lab-grown diamonds harm the environment.
          </h5>
          <p data-aos="zoom-in-up" data-aos-duration="1000" className="mb-4">
            FACT: It is true that Lab diamonds may not be very ecofriendly. But
            when one compares it to the natural ones, Lab Grown Diamonds are
            least destructive to nature. Mining diamonds from the crust of the
            earth takes up a lot of resources which can damage the earth as well
            the air. Comparatively, man-made diamonds do not harm the
            environment to such a large extent.
          </p>

          <h5 data-aos="fade-right" data-aos-duration="1000">
            MYTH: Lab Grown Diamonds may change in appearance as time passes.
          </h5>
          <p data-aos="zoom-in-up" data-aos-duration="1000" className="mb-4">
            FACT: As man-made diamonds have similar physical, optical and
            chemical properties as the natural ones, it is not true that their
            appearance will change. Although American diamonds usually do get
            cloudy over a period of time, they are not the same as Lab Grown
            Diamonds and in fact, they are cubic zirconia, which means they are
            not even real diamonds.
          </p>

          <h5 data-aos="fade-right" data-aos-duration="1000">
            MYTH: There is no resale value of man-made diamonds.
          </h5>
          <p data-aos="zoom-in-up" data-aos-duration="1000" className="mb-4">
            FACT: Since Lab Grown Diamonds are relatively new to the market they
            are yet to find their place in the resale scenario. Plus, the
            process of mining is highly expensive since it is more difficult to
            retrieve diamonds 100s of miles below the earth. Lab-grown diamonds
            may yet not be considered in re-sale value, they are still real
            diamonds and they have their own blunt costs.
          </p>

          <h5 data-aos="fade-right" data-aos-duration="1000">
            MYTH: Lab Grown diamonds lose their value over time.
          </h5>
          <p data-aos="zoom-in-up" data-aos-duration="1000" className="mb-4">
            FACT: Diamonds that are mined undergo a lot more exchanges, unlike
            the man-made ones which have a transparent supply chain. Lab Grown
            Diamonds can be replaced or changed whenever required.
          </p>

          <h5 data-aos="fade-right" data-aos-duration="1000">
            MYTH: Diamonds mined from the crust of the earth are very rare.
          </h5>
          <p data-aos="zoom-in-up" data-aos-duration="1000" className="mb-4">
            FACT: This is false as diamonds are found in abundance below the
            surface of the earth. Although retrieving them is time- consuming,
            that does not mean that they are hardly found. In fact, the market
            is to be blamed for creating such a false perception so that they
            can amplify the prices.
          </p>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default LabGrownDiamond;
