import React, { useEffect, useState } from "react";
import { getSelectedState, Grid, GridColumn } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-data-query";
import StonePreviewModal from "./modals/StonePreviewModal";
import StoneVideoPreviewModal from "./modals/StoneVideoPreviewModal";
import PdfModal from "./modals/PdfModal";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  NEW_ARRIVAL_LIST_RESET,
  SEARCH_DIAMONDS_RESET,
} from "../userStore/common/constant";
import { searchDiamonds } from "../userStore/action/labgrownAction";
import Loader from "../Components/Loader";
import { getNewArrivalList } from "../userStore/action/masterAction";
import { toast } from "react-toastify";
import { Skeleton } from "@progress/kendo-react-indicators";

// const DATA_ITEM_KEY = "stone_id";
const DATA_ITEM_KEY = "Index";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const SearchTable = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [imgShow, setImgShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [videoShow, setVideoShow] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [certificateShow, setCertificateShow] = useState(false);
  const [selectedCert, setSelectedCert] = useState("");
  const requestInProgress = React.useRef(false);
  const pageSize = 25;
  const [total, setTotal] = useState(60);
  const [diamonds, setDiamonds] = React.useState([]);
  const [finalResult, setFinalResult] = React.useState([]);
  const [page, setPage] = React.useState({
    skip: 0,
    take: pageSize,
  });
  const [selectedState, setSelectedState] = React.useState({});
  useEffect(() => {
    if (!!location?.state?.selected) {
      setSelectedState(location?.state?.selected);
    }
  }, [location]);

  const [dataState, setDataState] = React.useState(
    diamonds?.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    )
  );

  useEffect(() => {
    const list = diamonds?.map((dataItem, i) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    );
    setDataState([...list]);
  }, [diamonds]);

  useEffect(() => {
    props.setSelected(selectedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  // Search Diamond api call
  const searchDiamondsList = (pageCount = true, pageSkip = 0) => {
    // const searchedData = location?.state?.state;
    // props.searchDiamonds(searchedData, pageCount, pageSkip);
    props.searchDiamonds(
      {
        stone_id: "",
        location: [],
        shape_id: [],
        size_id: [],
        color_id: [],
        colors_id: [],
        overtone_id: [],
        intensity_id: [],
        clarity_id: [],
        cut_id: [],
        polish_id: [],
        symmetry_id: [],
        fluorescence_id: [],
        fluorescence_color_id: [],
        bgm_id: [],
        lab: [],
        diamond_type: [],
        carat_from: "",
        carat_to: "",
        ct_from: "",
        ct_to: "",
        discount_from: "",
        discount_to: "",
        value_from: "",
        value_to: "",
        length_from: "",
        length_to: "",
        width_from: "",
        width_to: "",
        height_from: "",
        height_to: "",
        table_from: "",
        table_to: "",
        depth_from: "",
        depth_to: "",
        ratio_from: "",
        ratio_to: "",
      },
      pageCount,
      pageSkip
    );
  };

  // // new_arrival Data Fetching
  useEffect(() => {
    if (
      props.page === "new_arrival" &&
      props.getNewArrivalListReducer.success
    ) {
      requestInProgress.current = false;
      const data = props.getNewArrivalListReducer?.data;
      const totalCount = props.getNewArrivalListReducer?.count;
      const finaldata =
        diamonds?.length === totalCount
          ? [...diamonds]
          : new Array(totalCount).fill({}).map((e, i) => ({
              Index: i,
            }));
      const selected =
        Object.values(selectedState).length === totalCount
          ? Object.values(selectedState)
          : new Array(totalCount).fill(false);

      data.forEach((e, i) => {
        finaldata[i + skipTrack] = {
          Index: i + skipTrack,
          srNo: i + 1,
          video: e.video ? e.video : "N/A",
          image: e.image ? e.image : "N/A",
          cert_url: e.cert_url ? e.cert_url : "N/A",
          stone_id: e.stone_id ? e.stone_id : "N/A",
          cert_no: e.cert_no ? e.cert_no : "N/A",
          cert: e.cert ? e.cert : "N/A",
          country: e.country ? e.country.toUpperCase() : "N/A",
          growth_type: e.growth_type ? e.growth_type : "N/A",
          shape: e.shape_name ? e.shape_name : "N/A",
          color: e.color_name ? e.color_name : "N/A",
          Overtone: e.overtone_name ? e.overtone_name : "N/A",
          intensity: e.intensity_name ? e.intensity_name : "N/A",
          clarity: e.clarity_name ? e.clarity_name : "N/A",
          cut: e.cut_name ? e.cut_name : "N/A",
          polish: e.polish_name ? e.polish_name : "N/A",
          symmetry: e.symmetry_name ? e.symmetry_name : "N/A",
          rapRate: e.rapo_rate ? e.rapo_rate : "N/A",
          disc: e.discount ? e.discount : "N/A",
          carat: e.carat && e.carat !== "" ? e.carat : "N/A",
          amount: e.amount ? e.amount : "N/A",
          measurement: e.measurement ? e.measurement : "N/A",
          ratio: e.ratio ? e.ratio : "N/A",
          rate: e.rate ? e.rate : "N/A",
          depth: e.depth_per ? e.depth_per : "N/A",
          table: e.table_per ? e.table_per : "N/A",
          bgm: e.bgm_name ? e.bgm_name : "N/A",
          fluorescence: e.fluorescence_name ? e.fluorescence_name : "N/A",
          fancy_color: e.colors_name ? e.colors_name : "N/A",
          key_to_symbols: !!e.key_to_symbols ? e.key_to_symbols : "N/A",
          id: e.id,
          selected: false,
        };
      });

      const selectedObject = {};
      selected.forEach((e, i) => {
        selectedObject[i] = e;
      });
      setDiamonds(finaldata);
      setSelectedState(selectedObject);
      setFinalResult(finaldata);
      setTotal(totalCount);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getNewArrivalListReducer]);

  // Searched data Fetching
  useEffect(() => {
    if (props.page !== "new_arrival" && props.searchDiamondsReducer.success) {
      requestInProgress.current = false;
      const data = props.searchDiamondsReducer?.data;
      const totalCount = props.searchDiamondsReducer?.count;
      const finaldata =
        diamonds?.length === totalCount
          ? [...diamonds]
          : new Array(totalCount).fill({}).map((e, i) => ({
              Index: i,
            }));

      // const selected =
      //   selectedState?.length === totalCount
      //     ? [...selectedState]
      //     : new Array(totalCount).fill(false);

      const selected =
        Object.values(selectedState).length === totalCount
          ? Object.values(selectedState)
          : new Array(totalCount).fill(false);
      data.forEach((e, i) => {
        finaldata[i + skipTrack] = {
          Index: i + skipTrack,
          srNo: i + 1,
          video: e.video ? e.video : "N/A",
          image: e.image ? e.image : "N/A",
          cert_url: e.cert_url ? e.cert_url : "N/A",
          stone_id: e.stone_id ? e.stone_id : "N/A",
          cert_no: e.cert_no ? e.cert_no : "N/A",
          cert: e.cert ? e.cert : "N/A",
          country: e.country ? e.country.toUpperCase() : "N/A",
          growth_type: e.growth_type ? e.growth_type : "N/A",
          shape: e.shape_name !== "" ? e.shape_name : "N/A",
          color: e.color_name ? e.color_name : "N/A",
          Overtone: e.overtone_name ? e.overtone_name : "N/A",
          intensity: e.intensity_name ? e.intensity_name : "N/A",
          clarity: e.clarity_name ? e.clarity_name : "N/A",
          cut: e.cut_name ? e.cut_name : "N/A",
          polish: e.polish_name ? e.polish_name : "N/A",
          symmetry: e.symmetry_name ? e.symmetry_name : "N/A",
          rapRate: e.rapo_rate ? e.rapo_rate : "N/A",
          disc: e.discount ? e.discount : "N/A",
          carat: e.carat && e.carat !== "" ? e.carat : "N/A",
          amount: e.amount ? e.amount : "N/A",
          measurement: e.measurement ? e.measurement : "N/A",
          ratio: e.ratio ? e.ratio : "N/A",
          rate: e.rate ? e.rate : "N/A",
          depth: e.depth_per ? e.depth_per : "N/A",
          table: e.table_per ? e.table_per : "N/A",
          bgm: e.bgm_name ? e.bgm_name : "N/A",
          fluorescence: e.fluorescence_name ? e.fluorescence_name : "N/A",
          fancy_color: e.colors_name ? e.colors_name : "N/A",
          key_to_symbols: !!e.key_to_symbols ? e.key_to_symbols : "N/A",
          id: e.id,
          selected: false,
        };
      });
      const selectedObject = {};
      selected.forEach((e, i) => {
        selectedObject[i] = e;
      });

      setDiamonds(finaldata);
      setSelectedState(selectedObject);
      setFinalResult(finaldata);
      setTotal(totalCount);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchDiamondsReducer]);

  const CustomCell = (cellProps) => {
    return (
      <td
        className="k-grid-content-sticky"
        style={{ left: "50px", right: "0px" }}
      >
        <button
          className="btn btn-sm p-0 me-2"
          disabled={cellProps.dataItem.image === "N/A"}
          onClick={() => {
            setSelectedImage(cellProps.dataItem.image);
            setImgShow(true);
          }}
        >
          <i className="fa-solid fa-camera-retro"></i>
        </button>
        <button
          className="btn btn-sm p-0 me-2"
          disabled={cellProps.dataItem.video === "N/A"}
          onClick={() => {
            setSelectedVideo(cellProps.dataItem.video);
            setVideoShow(true);
          }}
        >
          {/* {cellProps.dataItem.video} */}
          <i className="fa-solid fa-video"></i>
        </button>
        <button
          className="btn btn-sm p-0 me-2"
          disabled={cellProps.dataItem.cert_url === "N/A"}
          onClick={() => {
            setSelectedCert(cellProps.dataItem.cert_url);
            setCertificateShow(true);
          }}
        >
          <i className="fa-solid fa-certificate"></i>
        </button>
        <div
          className="btn btn-sm p-0"
          onClick={() => {
            navigate(`/detail/${cellProps.dataItem.id}`, {
              state: {
                ...location.state,
                path: location.pathname,
                selected: selectedState,
              },
            });
          }}
        >
          <i className="fa-solid fa-eye"></i>
        </div>
      </td>
    );
  };

  const onSelectionChange = React.useCallback(
    (event) => {
      if (!!event.dataItem) {
        const index = event.dataItems.findIndex(
          (e) => e?.Index === event?.dataItem?.Index
        );
        event["startRowIndex"] = index;
        event["endRowIndex"] = index;
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedState,
          dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);
      }
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item, i) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  useEffect(() => {
    if (
      !!props.getNewArrivalListReducer.data ||
      !!props.searchDiamondsReducer.data
    ) {
      requestInProgress.current = false;
      const data =
        props.page === "new_arrival"
          ? props.getNewArrivalListReducer.data
          : props.searchDiamondsReducer.data;

      const idSelected = Object.keys(selectedState).filter((e) => {
        if (selectedState[e] === true) {
          return e;
        } else return false;
      });
      // old
      // const filterData = props.showSelected ? data?.filter((e) => {
      //     if (idSelected.includes(e.id)) {
      //         return e;
      //     } else return false;
      // }) : data;
      // new
      const filterData = props.showSelected
        ? diamonds?.filter((e) => {
            if (idSelected.includes(e.Index?.toString())) {
              return e;
            } else return false;
          })
        : finalResult;

        // data loader
      if (filterData?.length !== 0) {
        const list = filterData.map((e, i) => {
          if (props.page === "new_arrival") {
            return {
              Index: e.Index,
              srNo: i + 1,
              video: e.video ,
              image: e.image,
              cert_url: e.cert_url ,
              stone_id: e.stone_id ,
              cert_no: e.cert_no ,
              cert: e.cert,
              country: e.country ,
              growth_type: e.growth_type ,
              shape: e.shape,
              color: e.color ,
              Overtone: e.Overtone ,
              intensity: e.intensity ,
              clarity: e.clarity,
              cut: e.cut ,
              polish: e.polish,
              symmetry: e.symmetry,
              rapRate: e.rapRate ,
              disc: e.disc,
              carat: e.carat ,
              amount: e.amount,
              measurement: e.measurement,
              ratio: e.ratio,
              rate: e.rate ,
              depth: e.depth ,
              table: e.table ,
              bgm: e.bgm ,
              fluorescence: e.fluorescence ,
              fancy_color: e.fancy_color,
              key_to_symbols: !!e.key_to_symbols ? e.key_to_symbols : "N/A",
              id: e.id,
              selected: false,
            };
          } else {
            return {
              Index: e.Index,
              srNo: i + 1,
              video: e.video,
              image: e.image ,
              cert_url: e.cert_url ,
              stone_id: e.stone_id,
              cert_no: e.cert_no ,
              cert: e.cert ,
              country: e.country ,
              growth_type: e.growth_type ,
              shape: e.shape ,
              color: e.color ,
              Overtone: e.Overtone ,
              intensity: e.intensity ,
              clarity: e.clarity ,
              cut: e.cut ,
              polish: e.polish ,
              symmetry: e.symmetry ,
              rapRate: e.rapRate ,
              disc: e.disc ,
              carat: e.carat,
              amount: e.amount ,
              measurement: e.measurement ,
              rate: e.rate ,
              ratio: e.ratio ,
              depth: e.depth ,
              table: e.table ,
              bgm: e.bgm ,
              fluorescence: e.fluorescence ,
              fancy_color: e.fancy_color ,
              key_to_symbols: !!e.key_to_symbols ,
              id: e.id,
              selected: false,
            };
          }
        });
        setDiamonds(list);
        // setDiamonds(oldArray => [...oldArray,list] );
      }
    }
  }, [props.showSelected, selectedState]);
  const [skipTrack, setSkipTrack] = useState(0);
  // Call Diamond API
  const requestData = (skipParameter) => {
    console.log("898989989");
    if (requestInProgress.current) {
      // perform only one request at a time
      return;
    }
    requestInProgress.current = true;
    const skip = Math.max(skipParameter - pageSize, 0); // request the prev page as well
    if (props.page !== "new_arrival" && !props.searchDiamondsReducer.success) {
      searchDiamondsList(skip);
    }
    if (
      props.page === "new_arrival" &&
      !props.getNewArrivalListReducer.success
    ) {
      props.getNewArrivalList(skip);
    }
    setSkipTrack(skip);
  };

  const requestIfNeeded = (skip) => {
    for (let i = skip; i < skip + pageSize && i < diamonds?.length; i++) {
      if (
        diamonds[i].stone_id === undefined ||
        diamonds[i].stone_id === "N/A"
      ) {
        // request data only if not already fetched
        requestData(skip);
        return;
      }
    }
  };

  React.useEffect(() => {
    requestIfNeeded(page.skip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diamonds, page.skip]);
  React.useEffect(() => {
    // request the first page on initial load
    requestData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const pageChange = (event) => {
    requestIfNeeded(event.page.skip);
    setPage(event.page);
  };
  const loadingCell = (tdElement, props) => {
    const field = props.field || "";
    if (field !== "selected" && props.dataItem[field] === undefined) {
      // shows loading cell if no data
      return (
        <td>
          <Skeleton
            shape={"text"}
            style={{
              width: "100%",
            }}
          />
        </td>
      );
    } // default rendering for this cell
    return tdElement;
  };
  const MyCustomCell = (props) => <CustomCell {...props} />;
  const grid = (
    <Grid
      style={{ height: "60vh" }}
      // data={dataState.slice(page.skip, page.skip + pageSize)}
      data={diamonds?.map((item) => {
        return {
          ...item,
          [SELECTED_FIELD]: !!selectedState[idGetter(item)]
            ? selectedState[idGetter(item)]
            : false,
        };
      })}
      pageSize={pageSize}
      total={total}
      skip={page.skip}
      scrollable={"virtual"}
      rowHeight={50}
      onPageChange={pageChange}
      cellRender={loadingCell}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      selectable={{
        enabled: true,
        drag: false,
        cell: false,
        mode: "multiple",
      }}
      onSelectionChange={onSelectionChange}
      onHeaderSelectionChange={onHeaderSelectionChange}
    >
      <GridColumn
        locked={true}
        field={SELECTED_FIELD}
        width="50"
        headerSelectionValue={
          diamonds.length === 0
            ? false
            : diamonds.findIndex((item) => {
                return !selectedState[idGetter(item)];
              }) === -1
        }
      />
      <GridColumn
        field="Action"
        width="102"
        locked={true}
        cell={MyCustomCell}
      />
      {/* <GridColumn field="Index" title="Index" width="75" /> */}
      <GridColumn field="stone_id" title="Stone ID" width="75" />
      <GridColumn field="cert_no" title="Certificate No" width="105" />
      <GridColumn field="shape" title="Shape" width="55" />
      <GridColumn field="carat" title="Carat" width="45" />
      {/* <GridColumn field="size" title="Size" width="100" /> */}
      <GridColumn field="color" title="Color" width="45" />
      <GridColumn field="fancy_color" title="Fancy Color" width="90" />
      <GridColumn field="intensity" title="Intensity" width="70" />
      <GridColumn field="Overtone" title="Overtone" width="70" />
      <GridColumn field="clarity" title="Clarity" width="55" />
      <GridColumn field="cut" title="Cut" width="40" />
      <GridColumn field="polish" title="Poilish" width="55" />
      <GridColumn field="symmetry" title="Symmetry" width="80" />
      <GridColumn field="rapRate" title="Rap Rate" width="70" />
      <GridColumn field="disc" title="Discount" width="70" />
      <GridColumn field="rate" title="$/CT" width="45" />
      <GridColumn field="amount" title="$/total" width="55" />
      <GridColumn field="measurement" title="Measurement" width="105" />
      <GridColumn field="ratio" title="Ratio" width="50" />
      <GridColumn field="depth" title="Depth" width="55" />
      <GridColumn field="table" title="Table" width="50" />
      <GridColumn field="bgm" title="BGM" width="50" />
      <GridColumn field="fluorescence" title="Fluorescence" width="100" />
      <GridColumn field="key_to_symbols" title="Key to Symbol" width="150" />
      <GridColumn field="cert" title=" Certificate" width="80" />
      <GridColumn field="country" title=" Country" width="65" />
      <GridColumn field="growth_type" title="Growth Type" width="95" />
      {/*<GridColumn field="rate" title="Rate $/CT" width="100" />
    <GridColumn field="length" title="Length" width="80" />
    <GridColumn field="width" title="Width" width="80" />
    <GridColumn field="table" title="Table %" width="80" />
    <GridColumn field="depth_pr" title="Depth %" width="80" /> */}
    </Grid>
  );
  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            zIndex: "9999",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            background: "rgba(0,0,0,0.4)",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {grid}
          {imgShow && (
            <StonePreviewModal
              show={imgShow}
              onHide={() => {
                setImgShow(false);
                setSelectedImage("");
              }}
              details={selectedImage}
            />
          )}
          {videoShow && (
            <StoneVideoPreviewModal
              show={videoShow}
              onHide={() => {
                setVideoShow(false);
                setSelectedVideo("");
              }}
              details={selectedVideo}
            />
          )}
          {certificateShow && (
            <PdfModal
              show={certificateShow}
              onHide={() => {
                setCertificateShow(false);
                setSelectedCert("");
              }}
              details={selectedCert}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  searchDiamondsReducer: state.labGrownReducer.searchDiamonds,
  getNewArrivalListReducer: state.masterReducer.getNewArrivalList,
});
const mapDispatchToProps = (dispatch) => ({
  searchDiamonds: (details, pageSkip) =>
    dispatch(searchDiamonds(details, pageSkip)),
  getNewArrivalList: (pageSkip) => dispatch(getNewArrivalList(pageSkip)),
  resetSearchDiamonds: () => dispatch({ type: SEARCH_DIAMONDS_RESET }),
  resetNewArrival: () => dispatch({ type: NEW_ARRIVAL_LIST_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchTable);
// 644
