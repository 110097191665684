import React, { useState } from "react";
import { Button} from "react-bootstrap";
import { connect } from "react-redux";
import { deleteSaveSearchList } from "../userStore/action/saveSearchList";
import SavedSearchTable from "./Tables/SavedSearchTable";
import Footer from "./userLayout/Footer";
import Header from "./userLayout/Header";
import { toast } from "react-toastify";
import { exportExcel, exportPdf } from "../userStore/action/exportFileAction";
import {
  EXPORT_EXCEL_RESET,
  EXPORT_PDF_RESET,
} from "../userStore/common/constant";

const SavedSearch = (props) => {
  const [selected, setSelected] = useState({});

  const handleDelete = () => {
    const ids = Object.keys(selected).filter((e) => {
      if (selected[e] === true) {
        return e;
      } else return false;
    });
   if (ids.length !== 0) {
    props.deleteSaveSearchList({
      search_id: JSON.stringify([...ids]),
    });
   }
   else{
    toast.error(
      "There are no selected stone please select stone first.."
    );
   }
  };

 
  return (
    <>
      <Header />
      <main className="userMain">
        <div className="d-flex justify-content-between  mt-5 mb-4 flex-wrap">
          <h2 className="heading mb-0"> Save Search List</h2>
          <div>
            <Button
              variant="secondary"
              className="me-sm-2 me-1"
              onClick={handleDelete}
            >
              Delete Saved Search
            </Button>
          </div>
        </div>
        <div className="content-block">
          <SavedSearchTable setSelected={setSelected} />
        </div>
      </main>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  deleteViewRequestReducer: state.viewRequestReducer.deleteViewRequest,
  getSaveSearchList: state.saveSearchListReducer.getSaveSearchList,
});
const mapDispatchToProps = (dispatch) => ({
  deleteSaveSearchList: (details) => dispatch(deleteSaveSearchList(details)),
  exportPdf: (details) => dispatch(exportPdf(details)),
  exportExcel: (details) => dispatch(exportExcel(details)),
  resetExportPdf: () => dispatch({ type: EXPORT_PDF_RESET }),
  resetExportExcel: () => dispatch({ type: EXPORT_EXCEL_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedSearch);
