import React, { useState } from "react";
import { Accordion, Col, Dropdown, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./userLayout/Header";
import Footer from "./userLayout/Footer";
import { connect } from "react-redux";
import { useEffect } from "react";
import {
  getMastersList,
  getNewArrivalList,
} from "../userStore/action/masterAction";
import Loader from "../Components/Loader";
import { searchDiamonds } from "../userStore/action/labgrownAction";
import { Button } from "reactstrap";
import { addDemandList } from "../userStore/action/demandAction";
import { addSaveSearchList } from "../userStore/action/saveSearchList";
import { toast } from "react-toastify";
import {
  ADD_DEMAND_LIST_RESET,
  ADD_SAVE_SEARCH_RESET,
} from "../userStore/common/constant";
// import { MultiSelect } from "@progress/kendo-react-dropdowns";
// import { MultiSelect } from "react-multi-select-component";
// import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
const LabGrown = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [caret, setCaret] = useState(true);
  const diamondType = location.pathname === "/natural/search" ? 1 : 2;

  const [naturalDetails, setNaturalsDetails] = useState({
    table_open: [],
    table_black: [],
    side_black: [],
    white_side: [],
    white_table: [],
  });
  const [selectedNaturalDetails, setSelectedNaturalsDetails] = useState({
    table_open: [],
    table_black: [],
    side_black: [],
    white_side: [],
    white_table: [],
  });

  const [demandDetails, setDemandDetails] = useState({
    name: "",
    quantity: 1,
  });
  const [isSaveDemand, setIsSaveDemand] = useState(false);
  const [isSaveSearch, setIsSaveSearch] = useState(false);

  const [details, setDetails] = useState({});
  const [error, setError] = useState({
    carat: [false],
    demandName: "",
    quantity: false,
  });

  const [general, setgeneral] = useState("");

  const [InitialState] = useState({
    stone_id: "",
    location: [],
    shape_id: [],
    size_id: [],
    color_id: [],
    colors_id: [],
    overtone_id: [],
    intensity_id: [],
    clarity_id: [],
    cut_id: [],
    polish_id: [],
    symmetry_id: [],
    fluorescence_id: [],
    fluorescence_color_id: [],
    bgm_id: [],
    lab: [],
    growth_type: [],
    carat_from: [""],
    carat_to: [""],
    ct_from: "",
    ct_to: "",
    discount_from: "",
    discount_to: "",
    value_from: "",
    value_to: "",
    length_from: "",
    length_to: "",
    width_from: "",
    width_to: "",
    height_from: "",
    height_to: "",
    table_from: "",
    table_to: "",
    depth_from: "",
    depth_to: "",
    ratio_from: "",
    ratio_to: "",
    no_bgm: 0,
    h_a: 0,
    eye_clean: 0,
    as_grown: 0,
    treated: 0,
    unknown: 0,
  });

  const [stateList, setStateList] = useState(InitialState);

  useEffect(() => {
    const getData = async () => {
      await props.getMastersList();
      setLoading(true);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!location.state && location.state.page !== "new_arrival") {
      setStateList({
        ...location.state.state,
      });
    } else {
      setStateList({
        ...InitialState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (props.masterListReducer.success) {
      const data = props.masterListReducer.data;
      setDetails({ ...data });
      setNaturalsDetails((prev) => ({
        ...prev,
        table_open: data.table_open
          .map((e, i) => {
            if (!!e.table_open) {
              return { label: e.table_open, value: i + 1 };
            } else return false;
          })
          .filter((e) => !!e),
        white_side: data.white_side
          .map((e, i) => {
            if (!!e.white_side) {
              return { label: e.white_side, value: i + 1 };
            } else return false;
          })
          .filter((e) => !!e),
        white_table: data.white_table
          .map((e, i) => {
            if (!!e.white_table) {
              return { label: e.white_table, value: i + 1 };
            } else return false;
          })
          .filter((e) => !!e),
        table_black: data.table_black
          .map((e, i) => {
            if (!!e.table_black) {
              return { label: e.table_black, value: i + 1 };
            } else return false;
          })
          .filter((e) => !!e),
        side_black: data.side_black
          .map((e, i) => {
            if (!!e.side_black) {
              return { label: e.side_black, value: i + 1 };
            } else return false;
          })
          .filter((e) => !!e),
      }));
      setLoading(false);
    }
  }, [props.masterListReducer]);

  // handle click event of the Add button
  const addCarat = () => {
    setStateList((prev) => ({
      ...prev,
      carat_from: [...prev.carat_from, ""],
      carat_to: [...prev.carat_to, ""],
    }));
    setError((prev) => ({
      ...prev,
      carat: [...prev.carat, false],
    }));
  };
  // handle click event of the Remove button
  const removeCarat = (index) => {
    const cartFrom = [...stateList.carat_from];
    cartFrom.splice(index, 1);
    const cartTo = [...stateList.carat_to];
    cartTo.splice(index, 1);
    setStateList((prev) => ({
      ...prev,
      carat_from: [...cartFrom],
      carat_to: [...cartTo],
    }));
    const cartError = [...error.carat];
    cartError.splice(index, 1);
    setError((prev) => ({
      ...prev,
      carat: [...cartError],
    }));
  };

  const handleInput = (e, index) => {
    const { name, value } = e.target;

    if (
      name === "discount_from" ||
      name === "discount_to" ||
      name === "ct_from" ||
      name === "ct_to"
    ) {
      if (RegExp(/^([0-9]{0,2}([.][0-9]{0,2})?|100)$/i).test(value)) {
        setStateList((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    }
    if (
      name === "carat_from" ||
      name === "carat_to" ||
      name === "height" ||
      name === "width" ||
      name === "length" ||
      name === "length_from" ||
      name === "length_to" ||
      name === "width_from" ||
      name === "width_to" ||
      name === "height_from" ||
      name === "height_to" ||
      name === "depth" ||
      name === "table" ||
      name === "table_from" ||
      name === "table_to"
    ) {
      if (RegExp(/^([0-9]{0,5}([.][0-9]{0,2})?)$/i).test(value)) {
        setStateList((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    }

    if (name === "depth_from" || name === "depth_to") {
      if (RegExp(/^([0-9]{0,2}([.][0-9]{0,2})?|100)$/i).test(value)) {
        setStateList((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    }

    if (
      name === "ratio_from" ||
      name === "ratio_to" ||
      name === "value_from" ||
      name === "value_to"
    ) {
      if (RegExp(/^([0-9]{0,10}([.][0-9]{0,2})?)$/i).test(value)) {
        setStateList((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    }

    if (name === "stone_id") {
      if (RegExp(/^([a-zA-Z0-9]{0,12})$/i).test(value)) {
        setStateList((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    }

    if (name === "carat_from") {
      const from = [...stateList.carat_from];
      const _list = from.map((e, i) => {
        if (i === index) {
          return value;
        } else return e;
      });
      setStateList((pre) => ({
        ...pre,
        [name]: [..._list],
      }));
    } else if (name === "carat_to") {
      const to = [...stateList.carat_to];
      const _list = to.map((e, i) => {
        if (i === index) {
          return value;
        } else return e;
      });
      setStateList((pre) => ({
        ...pre,
        [name]: [..._list],
      }));
    } else {
      setStateList((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleCheckBox = (e) => {
    const { name, checked, value } = e.target;
    if (checked) {
      if (
        name === "no_bgm" ||
        name === "h_a" ||
        name === "eye_clean" ||
        name === "as_grown" ||
        name === "treated" ||
        name === "unknown"
      ) {
        setStateList((prev) => ({
          ...prev,
          [name]: 1,
        }));
      } else {
        const list = [...stateList[name], value];
        setStateList((prev) => ({
          ...prev,
          [name]: [...list],
        }));
      }
    } else {
      if (
        name === "no_bgm" ||
        name === "h_a" ||
        name === "eye_clean" ||
        name === "as_grown" ||
        name === "treated" ||
        name === "unknown"
      ) {
        setStateList((prev) => ({
          ...prev,
          [name]: 0,
        }));
      } else {
        const list = stateList[name];
        const index = list.findIndex((e) => e === value);
        list.splice(index, 1);
        setStateList((prev) => ({
          ...prev,
          [name]: [...list],
        }));
      }
    }
  };
  const handleCheckBoxGeneral = (e) => {
    const { checked, value } = e.target;
    setgeneral(checked ? value : "");
  };

  useEffect(() => {
    if (parseInt(general) === 20) {
      setStateList((preve) => ({
        ...preve,
        cut_id: ["1"],
        polish_id: ["8"],
        symmetry_id: ["14"],
      }));
    } else if (parseInt(general) === 21) {
      setStateList((preve) => ({
        ...preve,
        cut_id: ["3", "1"],
        polish_id: ["9", "8"],
        symmetry_id: ["15", "14"],
      }));
    } else if (parseInt(general) === 22) {
      setStateList((preve) => ({
        ...preve,
        cut_id: ["4", "3", "1"],
        polish_id: ["10", "9", "8"],
        symmetry_id: ["16", "15", "14"],
      }));
    } else {
      setStateList((preve) => ({
        ...preve,
        cut_id: [],
        polish_id: [],
        symmetry_id: [],
      }));
    }
  }, [general]);

  // const handleCheckBoxGeneral = (e) => {
  //   const { name, checked, value } = e.target;
  //   setgeneral(value);
  // };

  useEffect(() => {
    if (parseInt(general) === 20) {
      setStateList((preve) => ({
        ...preve,
        cut_id: ["1"],
        polish_id: ["8"],
        symmetry_id: ["14"],
      }));
    } else if (parseInt(general) === 21) {
      setStateList((preve) => ({
        ...preve,
        cut_id: ["3", "1"],
        polish_id: ["9", "8"],
        symmetry_id: ["15", "14"],
      }));
    } else if (parseInt(general) === 22) {
      setStateList((preve) => ({
        ...preve,
        cut_id: ["4", "3", "1"],
        polish_id: ["10", "9", "8"],
        symmetry_id: ["16", "15", "14"],
      }));
    } else {
      setStateList((preve) => ({
        ...preve,
        cut_id: [],
        polish_id: [],
        symmetry_id: [],
      }));
    }
  }, [general]);

  const handleFunctions = (name) => {
    const isCarat = stateList.carat_from
      .map((e, i) => {
        if (e === "" || stateList.carat_to[i] === "") {
          return false;
        } else return true;
      })
      .every((e) => e === true);

    if (
      (stateList.carat_from.length === 1 &&
        stateList.carat_from[0] === "" &&
        stateList.carat_to.length === 1 &&
        stateList.carat_to[0] === "") ||
      isCarat
    ) {
      setLoading(true);
      const _details = {
        ...stateList,
        location:
          stateList.location.length !== 0
            ? JSON.stringify(stateList.location)
            : "",
        shape_id:
          stateList.shape_id.length !== 0
            ? JSON.stringify(stateList.shape_id)
            : "",
        size_id:
          stateList.size_id.length !== 0
            ? JSON.stringify(stateList.size_id)
            : "",
        color_id:
          stateList.color_id.length !== 0
            ? JSON.stringify(stateList.color_id)
            : "",
        colors_id:
          stateList.colors_id.length !== 0
            ? JSON.stringify(stateList.colors_id)
            : "",
        overtone_id:
          stateList.overtone_id.length !== 0
            ? JSON.stringify(stateList.overtone_id)
            : "",
        intensity_id:
          stateList.intensity_id.length !== 0
            ? JSON.stringify(stateList.intensity_id)
            : "",
        clarity_id:
          stateList.clarity_id.length !== 0
            ? JSON.stringify(stateList.clarity_id)
            : "",
        cut_id:
          stateList.cut_id.length !== 0 ? JSON.stringify(stateList.cut_id) : "",
        polish_id:
          stateList.polish_id.length !== 0
            ? JSON.stringify(stateList.polish_id)
            : "",
        symmetry_id:
          stateList.symmetry_id.length !== 0
            ? JSON.stringify(stateList.symmetry_id)
            : "",
        fluorescence_id:
          stateList.fluorescence_id.length !== 0
            ? JSON.stringify(stateList.fluorescence_id)
            : "",
        fluorescence_color_id:
          stateList.fluorescence_color_id.length !== 0
            ? JSON.stringify(stateList.fluorescence_color_id)
            : "",
        bgm_id:
          stateList.bgm_id.length !== 0 ? JSON.stringify(stateList.bgm_id) : "",
        lab: stateList.lab.length !== 0 ? JSON.stringify(stateList.lab) : "",
        growth_type:
          stateList.growth_type.length !== 0
            ? JSON.stringify(stateList.growth_type)
            : "",
        carat_from:
          stateList.carat_from.length === 1 && !isCarat
            ? ""
            : JSON.stringify(stateList.carat_from),
        carat_to:
          stateList.carat_to.length === 1 && !isCarat
            ? ""
            : JSON.stringify(stateList.carat_to),
        no_bgm: stateList.no_bgm,
        h_a: stateList.h_a,
        eye_clean: stateList.eye_clean,
        as_grown: stateList.as_grown,
        treated: stateList.treated,
        unknown: stateList.unknown,
        table_open: selectedNaturalDetails.table_open.map((e) => e.label),
        white_table: selectedNaturalDetails.white_table.map((e) => e.label),
        table_black: selectedNaturalDetails.table_black.map((e) => e.label),
        white_side: selectedNaturalDetails.white_side.map((e) => e.label),
        side_black: selectedNaturalDetails.side_black.map((e) => e.label),
      };

      name === "search" &&
        props.searchDiamonds({
          ..._details,
          diamond_type: diamondType,
        });
      name === "demand" &&
        props.addDemandList({
          ..._details,
          name: demandDetails.name,
          quantity: parseInt(demandDetails.quantity),
          carat_from:
            stateList.carat_from.length === 1 && !isCarat
              ? ""
              : [...stateList.carat_from],
          carat_to:
            stateList.carat_to.length === 1 && !isCarat
              ? ""
              : [...stateList.carat_to],
        });

      name === "saveSearch" &&
        props.addSaveSearchList({
          ..._details,
          name: demandDetails.name,
          carat_from:
            stateList.carat_from.length === 1 && !isCarat
              ? ""
              : [...stateList.carat_from],
          carat_to:
            stateList.carat_to.length === 1 && !isCarat
              ? ""
              : [...stateList.carat_to],
        });
    } else {
      const index = stateList.carat_from.findIndex(
        (e, i) => e === "" || stateList.carat_to[i] === ""
      );
      const errorList = error.carat.map((e, i) => {
        if (i === index) {
          return "Enter both value";
        } else {
          return false;
        }
      });

      setError((pre) => ({
        ...pre,
        carat: [...errorList],
      }));
    }
  };

  useEffect(() => {
    if (props.searchDiamondsReducer.success) {
      setLoading(false);
      setStateList(InitialState);
      navigate("/search-result", {
        state: {
          ...location.state,
          state: stateList,
          page: "search-result",
        },
      });
    }
  }, [
    props.searchDiamondsReducer,
    InitialState,
    location.state,
    navigate,
    stateList,
  ]);

  const saveDemandName = () => {
    if (demandDetails.name === "") {
      setError((prev) => ({
        ...prev,
        demandName: "Required",
      }));
    } else if (
      demandDetails.quantity === "" ||
      parseInt(demandDetails.quantity) === 0
    ) {
      setError((prev) => ({
        ...prev,
        quantity: "Required",
      }));
    }

    if (
      demandDetails.name !== "" &&
      demandDetails.quantity !== "" &&
      demandDetails.quantity !== ""
    ) {
      setIsSaveDemand(false);
      setLoading(true);
      handleFunctions("demand");
    }
  };

  const handleSaveSearch = () => {
    if (demandDetails.name === "") {
      setError((prev) => ({
        ...prev,
        demandName: "Required",
      }));
    }
    if (demandDetails.name !== "") {
      setIsSaveSearch(false);
      setLoading(true);
      handleFunctions("saveSearch");
    }
  };

  useEffect(() => {
    if (props.addDemandReducer.success) {
      setLoading(false);
      setStateList(InitialState);
      toast.success("Demand List Added successfully..");
      setDemandDetails({
        name: "",
        quantity: 0,
      });
      props.resetAddDemand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addDemandReducer, InitialState]);
  useEffect(() => {
    if (props.addSaveSearchListReducer.success) {
      setLoading(false);
      toast.success("Search list saved successfully..");
      props.resetSavedSearch();
      setStateList(InitialState);
      setDemandDetails({
        name: "",
        quantity: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addSaveSearchListReducer, InitialState]);

  const handleNewArrival = () => {
    props.getNewArrivalList();
  };

  useEffect(() => {
    if (props.getNewArrivalListReducer.success) {
      navigate("/new-arrival", {
        state: {
          ...location.state,
          page: "new_arrival",
        },
      });
    }
  }, [props.getNewArrivalListReducer, location.state, navigate]);

  const GoToDemand = () => {
    if (JSON.stringify(InitialState) === JSON.stringify(stateList)) {
      toast.error("Please Select Atleast 1 Filter For Search...");
    } else {
      setIsSaveDemand(true);
    }
  };

  const GoToSaveSearch = () => {
    if (JSON.stringify(InitialState) === JSON.stringify(stateList)) {
      toast.error("Please Select Atleast 1 Filter For Search...");
    } else {
      setIsSaveSearch(true);
    }
  };

  return (
    <>
      <Header />

      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            zIndex: "9999",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            background: "rgba(0,0,0,0.4)",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <main className="userMain">
            <div className="d-flex align-items-center mt-5 mb-4 flex-wrap">
              <h2 className="heading mb-0">Basic Search - </h2>
              <Dropdown
                className="profile-drp notification-drp"
                drop="down-right"
                align="end"
              >
                <Dropdown.Toggle id="profile" className="searchType text-decoration-none py-0">
                  <h2 className="mb-0">
                    {diamondType == 1
                      ? "Natural Diamonds"
                      : "Lab-Grown Diamonds"}
                  </h2>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div style={{ cursor: "pointer", padding: "0px" }}>
                    <h5
                      className="mb-0 p-2"
                      onClick={() => {
                        navigate(
                          diamondType == 1
                            ? "/labGrown/search"
                            : "/natural/search"
                        );
                      }}
                    >
                      {diamondType == 1
                        ? "Lab-Grown Diamonds"
                        : "Natural Diamonds"}
                    </h5>
                  </div>
                </Dropdown.Menu>
              </Dropdown>

            </div>
            <div className="text-end mt-3 mt-sm-0 mb-5 ms-auto">
              <button
                className="btn btn-primary me-md-2 me-1 text-white px-md-4"
                onClick={() => navigate("/star-melee-dashboard")}
              >
                Star melee Diamonds
              </button>
              <button
                className="btn btn-primary me-md-2 me-1 text-white px-md-4"
                onClick={GoToSaveSearch}
              >
                Saved Search
              </button>
              <button
                className="btn btn-primary text-white px-md-4 me-md-2 me-1"
                onClick={handleNewArrival}
              >
                New Arrival
              </button>
              <button
                type="reset"
                className="btn btn-secondary text-white me-2 d-inline-block"
                onClick={() => setStateList(InitialState)}
              >
                <i className="fa-solid fa-arrow-rotate-right"></i>
              </button>
            </div>
            <div className="container-fluid content-block">
              {/* Begin:: shape */}
              <div className="row border-bottom search-row">
                <div className="col-lg-2">Shape</div>
                <div className="col-lg-10">
                  <ul className="search-items-list">
                    {Object.keys(details).length !== 0 && (
                      <>
                        {details?.Shape?.map((e, i) => {
                          return (
                            <li key={i}>
                              <input
                                className="styled-checkbox"
                                id={`Shape-${i}`}
                                type="checkbox"
                                value={e.id}
                                name="shape_id"
                                checked={stateList?.shape_id?.includes(
                                  e.id.toString()
                                )}
                                onChange={handleCheckBox}
                              />
                              <label htmlFor={`Shape-${i}`}>
                                {/* <span
                                  className={`diam-icon ${e.icon} ab`}
                                ></span> */}
                                <img
                                  src={`/images/shapes/${e.icon}.svg`}
                                  className="img-fluid diam-icon"
                                  alt=""
                                />

                                {e.name}
                              </label>
                            </li>
                          );
                        })}
                      </>
                    )}
                  </ul>
                </div>
              </div>
              {/* End:: shape */}

              {/* Begin:: Caret | Simple*/}
              <div className="row border-bottom search-row">
                <div className="col-lg-2">
                  <button
                    type="button"
                    onClick={() => setCaret(true)}
                    className={"btn ps-0 " + (caret && "btn-active")}
                  >
                    Carat
                  </button>{" "}
                  {/* {diamondType === 2 && (
                    <>
                      |{" "}
                      <button
                        className={"btn pe-0 " + (!caret && "btn-active")}
                        onClick={() => setCaret(false)}
                        type="button"
                      >
                        Simple
                      </button>
                    </>
                  )} */}
                  <button
                    className={"btn pe-0 " + (!caret && "btn-active")}
                    onClick={() => setCaret(false)}
                    type="button"
                  >
                    Simple
                  </button>
                </div>
                <div className="col-lg-10">
                  {caret ? (
                    <>
                      {/* Caret */}
                      <div className="row g-1 carat-container">
                        {stateList?.carat_from?.map((item, i) => {
                          return (
                            i < 6 && (
                              <div
                                className="col-lg-6 g-1 row mb-2 carat-row"
                                data-key={"carat" + item}
                                key={i}
                              >
                                <div className="col-5">
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => handleInput(e, i)}
                                    name="carat_from"
                                    value={item}
                                    placeholder="From"
                                    aria-label="From"
                                  />
                                </div>
                                <div className="col-5">
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => handleInput(e, i)}
                                    name="carat_to"
                                    value={stateList.carat_to[i]}
                                    placeholder="To"
                                    aria-label="To"
                                  />
                                </div>

                                <div className="col-2 px-0">
                                  {stateList.carat_from.length !== 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-secondary btn-remove text-white "
                                      onClick={() => removeCarat(i)}
                                      style={{ padding: "6px 12px" }}
                                    >
                                      <i className="fa-solid fa-minus"></i>
                                    </button>
                                  )}
                                  {stateList.carat_from.length - 1 === i &&
                                    stateList.carat_from.length !== 5 && (
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-add text-white ms-1"
                                        onClick={addCarat}
                                        style={{ padding: "6px 12px" }}
                                      >
                                        <i className="fa-solid fa-plus"></i>
                                      </button>
                                    )}
                                </div>
                                <div className="col-12 px-0">
                                  {error.carat[i] && (
                                    <span className="form-error">
                                      {error.carat[i]}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Simple */}
                      <ul className="search-items-list">
                        {Object.keys(details).length !== 0 && (
                          <>
                            {details?.Size?.map((e, i) => {
                              return (
                                <li key={i}>
                                  <input
                                    className="styled-checkbox"
                                    id={`Size-${i}`}
                                    type="checkbox"
                                    value={e.id}
                                    checked={stateList.size_id.includes(
                                      e.id.toString()
                                    )}
                                    name="size_id"
                                    onChange={handleCheckBox}
                                  // value=" 0.18-0.22"
                                  />
                                  <label htmlFor={`Size-${i}`}>{e.name}</label>
                                </li>
                              );
                            })}
                          </>
                        )}
                      </ul>
                    </>
                  )}
                </div>
              </div>
              {/* End:: Caret | Simple*/}

              {/* Begin:: Color */}
              <div className="row border-bottom search-row">
                <div className="col-lg-2">Color</div>
                <div className="col-lg-10">
                  <ul className="search-items-list">
                    {Object.keys(details).length !== 0 && (
                      <>
                        {details?.Color?.map((e, i) => {
                          return (
                            <li key={i}>
                              <input
                                className="styled-checkbox"
                                id={`Color-${i}`}
                                type="checkbox"
                                value={e.id}
                                onChange={handleCheckBox}
                                name="color_id"
                                checked={stateList?.color_id?.includes(
                                  e.id.toString()
                                )}
                              // value="E"
                              />
                              <label htmlFor={`Color-${i}`}>{e.name}</label>
                            </li>
                          );
                        })}
                      </>
                    )}
                  </ul>
                </div>
              </div>
              {/* End:: Color */}

              {/* Begin:: Fancy Color */}
              {diamondType === 2 && (
                <div className="row border-bottom search-row">
                  <div className="col-lg-2">Fancy Color</div>
                  <div className="col-lg-10">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="search-head mb-4">Colors</div>
                        <ul className="fancy-blocks">
                          {Object.keys(details).length !== 0 && (
                            <>
                              {details?.FancyColor?.map((e, i) => {
                                if (e.type === 0) {
                                  return (
                                    <li key={i}>
                                      <input
                                        className="styled-checkbox"
                                        id={`FancyColor-${i}`}
                                        type="checkbox"
                                        name="colors_id"
                                        onChange={handleCheckBox}
                                        value={e.id}
                                        checked={stateList?.colors_id?.includes(
                                          e.id.toString()
                                        )}
                                      // value="Orange"
                                      />
                                      <label htmlFor={`FancyColor-${i}`}>
                                        <span>
                                          <img
                                            src={e.image}
                                            className="img-fluid"
                                            alt=""
                                          />
                                        </span>
                                        {e.name}
                                      </label>
                                    </li>
                                  );
                                } else return false;
                              })}
                            </>
                          )}
                        </ul>
                      </div>
                      <div className="col-lg-4">
                        <div className="search-head mb-4">Overtone</div>
                        <ul className="overtone-list">
                          {Object.keys(details).length !== 0 && (
                            <>
                              {details?.FancyColor?.map((e, i) => {
                                if (e.type === 1) {
                                  return (
                                    <li key={i}>
                                      <input
                                        className="styled-checkbox"
                                        id={`Overtone-${i}`}
                                        type="checkbox"
                                        name="overtone_id"
                                        onChange={handleCheckBox}
                                        value={e.id}
                                        checked={stateList?.overtone_id?.includes(
                                          e.id.toString()
                                        )}
                                      // value="Orange"
                                      />
                                      <label htmlFor={`Overtone-${i}`}>
                                        {e.name}
                                      </label>
                                    </li>
                                  );
                                } else return false;
                              })}
                            </>
                          )}
                        </ul>
                      </div>
                      <div className="col-lg-4">
                        <div className="search-head mb-4">Intensity</div>
                        <ul className="overtone-list">
                          {Object.keys(details).length !== 0 && (
                            <>
                              {details?.FancyColor?.map((e, i) => {
                                if (e.type === 2) {
                                  return (
                                    <li key={i}>
                                      <input
                                        className="styled-checkbox"
                                        id={`Intensity-${i}`}
                                        type="checkbox"
                                        name="intensity_id"
                                        onChange={handleCheckBox}
                                        value={e.id}
                                        checked={stateList?.intensity_id?.includes(
                                          e.id.toString()
                                        )}
                                      // value="Orange"
                                      />
                                      <label htmlFor={`Intensity-${i}`}>
                                        {e.name}
                                      </label>
                                    </li>
                                  );
                                } else return false;
                              })}
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* End:: Fancy Color */}

              {/* Begin:: Clarity */}
              <div className="row border-bottom search-row">
                <div className="col-lg-2">Clarity</div>
                <div className="col-lg-10">
                  <ul className="search-items-list clarity-list">
                    {Object.keys(details).length !== 0 && (
                      <>
                        {details?.Clarity?.map((e, i) => {
                          return (
                            <li key={i}>
                              <input
                                className="styled-checkbox"
                                id={`Clarity-${i}`}
                                type="checkbox"
                                name="clarity_id"
                                onChange={handleCheckBox}
                                value={e.id}
                                checked={stateList?.clarity_id?.includes(
                                  e.id.toString()
                                )}
                              // value="Orange"
                              />
                              <label htmlFor={`Clarity-${i}`}>{e.name}</label>
                            </li>
                          );
                        })}
                      </>
                    )}
                  </ul>
                </div>
              </div>
              {/* End:: Clarity */}

              {/* Begin:: Finish */}
              <div className="row border-bottom search-row">
                <div className="col-lg-2">
                  <h6>Finish</h6>
                  {/* <button
              type="button"
              className={"btn ps-0 " + (Specific && "btn-active")}
              onClick={() => setSpecific(true)}
            >
              Specific
            </button>{" "}
            |{" "}
            <button
              className={"btn pe-0 " + (!Specific && "btn-active")}
              onClick={() => setSpecific(false)}
              type="button"
            >
              General
            </button> */}
                </div>
                <div className="col-lg-10">
                  <>
                    {/* Specific */}
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="search-head mb-4">Cut</div>
                        <ul className="row g-1 search-items-list">
                          {Object.keys(details)?.length !== 0 && (
                            <>
                              {details?.Finish?.map((e, i) => {
                                if (e.type === 0) {
                                  return (
                                    <li key={i}>
                                      <input
                                        className="styled-checkbox"
                                        id={`Cut-${i}`}
                                        type="checkbox"
                                        name="cut_id"
                                        onChange={handleCheckBox}
                                        value={e?.id}
                                        checked={stateList?.cut_id?.includes(
                                          e.id.toString()
                                        )}
                                      // value="Orange"
                                      />
                                      <label htmlFor={`Cut-${i}`}>
                                        {e.name}
                                      </label>
                                    </li>
                                  );
                                } else return false;
                              })}
                            </>
                          )}
                        </ul>
                      </div>
                      <div className="col-lg-4">
                        <div className="search-head mb-4">Polish</div>
                        <ul className="row g-1 search-items-list">
                          {Object.keys(details).length !== 0 && (
                            <>
                              {details?.Finish?.map((e, i) => {
                                if (e.type === 1) {
                                  return (
                                    <li key={i}>
                                      <input
                                        className="styled-checkbox"
                                        id={`Polish-${i}`}
                                        type="checkbox"
                                        name="polish_id"
                                        onChange={handleCheckBox}
                                        value={e.id}
                                        checked={stateList?.polish_id?.includes(
                                          e.id.toString()
                                        )}
                                      // value="Orange"
                                      />
                                      <label htmlFor={`Polish-${i}`}>
                                        {e.name}
                                      </label>
                                    </li>
                                  );
                                } else return false;
                              })}
                            </>
                          )}
                        </ul>
                      </div>
                      <div className="col-lg-4">
                        <div className="search-head mb-4">Symmetry</div>
                        <ul className="row g-1 search-items-list">
                          {Object.keys(details).length !== 0 && (
                            <>
                              {details?.Finish?.map((e, i) => {
                                if (e.type === 2) {
                                  return (
                                    <li key={i}>
                                      <input
                                        className="styled-checkbox"
                                        id={`Symmetry-${i}`}
                                        type="checkbox"
                                        name="symmetry_id"
                                        onChange={handleCheckBox}
                                        value={e.id}
                                        checked={stateList?.symmetry_id?.includes(
                                          e.id.toString()
                                        )}
                                      // value="Orange"
                                      />
                                      <label htmlFor={`Symmetry-${i}`}>
                                        {e.name}
                                      </label>
                                    </li>
                                  );
                                } else return false;
                              })}
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </>

                  <>
                    <div className="">
                      <div className="search-head-general mb-4 mt-4">
                        General
                      </div>
                      <ul className="row g-1 search-items-list">
                        {Object.keys(details).length !== 0 && (
                          <>
                            {details?.Finish?.map((e, i) => {
                              if (e.type === 3) {
                                return (
                                  <li key={i} className="">
                                    <input
                                      className="styled-checkbox"
                                      id={`Genaral-${i}`}
                                      type="checkbox"
                                      name="general"
                                      onChange={handleCheckBoxGeneral}
                                      value={e.id}
                                      checked={general === e.id.toString()}
                                    // value="Orange"
                                    />
                                    <label htmlFor={`Genaral-${i}`}>
                                      {e.name}
                                    </label>
                                  </li>
                                );
                              } else return false;
                            })}
                          </>
                        )}
                      </ul>
                    </div>
                  </>
                </div>
              </div>
              {/* End:: Finish */}

              {/* Begin::Fluorescence */}
              <div className="row border-bottom search-row">
                <div className="col-lg-2">Fluorescence</div>
                <div className="col-lg-10">
                  <div className="row">
                    <div
                      className={diamondType === 2 ? "col-lg-4" : "col-lg-12"}
                    >
                      <div className="search-head mb-2 invisible">BGM</div>
                      <ul className="row g-1 search-items-list">
                        {Object.keys(details).length !== 0 && (
                          <>
                            {details?.Fluorescence?.map((e, i) => {
                              if (e.type === 0) {
                                return (
                                  <li key={i}>
                                    <input
                                      className="styled-checkbox"
                                      id={`None-${i}`}
                                      type="checkbox"
                                      name="fluorescence_id"
                                      onChange={handleCheckBox}
                                      value={e.id}
                                      checked={stateList?.fluorescence_id?.includes(
                                        e.id.toString()
                                      )}
                                    // value="Orange"
                                    />
                                    <label htmlFor={`None-${i}`}>
                                      {e.name}
                                    </label>
                                  </li>
                                );
                              } else return false;
                            })}
                          </>
                        )}
                      </ul>
                    </div>
                    {diamondType === 2 && (
                      <>
                        <div className="col-lg-4">
                          <div className="search-head mb-2">Color</div>
                          <ul className="row g-1 search-items-list">
                            {Object.keys(details).length !== 0 && (
                              <>
                                {details?.Fluorescence?.map((e, i) => {
                                  if (e.type === 1) {
                                    return (
                                      <li key={i}>
                                        <input
                                          className="styled-checkbox"
                                          id={`Colors-${i}`}
                                          type="checkbox"
                                          name="fluorescence_color_id"
                                          onChange={handleCheckBox}
                                          value={e.id}
                                          checked={stateList?.fluorescence_color_id?.includes(
                                            e.id.toString()
                                          )}
                                        // value="Orange"
                                        />
                                        <label htmlFor={`Colors-${i}`}>
                                          {e.name}
                                        </label>
                                      </li>
                                    );
                                  } else return false;
                                })}
                              </>
                            )}
                          </ul>
                        </div>
                        <div className="col-lg-4">
                          <div className="search-head mb-2">BGM</div>
                          <ul className="row g-1 search-items-list">
                            {Object.keys(details).length !== 0 && (
                              <>
                                {details?.Fluorescence?.map((e, i) => {
                                  if (e.type === 2) {
                                    return (
                                      <li key={i}>
                                        <input
                                          className="styled-checkbox"
                                          id={`BGM-${i}`}
                                          type="checkbox"
                                          name="bgm_id"
                                          onChange={handleCheckBox}
                                          value={e.id}
                                          checked={stateList?.bgm_id?.includes(
                                            e.id.toString()
                                          )}
                                        // value="Orange"
                                        />
                                        <label htmlFor={`BGM-${i}`}>
                                          {e.name}
                                        </label>
                                      </li>
                                    );
                                  } else return false;
                                })}
                              </>
                            )}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* End::Fluorescence */}

              {/* Begin:: LAB */}
              <div className="row border-bottom search-row">
                <div className="col-lg-2">Lab</div>
                <div className="col-lg-10">
                  <ul className="row g-1 search-items-list">
                    {Object.keys(details)?.length !== 0 && (
                      <>
                        {Object.keys(details?.Lab)?.map((e, i) => {
                          return (
                            <li key={i}>
                              <input
                                className="styled-checkbox"
                                id={`Lab-${i}`}
                                type="checkbox"
                                name="lab"
                                onChange={handleCheckBox}
                                value={e}
                                checked={stateList?.lab?.includes(e.toString())}
                              // value="Orange"
                              />
                              <label htmlFor={`Lab-${i}`}>
                                {details?.Lab[e]}
                              </label>
                            </li>
                          );
                        })}
                      </>
                    )}
                  </ul>
                </div>
              </div>
              {/* End:: LAB */}

              {/* Begin:: Type */}
              {diamondType === 2 && (
                <div className="row border-bottom search-row">
                  <div className="col-lg-2">Growth Type</div>
                  <div className="col-lg-10">
                    <ul className=" search-items-list">
                      {Object.keys(details).length !== 0 && (
                        <>
                          {Object.keys(details?.Type)?.map((e, i) => {
                            return (
                              <li key={i}>
                                <input
                                  className="styled-checkbox"
                                  id={`DiamondType-${i}`}
                                  type="checkbox"
                                  name="growth_type"
                                  onChange={handleCheckBox}
                                  value={e}
                                  checked={stateList?.growth_type?.includes(
                                    e.toString()
                                  )}
                                // value="Orange"
                                />
                                <label htmlFor={`DiamondType-${i}`}>
                                  {details?.Type[e]}
                                </label>
                              </li>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              {/* End:: Type */}

              {/* Begin:: Price */}
              <div className="row border-bottom search-row">
                <div className="col-lg-2">Price</div>
                <div className="col-lg-10">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="search-head mb-2">$/CT</div>
                      <div className="row g-1">
                        <div className="col-6">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="From"
                            aria-label="From"
                            name="ct_from"
                            value={stateList.ct_from}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="To"
                            aria-label="To"
                            name="ct_to"
                            value={stateList.ct_to}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="search-head mb-2">Discount</div>
                      <div className="row g-1">
                        <div className="col-6">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="From"
                            aria-label="From"
                            name="discount_from"
                            value={stateList.discount_from}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="To"
                            aria-label="To"
                            name="discount_to"
                            value={stateList.discount_to}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="search-head mb-2">Value</div>
                      <div className="row g-1">
                        <div className="col-6">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="From"
                            aria-label="From"
                            name="value_from"
                            value={stateList.value_from}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="To"
                            aria-label="To"
                            name="value_to"
                            value={stateList.value_to}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End:: Price */}

              {/* Begin:: STONE ID / CERTIFICATE NO | LOCATION */}
              <div className="row border-bottom search-row">
                <div className="col-lg-2">
                  Stone ID / Certificate No | Location
                </div>
                <div className="col-lg-10">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="search-head mb-2">
                        Stone ID / Certificate No
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Stone ID / Certi No. here..."
                        name="stone_id"
                        value={stateList.stone_id}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="search-head mb-2">Location</div>
                      <ul className="search-items-list">
                        {Object.keys(details).length !== 0 && (
                          <>
                            {details?.Location?.map((e, i) => {
                              return (
                                <li key={i}>
                                  <input
                                    className="styled-checkbox"
                                    id={`Country-${i}`}
                                    type="checkbox"
                                    name="location"
                                    onChange={handleCheckBox}
                                    value={e.country}
                                    checked={stateList?.location?.includes(
                                      e.country.toString()
                                    )}
                                  // value="Cushion"
                                  />
                                  <label htmlFor={`Country-${i}`}>
                                    {e.country}
                                  </label>
                                </li>
                              );
                            })}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End:: STONE ID / CERTIFICATE NO | LOCATION */}
              {diamondType === 2 && (
                <div className="mb-5 row mt-4 pb-4 border-bottom">
                  <div className="col-6">
                    {" "}
                    <ul className="row g-1 search-items-list">
                      <li className="">
                        <input
                          className="styled-checkbox"
                          id={"nobgm"}
                          type="checkbox"
                          name="no_bgm"
                          value={stateList.no_bgm}
                          checked={
                            parseInt(stateList?.no_bgm) === 0 ? false : true
                          }
                          onChange={handleCheckBox}
                        />
                        <label htmlFor={"nobgm"} className="py-2 px-5">
                          No BGM
                        </label>
                      </li>
                      <li className="">
                        <input
                          className="styled-checkbox"
                          id={"h&A"}
                          type="checkbox"
                          name="h_a"
                          checked={
                            parseInt(stateList?.h_a) === 0 ? false : true
                          }
                          onChange={handleCheckBox}
                        />
                        <label htmlFor={"h&A"} className="py-2 px-5">
                          H&A
                        </label>
                      </li>
                      <li className="">
                        <input
                          className="styled-checkbox"
                          id={"eyeclean"}
                          type="checkbox"
                          name="eye_clean"
                          checked={
                            parseInt(stateList?.eye_clean) === 0 ? false : true
                          }
                          onChange={handleCheckBox}
                        />
                        <label htmlFor={"eyeclean"} className="py-2 px-5">
                          Eye Clean
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="row g-1 search-items-list">
                      <li>
                        <input
                          className="styled-checkbox"
                          id={"asgrown"}
                          type="checkbox"
                          name="as_grown"
                          checked={
                            parseInt(stateList?.as_grown) === 0 ? false : true
                          }
                          onChange={handleCheckBox}
                        />
                        <label htmlFor={"asgrown"} className="py-2 px-5">
                          As Grown
                        </label>
                      </li>
                      <li>
                        <input
                          className="styled-checkbox"
                          id={"treated"}
                          type="checkbox"
                          name="treated"
                          checked={
                            parseInt(stateList?.treated) === 0 ? false : true
                          }
                          onChange={handleCheckBox}
                        />
                        <label htmlFor={"treated"} className="py-2 px-5">
                          Treated
                        </label>
                      </li>
                      <li>
                        <input
                          className="styled-checkbox"
                          id={"unknow"}
                          type="checkbox"
                          name="unknown"
                          checked={
                            parseInt(stateList?.unknown) === 0 ? false : true
                          }
                          onChange={handleCheckBox}
                        />
                        <label htmlFor={"unknow"} className="py-2 px-5">
                          Unknown
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {/* Begin::Advance Search */}
              <Accordion className="mt-5 mb-5 search-accordian">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Advance - Measurement Search
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* Begin:: Metric */}
                    <div className="row border-bottom search-row">
                      <div className="col-lg-2">Metric</div>
                      <div className="col-lg-10">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="search-head mb-2 text-start">
                              Length
                            </div>
                            <div className="row g-1">
                              <div className="col-6">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="From"
                                  aria-label="From"
                                  name="length_from"
                                  value={stateList.length_from}
                                  onChange={handleInput}
                                />
                              </div>
                              <div className="col-6">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="To"
                                  aria-label="To"
                                  name="length_to"
                                  value={stateList.length_to}
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="search-head mb-2 text-start">
                              Width
                            </div>
                            <div className="row g-1">
                              <div className="col-6">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="From"
                                  aria-label="From"
                                  name="width_from"
                                  value={stateList.width_from}
                                  onChange={handleInput}
                                />
                              </div>
                              <div className="col-6">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="To"
                                  aria-label="To"
                                  name="width_to"
                                  value={stateList.width_to}
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End:: Metric */}

                    {/* Begin:: Height | Table */}
                    <div className="row border-bottom search-row">
                      <div className="col-lg-2">
                        Height{diamondType === 2 && <> | Table</>}
                      </div>
                      <div className="col-lg-10">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="search-head mb-2 text-start">
                              Height
                            </div>
                            <div className="row g-1">
                              <div className="col-6">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="From"
                                  aria-label="From"
                                  name="height_from"
                                  value={stateList.height_from}
                                  onChange={handleInput}
                                />
                              </div>
                              <div className="col-6">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="To"
                                  aria-label="To"
                                  name="height_to"
                                  value={stateList.height_to}
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>
                          {diamondType === 2 && (
                            <div className="col-lg-6">
                              <div className="search-head mb-2 text-start">
                                Table
                              </div>
                              <div className="row g-1">
                                <div className="col-md-6">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="From"
                                    aria-label="From"
                                    name="table_from"
                                    value={stateList.table_from}
                                    onChange={handleInput}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="To"
                                    aria-label="To"
                                    name="table_to"
                                    value={stateList.table_to}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* End:: Height | Table */}

                    {/* Begin:: Total Depth | Ratio */}
                    <div className="row border-bottom search-row">
                      <div className="col-lg-2">
                        {diamondType === 2 && <> Total Depth | </>}Ratio
                      </div>
                      <div className="col-lg-10">
                        <div className="row">
                          {diamondType === 2 && (
                            <div className="col-lg-6">
                              <div className="search-head mb-2 text-start">
                                Total Depth
                              </div>
                              <div className="row g-1">
                                <div className="col-md-6">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="From"
                                    aria-label="From"
                                    name="depth_from"
                                    value={stateList.depth_from}
                                    onChange={handleInput}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="To"
                                    aria-label="To"
                                    name="depth_to"
                                    value={stateList.depth_to}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-lg-6">
                            <div className="search-head mb-2 text-start">
                              Ratio
                            </div>
                            <div className="row g-1">
                              <div className="col-md-6">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="From"
                                  aria-label="From"
                                  name="ratio_from"
                                  value={stateList.ratio_from}
                                  onChange={handleInput}
                                />
                              </div>
                              <div className="col-md-6">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="To"
                                  aria-label="To"
                                  name="ratio_to"
                                  value={stateList.ratio_to}
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End:: Total Depth | Ratio */}

                    {/* Begin:: Inclusion */}
                    {diamondType === 1 && (
                      <div className="row search-row">
                        <div className="col-lg-2">Inclusion</div>
                        <div className="col-lg-10">
                          <div className="row">
                            <div className="col-lg-12 mb-3">
                              <label className="fw-semibold">
                                Key to Symbol
                              </label>
                              <div className="d-flex">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="keytoSymbol"
                                    id="contain"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="contain"
                                  >
                                    Contains
                                  </label>
                                </div>
                                <div className="form-check ms-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="keytoSymbol"
                                    id="notcontain"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="notcontain"
                                  >
                                    Does not contains
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="search-head mb-2 text-start">
                                Table Open Inclusion
                              </div>
                              <div className="multiSelect  mb-3">
                                <Select
                                  options={naturalDetails.table_open}
                                  name="table_open"
                                  value={selectedNaturalDetails.table_open}
                                  isMulti
                                  onChange={(e) => {
                                    setSelectedNaturalsDetails((prev) => ({
                                      ...prev,
                                      table_open: [...e],
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="search-head mb-2 text-start">
                                Table white Inclusion
                              </div>
                              <div className="multiSelect mb-3">
                                <Select
                                  options={naturalDetails.white_table}
                                  name="white_table"
                                  value={selectedNaturalDetails.white_table}
                                  isMulti
                                  onChange={(e) => {
                                    setSelectedNaturalsDetails((prev) => ({
                                      ...prev,
                                      white_table: [...e],
                                    }));
                                  }}
                                />
                              </div>
                              <div className="search-head mb-2 text-start">
                                Side white Inclusion
                              </div>
                              <div className="multiSelect mb-3">
                                <Select
                                  options={naturalDetails.white_side}
                                  name="white_side"
                                  value={selectedNaturalDetails.white_side}
                                  isMulti
                                  onChange={(e) => {
                                    setSelectedNaturalsDetails((prev) => ({
                                      ...prev,
                                      white_side: [...e],
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="search-head mb-2 text-start">
                                Table Black Inclusion
                              </div>
                              <div className="multiSelect mb-3">
                                <Select
                                  options={naturalDetails.table_black}
                                  value={selectedNaturalDetails.table_black}
                                  name="table_black"
                                  isMulti
                                  onChange={(e) => {
                                    setSelectedNaturalsDetails((prev) => ({
                                      ...prev,
                                      table_black: [...e],
                                    }));
                                  }}
                                />
                              </div>
                              <div className="search-head mb-2 text-start">
                                Side Black Inclusion
                              </div>
                              <div className="multiSelect mb-3">
                                <Select
                                  options={naturalDetails.side_black}
                                  name="side_black"
                                  value={selectedNaturalDetails.side_black}
                                  isMulti
                                  onChange={(e) => {
                                    setSelectedNaturalsDetails((prev) => ({
                                      ...prev,
                                      side_black: [...e],
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* End:: Inclusion */}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* End::Advance Search */}

              <div className=" mb-5 d-flex">
                <button
                  type="button"
                  className="btn btn-secondary text-white me-2"
                  onClick={GoToDemand}
                >
                  <i className="fa-regular fa-floppy-disk me-2"></i>Save Demand
                </button>
                {/* <button type="submit" className="btn btn-primary text-white ms-auto"><i className="fa-solid fa-magnifying-glass me-2"></i> Search</button> */}
                <button
                  className="btn btn-primary text-white ms-auto"
                  onClick={() => handleFunctions("search")}
                >
                  <i className="fa-solid fa-magnifying-glass me-2"></i> Search
                </button>
              </div>
            </div>
          </main>
          <Footer />
        </>
      )}

      {isSaveDemand && (
        <>
          <Modal
            show={isSaveDemand}
            onHide={() => {
              setIsSaveDemand(false);
              setDemandDetails({
                name: "",
                quantity: 0,
              });
              error["demandName"] = false;
              error["quantity"] = false;
            }}
            backdrop={"static"}
          >
            <Modal.Header closeButton>
              <Modal.Title>Demand Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={6} className="mb-3">
                  <label>Demad Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter Demand Name"
                    className="form-control"
                    value={demandDetails.name}
                    onChange={(e) => {
                      setDemandDetails((pre) => ({
                        ...pre,
                        name: e.target.value,
                      }));
                    }}
                  />
                  {error.demandName && (
                    <span className="form-error">{error.demandName}</span>
                  )}
                </Col>
                <Col md={6} className="mb-3">
                  <label>Quantity</label>
                  <input
                    type="number"
                    name="quantity"
                    placeholder="Enter quantity"
                    className="form-control"
                    value={demandDetails.quantity}
                    onChange={(e) => {
                      setDemandDetails((pre) => ({
                        ...pre,
                        quantity: e.target.value,
                      }));
                    }}
                  />
                  {error.quantity && (
                    <span className="form-error">{error.quantity}</span>
                  )}
                </Col>
              </Row>
              <Button
                className="btn btn-primary text-white ms-auto d-block"
                onClick={saveDemandName}
                type="submit"
              >
                Submit
              </Button>
            </Modal.Body>
          </Modal>
        </>
      )}
      {isSaveSearch && (
        <>
          <Modal
            show={isSaveSearch}
            onHide={() => {
              setIsSaveSearch(false);
              setDemandDetails({
                name: "",
                quantity: 0,
              });
              error["demandName"] = false;
              error["quantity"] = false;
            }}
            backdrop={"static"}
          >
            <Modal.Header closeButton>
              <Modal.Title>Save Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={12} className="mb-3">
                  <label>Search Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter search Name"
                    className="form-control"
                    value={demandDetails.name}
                    onChange={(e) => {
                      setDemandDetails((pre) => ({
                        ...pre,
                        name: e.target.value,
                      }));
                    }}
                  />
                  {error.demandName && (
                    <span className="form-error">{error.demandName}</span>
                  )}
                </Col>
              </Row>
              <Button
                className="btn btn-primary text-white ms-auto d-block"
                onClick={handleSaveSearch}
                type="submit"
              >
                Submit
              </Button>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  masterListReducer: state.masterReducer.masterList,
  searchDiamondsReducer: state.labGrownReducer.searchDiamonds,
  addDemandReducer: state.demandReducer.addDemand,
  getNewArrivalListReducer: state.masterReducer.getNewArrivalList,
  addSaveSearchListReducer: state.saveSearchListReducer.addSaveSearchList,
});
const mapDispatchToProps = (dispatch) => ({
  getMastersList: (details) => dispatch(getMastersList(details)),
  searchDiamonds: (details) => dispatch(searchDiamonds(details)),
  addDemandList: (details) => dispatch(addDemandList(details)),
  getNewArrivalList: (details) => dispatch(getNewArrivalList(details)),
  addSaveSearchList: (details) => dispatch(addSaveSearchList(details)),
  resetSavedSearch: (details) => dispatch({ type: ADD_SAVE_SEARCH_RESET }),
  resetAddDemand: (details) => dispatch({ type: ADD_DEMAND_LIST_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabGrown);
