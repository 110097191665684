// const baseurl = "http://localhost:3001/";
const baseurl = "https://delightdiamonds.com";
  const imageurl = {
      logo : baseurl + "/images/logo.jpeg",
      yellowDiam : baseurl + "/images/icon_fancy_yellow.png",
      orangeDiam : baseurl + "/images/icon_fancy_orange.png",
      pinkDiam : baseurl + "/images/icon_fancy_pink.png",
      blueDiam : baseurl + "/images/icon_fancy_blue.png",
      greenDiam : baseurl + "/images/icon_fancy_green.png",
      brownDiam : baseurl + "/images/icon_fancy_brown.png",
      redDiam : baseurl + "/images/icon_fancy_red.png",
      whiteDiam : baseurl + "/images/icon_fancy_white.png",
      violetDiam : baseurl + "/images/icon_fancy_violet.png",
      purpleDiam : baseurl + "/images/icon_fancy_purple.png",
      grayDiam : baseurl + "/images/icon_fancy_gray.png",
      oliveDiam : baseurl + "/images/icon_fancy_olive.png",
      blackDiam : baseurl + "/images/icon_fancy_black.png",
      otherDiam : baseurl + "/images/icon_fancy_other.png",
      adBanner : baseurl + "/images/ad-banner.jpg",

      aboutComp: baseurl + "/images/about.jpg",
      ecoConscious: baseurl + "/images/eco-conscious.png",
      conflictFree: baseurl + "/images/conflict-free.png",
      genuine: baseurl + "/images/genuine.png",
      quality: baseurl + "/images/quality.png",
      value: baseurl + "/images/value.png",
      cerified: baseurl + "/images/certified.png",
      diamondEthic: baseurl + "/images/diamond-ethics.png",
      labGrown: baseurl + "/images/labGrown.png",
      hphtDiamond: baseurl + "/images/hpht-diamond.jpg",
      CVDImg: baseurl + "/images/cvd.webp",
      LabVSNatural: baseurl + "/images/lab-diamonds-vs-real-diamonds.png",
      diamondChart: baseurl + "/images/Diamond-Chart.jpg",
      table: baseurl + "/images/01-Table.gif",
      crown: baseurl + "/images/02-Crown.gif",
      girdle: baseurl + "/images/03-Girdle.gif",
      pavilion: baseurl + "/images/04-Pavilion.gif",
      culet: baseurl + "/images/05-Culet.gif",
      popupBanner: baseurl + "/images/promotinal-img.jpeg",
  }

  export default imageurl;