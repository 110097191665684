import React, { useState } from "react";
import Footer from "../Layout/Footer";
import { Container, Navbar, Offcanvas, Nav } from "react-bootstrap";
import imageurl from "../images";
import { useLocation, Link } from "react-router-dom";

const VerifyEmail = () => {
  const [isVisible] = useState(false);
  const path = useLocation();
  const token = localStorage.getItem("token");
  return (
    <div>
      <header className={"border-bottom-0 " + (isVisible && "fixed")}>
        <Navbar bg="dark" expand="lg" variant="dark" className="py-4">
          <Container>
            <Navbar.Brand href="#home">
              {path.pathname === "/" ? (
                isVisible && (
                  <img
                    src={imageurl.logo}
                    className="img-fluid"
                    alt="Delight Diamonds"
                  />
                )
              ) : (
                <img
                  src={imageurl.logo}
                  className="img-fluid"
                  alt="Delight Diamonds"
                />
              )}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-main" />
            <Navbar.Offcanvas
              id={`navbar-main`}
              aria-labelledby={`navbar-mainLabel-expand`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`navbar-mainLabel-expand}`}>
                  <img
                    src={imageurl.logo}
                    className="img-fluid logo"
                    alt="Delight Diamonds"
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="ms-auto justify-content-end align-items-lg-center flex-grow-1">
                  <Nav.Link
                    className={path.pathname === "/" ? "active" : ""}
                    as={Link}
                    to="/"
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/about"
                    className={path.pathname === "/about" ? "active" : ""}
                  >
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/LabGrownDiamond"
                    className={
                      path.pathname === "/LabGrownDiamond" ? "active" : ""
                    }
                  >
                    Lab Grown
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/knowledge"
                    className={path.pathname === "/knowledge" ? "active" : ""}
                  >
                    Knowledge
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/contact"
                    className={path.pathname === "/contact" ? "active" : ""}
                  >
                    Contact us
                  </Nav.Link>

                  <>
                    {!token ? (
                      <>
                        {" "}
                        <Nav.Link
                          as={Link}
                          to="/login"
                          className="d-flex align-items-center nav-login"
                        >
                          <i className="ri-user-line me-2"></i> Login
                        </Nav.Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </header>
      <Container fluid className="banner">
        <Container>
          <h2 className="pageName" data-aos="zoom-out" data-aos-duration="1000">
            Email Verified
          </h2>
        </Container>
      </Container>
      <main>
        <section>
          <Container>
            <h2 className="text-center">
              {" "}
              We have send verification email and verify to login
            </h2>
          </Container>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default VerifyEmail;
