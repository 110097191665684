import {
  ADD_STARMELEE_CARAT_ERROR,
  ADD_STARMELEE_CARAT_RESET,
  ADD_STARMELEE_CARAT_SUCCESS,
  ADD_STARMELEE_INQUAIRY_ERROR,
  ADD_STARMELEE_INQUAIRY_RESET,
  ADD_STARMELEE_INQUAIRY_SUCCESS,
  ADD_STARMELEE_PRICE_ERROR,
  ADD_STARMELEE_PRICE_RESET,
  ADD_STARMELEE_PRICE_SUCCESS,
  GET_STARMELEE_DIAMONDS_ERROR,
  GET_STARMELEE_DIAMONDS_RESET,
  GET_STARMELEE_DIAMONDS_SUCCESS,
  GET_STARMELEE_INQUAIRY_LIST_ERROR,
  GET_STARMELEE_INQUAIRY_LIST_RESET,
  GET_STARMELEE_INQUAIRY_LIST_SUCCESS,
} from "../common/constant";

let initialState = {
  starmeleeDiamondList: {
    error: false,
    data: null,
    errors: null,
    success: false,
  },
  starmeleeCaratList: {
    error: false,
    errors: null,
    success: false,
    data: [],
  },
  starmeleePriceList: {
    error: false,
    errors: null,
    success: false,
    data: [],
  },
  starmeleeInquairyList: {
    error: false,
    errors: null,
    success: false,
    data: [],
  },
  getstarmeleeInquairyList: {
    error: false,
    data: null,
    errors: null,
    success: false,
  },
};

export default function starmeleeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STARMELEE_DIAMONDS_SUCCESS:
      return {
        ...state,
        starmeleeDiamondList: {
          ...state.starmeleeDiamondList,
          success: true,
          error: false,
          errors: null,
          data: action.response,
        },
      };

    case GET_STARMELEE_DIAMONDS_ERROR:
      return {
        ...state,
        starmeleeDiamondList: {
          ...state.starmeleeDiamondList,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case GET_STARMELEE_DIAMONDS_RESET:
      return {
        ...state,
        starmeleeDiamondList: {
          ...state.starmeleeDiamondList,
          success: false,
          error: false,
          errors: null,
        },
      };

    // carat
    case ADD_STARMELEE_CARAT_SUCCESS:
      return {
        ...state,
        starmeleeCaratList: {
          ...state.starmeleeCaratList,
          success: true,
          error: false,
          errors: null,
          data: action.data,
        },
      };

    case ADD_STARMELEE_CARAT_ERROR:
      return {
        ...state,
        starmeleeCaratList: {
          ...state.starmeleeCaratList,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case ADD_STARMELEE_CARAT_RESET:
      return {
        ...state,
        starmeleeCaratList: {
          ...state.starmeleeCaratList,
          success: false,
          error: false,
          errors: null,
        },
      };

    // carat
    case ADD_STARMELEE_PRICE_SUCCESS:
      return {
        ...state,
        starmeleePriceList: {
          ...state.starmeleePriceList,
          success: true,
          error: false,
          errors: null,
          data: action.data,
        },
      };

    case ADD_STARMELEE_PRICE_ERROR:
      return {
        ...state,
        starmeleePriceList: {
          ...state.starmeleePriceList,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case ADD_STARMELEE_PRICE_RESET:
      return {
        ...state,
        starmeleePriceList: {
          ...state.starmeleePriceList,
          success: false,
          error: false,
          errors: null,
        },
      };

    // Inquairy
    case ADD_STARMELEE_INQUAIRY_SUCCESS:
      return {
        ...state,
        starmeleeInquairyList: {
          ...state.starmeleeInquairyList,
          success: true,
          error: false,
          errors: null,
          data: action.data,
        },
      };

    case ADD_STARMELEE_INQUAIRY_ERROR:
      return {
        ...state,
        starmeleeInquairyList: {
          ...state.starmeleeInquairyList,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case ADD_STARMELEE_INQUAIRY_RESET:
      return {
        ...state,
        starmeleeInquairyList: {
          ...state.starmeleeInquairyList,
          success: false,
          error: false,
          errors: null,
        },
      };

      case GET_STARMELEE_INQUAIRY_LIST_SUCCESS:
        return {
          ...state,
          getstarmeleeInquairyList: {
            ...state.getstarmeleeInquairyList,
            success: true,
            error: false,
            errors: null,
            data: action.response,
          },
        };
  
      case GET_STARMELEE_INQUAIRY_LIST_ERROR:
        return {
          ...state,
          getstarmeleeInquairyList: {
            ...state.getstarmeleeInquairyList,
            success: false,
            error: true,
            errors: action.msg,
          },
        };
  
      case GET_STARMELEE_INQUAIRY_LIST_RESET:
        return {
          ...state,
          getstarmeleeInquairyList: {
            ...state.getstarmeleeInquairyList,
            success: false,
            error: false,
            errors: null,
          },
        };


    default:
      return state;
  }
}
