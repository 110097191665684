import axios from "axios";
import { GET_SEARCH_STONE_ERROR, GET_SEARCH_STONE_SUCCESS } from "../common/constant";
import { searchStone_Url } from "../common/apiSheet";

export const serachStoneAction = (details) => {
    return (dispatch) => {
      const requestOptions = {
        method: "POST",
        url: `${searchStone_Url}`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
        data: details
      }
      axios(requestOptions)
        .then((resp) => {
          dispatch({
            type: GET_SEARCH_STONE_SUCCESS,
            msg: resp.data.data.error_message,
            data: resp.data.data.result
          });
        })
        .catch((error) => {
          let data = error.response;
          if (!error.response) {
            dispatch({
              type: GET_SEARCH_STONE_ERROR,
              msg: error,
            });
          } else if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_SEARCH_STONE_ERROR,
              msg: validationError,
            });
          }
        });
    };
  };