import { getter } from "@progress/kendo-data-query";
import { getSelectedState } from "@progress/kendo-react-data-tools";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getHoldStoneList } from "../../userStore/action/holdStoneAction";
import PdfModal from "../modals/PdfModal";
import StonePreviewModal from "../modals/StonePreviewModal";
import StoneVideoPreviewModal from "../modals/StoneVideoPreviewModal";
import Loader from "../../Components/Loader";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

function HoldStoneTable(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [imgShow, setImgShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [videoShow, setVideoShow] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [certificateShow, setCertificateShow] = useState(false);
  const [selectedCert, setSelectedCert] = useState("");
  const profileData = props.profileReducer.data;
  const [state, setState] = useState({
    items: [],
    // pageable: {
    //   buttonCount: 5,
    //   info: true,
    //   type: "numeric",
    //   pageSizes: true,
    //   previousNext: true,
    // },
    // total: 0,
    // skip: 0,
    // pageSize: 10,
  });

  const [selectedState, setSelectedState] = useState({});

  const [dataState, setDataState] = React.useState(
    state.items.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    )
  );

  useEffect(() => {
    const list = state.items.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    );
    setDataState([...list]);
  }, [state.items]);

  useEffect(() => {
    props.getHoldStoneList();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.HoldStoneListReducer.success) {
      const data = props.HoldStoneListReducer.data;
      const list = data.map((e, i) => {
        return {
          image:
            !!e.product?.image && e.product?.image !== ""
              ? e.product?.image
              : "N/A",
          video:
            !!e.product?.video && e.product?.video !== ""
              ? e.product?.video
              : "N/A",
          cert_url:
            !!e.product?.cert_url && e.product?.cert_url !== ""
              ? e.product?.cert_url
              : "N/A",
          stone_id:
            !!e.product?.stone_id && e.product?.stone_id !== ""
              ? e.product?.stone_id
              : "N/A",
          cert:
            !!e.product?.cert && e.product?.cert !== ""
              ? e.product?.cert
              : "N/A",
          cert_no:
            !!e.product?.cert_no && e.product?.cert_no !== ""
              ? e.product?.cert_no
              : "N/A",
          country:
            !!e.product?.country && e.product?.country !== ""
              ? e.product?.country
              : "N/A",
          growth_type:
            !!e.product?.growth_type && e.product?.growth_type !== ""
              ? e.product?.growth_type
              : "N/A",
          shape:
            !!e.product?.shape_name && e.product?.shape_name !== ""
              ? e.product?.shape_name
              : "N/A",
          color:
            !!e.product?.color_name && e.product?.color_name !== ""
              ? e.product?.color_name
              : "N/A",
          Overtone:
            !!e.product?.overtone_name && e.product?.overtone_name !== ""
              ? e.product?.overtone_name
              : "N/A",
          intensity:
            !!e.product?.intensity_name && e.product?.intensity_name !== ""
              ? e.product?.intensity_name
              : "N/A",
          rate:
            !!e.product?.rate && e.product?.rate !== ""
              ? e.product?.rate
              : "N/A",
          clarity:
            !!e.product?.clarity_name && e.product?.clarity_name !== ""
              ? e.product?.clarity_name
              : "N/A",
          cut:
            !!e.product?.cut_name && e.product?.cut_name !== ""
              ? e.product?.cut_name
              : "N/A",
          polish:
            !!e.product?.polish_name && e.product?.polish_name !== ""
              ? e.product?.polish_name
              : "N/A",
          symmetry:
            !!e.product?.symmetry_name && e.product?.symmetry_name !== ""
              ? e.product?.symmetry_name
              : "N/A",
          rapRate:
            !!e.product?.rapo_rate && e.product?.rapo_rate !== ""
              ? e.product?.rapo_rate
              : "N/A",
          disc:
            !!e.product?.discount && e.product?.discount !== ""
              ? e.product?.discount
              : "N/A",
          carat:
            !!e.product?.carat && e.product?.carat !== ""
              ? e.product?.carat
              : "N/A",
          amount:
            !!e.product?.amount && e.product?.amount !== ""
              ? e.product?.amount
              : "N/A",
          measurement:
            !!e.product?.measurement && e.product?.measurement !== ""
              ? e.product?.measurement
              : "N/A",
          ratio:
            !!e.product?.ratio && e.product?.ratio !== ""
              ? e.product?.ratio
              : "N/A",
          tabel_per:
            !!e.product?.table_per && e.product?.table_per !== ""
              ? e.product?.table_per
              : "N/A",
          depth_per:
            !!e.product?.depth_per && e.product?.depth_per !== ""
              ? e.product?.depth_per
              : "N/A",
          bgm:
            !!e.product?.bgm_name && e.product?.bgm_name !== ""
              ? e.product?.bgm_name
              : "N/A",
          fluorescence:
            !!e.product?.fluorescence_name &&
            e.product?.fluorescence_name !== ""
              ? e.product?.fluorescence_name
              : "N/A",
          fancy_color:
            !!e.product?.colors_name && e.product?.colors_name !== ""
              ? e.product?.colors_name
              : "N/A",
          key_to_symbols:
            !!e.product?.key_to_symbols && e.product?.key_to_symbols !== ""
              ? e.product?.key_to_symbols
              : "N/A",
          product_id: e.product_id,
          id: e.id,
          selected: false,

          // stone_id: e.product?.stone_id ? e.product?.stone_id : "N/A",
          // cert: e.product?.cert ? e.product?.cert : "N/A",
          // cert_no: e.product?.cert_no ? e.product?.cert_no : "N/A",
          // cert_url: e.product?.cert_url ? e.product?.cert_url : "N/A",
          // growth_type: e.product?.growth_type ? e.product?.growth_type : "N/A",
          // // size: e.product?.size_name ? e.product?.size_name : "N/A",
          // image: e.product?.image ? e.product?.image : "N/A",
          // video: e.product?.video ? e.product?.video : "N/A",
          // country: e.product?.country ? e.product?.country : "N/A",
          // amount: e.product?.amount ? e.product?.amount : "N/A",
          // rapRate: e.product?.rapo_rate ? e.product?.rapo_rate : "N/A",
          // disc: e.product?.discount ? e.product?.discount : "N/A",
          // shape: e.product?.shape_name ? e.product?.shape_name : "N/A",
          // carat: e.product?.carat ? e.product?.carat : "N/A",
          // color: e.product?.color_name ? e.product?.color_name : "N/A",
          // fancy_color: e.product?.colors_name ? e.product?.colors_name : "N/A",

          // clarity: e.product?.clarity_name ? e.product?.clarity_name : "N/A",
          // cut: e.product?.cut_name ? e.product?.cut_name : "N/A",
          // polish: e.product?.polish_name ? e.product?.polish_name : "N/A",
          // symmetry: e.product?.symmetry_name ? e.product?.symmetry_name : "N/A",
          // fluorescence: e.product?.fluorescence_name
          //   ? e.product?.fluorescence_name
          //   : "N/A",
          // fluorescence_color: e.product?.fluorescence_color_name
          //   ? e.product?.fluorescence_color_name
          //   : "N/A",
          // rate: e.product?.rate ? e.product?.rate : "N/A",
          // table: e.product?.table ? e.product?.table : "N/A",
          // depth: e.product?.depth ? e.product?.depth : "N/A",
          // measurement: e.product?.measurement ? e.product?.measurement : "N/A",
          // ratio: e.product?.ratio ? e.product?.ratio : "N/A",
          // length: e.product?.length ? e.product?.length : "N/A",
          // width: e.product?.width ? e.product?.width : "N/A",
          // tabel_per: e.product?.table_per ? e.product?.table_per : "N/A",
          // depth_per: e.product?.depth_per ? e.product?.depth_per : "N/A",
          // height: e.product?.height ? e.product?.height : "N/A",
          // milky: e.product?.milky ? e.product?.milky : "N/A",
          // shade: e.product?.shade ? e.product?.shade : "N/A",
          // crown_angle: e.product?.crown_angle ? e.product?.crown_angle : "N/A",
          // crown_height: e.product?.crown_height
          //   ? e.product?.crown_height
          //   : "N/A",
          // crown_open: e.product?.crown_open ? e.product?.crown_open : "N/A",
          // pavilion_angle: e.product?.pavilion_angle
          //   ? e.product?.pavilion_angle
          //   : "N/A",
          // pavilion_height: e.product?.pavilion_height
          //   ? e.product?.pavilion_height
          //   : "N/A",
          // pavilion_open: e.product?.pavilion_open
          //   ? e.product?.pavilion_open
          //   : "N/A",
          // white_table: e.product?.white_table ? e.product?.white_table : "N/A",
          // white_side: e.product?.white_side ? e.product?.white_side : "N/A",
          // table_black: e.product?.table_black ? e.product?.table_black : "N/A",
          // side_black: e.product?.side_black ? e.product?.side_black : "N/A",
          // table_open: e.product?.table_open ? e.product?.table_open : "N/A",
          // girdle: e.product?.girdle ? e.product?.girdle : "N/A",
          // girdle_desc: e.product?.girdle_desc ? e.product?.girdle_desc : "N/A",
          // culet: e.product?.culet ? e.product?.culet : "N/A",
          // key_to_symbols: e.product?.key_to_symbols
          //   ? e.product?.key_to_symbols
          //   : "N/A",
          // pair: e.product?.pair ? e.product?.pair : "N/A",
          // h_a: e.product?.h_a ? e.product?.h_a : "N/A",
          // eye_clean: e.product?.eye_clean ? e.product?.eye_clean : "N/A",

          // product_id: e.product_id ? e.product_id : "N/A",
          // id: e?.id,
          // selected: false,
        };
      });
      setState((pre) => ({
        ...pre,
        items: list,
      }));
      setLoading(false);
    }
  }, [props.HoldStoneListReducer]);

  useEffect(() => {
    props.setSelected(selectedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  useEffect(() => {
    if (props.deleteHoldStoneReducer.success) {
      setSelectedState({});
    }
  }, [props.deleteHoldStoneReducer]);

  const CustomCell = (cellProps) => {
    return (
      <td
        className="k-grid-content-sticky"
        style={{ left: "50px", right: "0px" }}
      >
        <button
          className="btn btn-sm p-0 me-2"
          disabled={cellProps.dataItem.image === "N/A"}
          onClick={() => {
            setSelectedImage(cellProps.dataItem.image);
            setImgShow(true);
          }}
        >
          <i className="fa-solid fa-camera-retro"></i>
        </button>
        <button
          className="btn btn-sm p-0 me-2"
          disabled={cellProps.dataItem.video === "N/A"}
          onClick={() => {
            setSelectedVideo(cellProps.dataItem.video);
            setVideoShow(true);
          }}
        >
          <i className="fa-solid fa-video"></i>
        </button>
        <button
          className="btn btn-sm p-0 me-2"
          disabled={cellProps.dataItem.cert_url === "N/A"}
          onClick={() => {
            setSelectedCert(cellProps.dataItem.cert_url);
            setCertificateShow(true);
          }}
        >
          <i className="fa-solid fa-certificate"></i>
        </button>
        <div
          className="btn btn-sm p-0"
          onClick={() => {
            navigate(`/detail/${cellProps.dataItem.product_id}`, {
              state: {
                path: location.pathname,
              },
            });
          }}
        >
          <i className="fa-solid fa-eye"></i>
        </div>
      </td>
    );
  };

  const MyCustomCell = (props) => <CustomCell {...props} />;

  // const pageChange = (event) => {
  //   setState((pre) => ({
  //     ...pre,
  //     items: pre.items.slice(
  //       event.page.skip,
  //       event.page.skip + event.page.take
  //     ),
  //     skip: event.page.skip,
  //     pageSize: event.page.take,
  //   }));
  // };

  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const grid = (
    <Grid
      style={{
        height: "70vh",
      }}
      data={dataState.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)],
      }))}
      // skip={state.skip}
      // take={state.take}
      // total={state.total}
      // pageable={state.pageable}
      // pageSize={state.pageSize}
      // onPageChange={pageChange}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      selectable={{
        enabled: true,
        drag: false,
        cell: false,
        mode: "multiple",
      }}
      onSelectionChange={onSelectionChange}
      onHeaderSelectionChange={onHeaderSelectionChange}
    >
      {/* <GridColumn field="view">sldkjklf</GridColumn> */}
      <GridColumn
        locked={true}
        field={SELECTED_FIELD}
        width="50px"
        headerSelectionValue={
          dataState.length === 0
            ? false
            : dataState.findIndex((item) => !selectedState[idGetter(item)]) ===
              -1
        }
      />
      <GridColumn
        field="Action"
        width="101"
        locked={true}
        cell={MyCustomCell}
      />

      <GridColumn field="stone_id" title="Stone ID" width="75" />
      <GridColumn field="cert_no" title="Certificate No" width="105" />

      <GridColumn field="shape" title="Shape" width="55" />
      <GridColumn field="carat" title="Carat" width="45" />
      <GridColumn field="color" title="Color" width="45" />
      <GridColumn field="fancy_color" title="Fancy Color" width="90" />
      <GridColumn field="intensity" title="Intensity" width="70" />
      <GridColumn field="Overtone" title="Overtone" width="70" />
      <GridColumn field="clarity" title="Clarity" width="55" />
      <GridColumn field="cut" title="Cut" width="40" />
      <GridColumn field="polish" title="Poilish" width="55" />
      <GridColumn field="symmetry" title="Symmetry" width="80" />
      <GridColumn field="rapRate" title="Rap Rate" width="70" />
      <GridColumn field="disc" title="Discount" width="70" />
      <GridColumn field="rate" title="$/CT" width="45" />
      <GridColumn field="amount" title="$ Total" width="65" />
      <GridColumn field="measurement" title="Measurement" width="105" />
      <GridColumn field="ratio" title="Ratio" width="50" />
      <GridColumn field="depth_per" title="Depth Per" width="78" />
      <GridColumn field="tabel_per" title="Table Per" width="70" />
      <GridColumn field="bgm" title="BGM" width="50" />
      <GridColumn field="fluorescence" title="Fluorescence" width="100" />
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="key_to_symbols" title="Key of Symbols" width="150" />
      )}
      <GridColumn field="cert" title=" Certificate" width="80" />
      <GridColumn field="country" title="Country" width="65" />
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="growth_type" title="Growth Type" width="95" />
      )}
      {/* 

      <GridColumn field="cert" title=" Certificate" width="100" />
      <GridColumn field="stone_id" title="Stone ID" width="100" />
      <GridColumn field="cert_no" title="Certificate No" width="130" />
       {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="size" title="Size" width="100" />
      )} 
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="growth_type" title="Growth Type" width="130" />
      )}
      <GridColumn field="country" title="Country" width="120" />
      <GridColumn field="carat" title="Carat" width="80" />
      <GridColumn field="shape" title="Shape" width="80" />
      <GridColumn field="color" title="Color" width="80" />
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="fancy_color" title="Fancy Color" width="110" />
      )}
      
      <GridColumn field="clarity" title="Clarity" width="80" />
      <GridColumn field="cut" title="Cut" width="80" />
      <GridColumn field="polish" title="Poilish" width="80" />
      <GridColumn field="symmetry" title="Symmetry" width="100" />
      <GridColumn field="fluorescence" title="Fluorescence" width="120" />
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn
          field="fluorescence_color"
          title="Fluorescence Color"
          width="80"
        />
      )}
      <GridColumn field="rapRate" title="Rap Rate" width="100" />
      <GridColumn field="disc" title="Discount" width="100" />
      <GridColumn field="rate" title="Rate $/CT" width="100" />
      <GridColumn field="amount" title="Amount" width="100" />
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="table" title="Table" width="100" />
      )}
      <GridColumn field="tabel_per" title="Table Per" width="100" />
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="depth" title="Depth" width="100" />
      )}
      <GridColumn field="depth_per" title="Depth Per" width="100" />

      <GridColumn field="measurement" title="Measurement" width="120" />
      <GridColumn field="ratio" title="Ratio" width="80" />
      <GridColumn field="length" title="Length" width="80" />
      <GridColumn field="width" title="Width" width="80" />
      <GridColumn field="height" title="Height" width="80" />
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="milky" title="Milky" width="100" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="shade" title="Shade" width="80" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="crown_angle" title="Crown Angle" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="crown_height" title="Crown Height" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="crown_open" title="Crown Open" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="pavilion_angle" title="Pavilion Angle" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn
          field="pavilion_height"
          title="Pavilion Height"
          width="120"
        />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="pavilion_open" title="Pavilion Open" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="white_table" title="White Table" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="white_side" title="White Side" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="table_black" title="Table Black" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="side_black" title="Side Black" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="table_open" title="Table Open" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="girdle" title="Girdle" width="80" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="girdle_desc" title="Girdle Desc" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="culet" title="Culet" width="80" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="key_to_symbols" title="Key of Symbols" width="160" />
      )}
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="pair" title="Pair" width="80" />
      )}
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="h_a" title="H & A" width="80" />
      )}
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="eye_clean" title="Eye Clean" width="120" />
      )} */}
    </Grid>
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {grid}
          {imgShow && (
            <StonePreviewModal
              show={imgShow}
              onHide={() => {
                setImgShow(false);
                setSelectedImage("");
              }}
              details={selectedImage}
            />
          )}
          {videoShow && (
            <StoneVideoPreviewModal
              show={videoShow}
              onHide={() => {
                setVideoShow(false);
                setSelectedVideo("");
              }}
              details={selectedVideo}
            />
          )}
          {certificateShow && (
            <PdfModal
              show={certificateShow}
              onHide={() => {
                setCertificateShow(false);
                setSelectedCert("");
              }}
              details={selectedCert}
            />
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  HoldStoneListReducer: state.holdStoneReducer.HoldStoneList,
  deleteHoldStoneReducer: state.holdStoneReducer.deleteHoldStone,
  profileReducer: state.authReducer.profile,
});
const mapDispatchToProps = (dispatch) => ({
  getHoldStoneList: () => dispatch(getHoldStoneList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HoldStoneTable);
