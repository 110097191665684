import axios from "axios";
import { addViewRequest_Url, deleteViewRequest_Url, viewRequest_Url } from "../common/apiSheet";
import { ADD_VIEW_REQUEST_ERROR, ADD_VIEW_REQUEST_SUCCESS, DELETE_VIEW_REQUEST_ERROR, DELETE_VIEW_REQUEST_SUCCESS, GET_VIEW_REQUEST_ERROR, GET_VIEW_REQUEST_SUCCESS } from "../common/constant";

export const getViewRequest = () => {
 return (dispatch) => {
  const requestOptions = {
   method: "GET",
   url: viewRequest_Url,
   headers: {
    'Authorization': `Bearer ${localStorage.getItem("token")}`
   }
  }
  axios(requestOptions)
   .then((resp) => {
    // localStorage.setItem('token', resp?.data.data.token)
    dispatch({
     type: GET_VIEW_REQUEST_SUCCESS,
     response: resp.data.data.result,
    });
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: GET_VIEW_REQUEST_ERROR,
      msg: error,
     });
    } else if (data.status === 422) {
     let validationError = data.data.message;
     dispatch({
      type: GET_VIEW_REQUEST_ERROR,
      msg: validationError,
     });
    }
   });
 };
};

export const addViewRequest = (details) => {
 return (dispatch) => {
  const requestOptions = {
   method: "POST",
   url: addViewRequest_Url,
   headers: {
    'Authorization': `Bearer ${localStorage.getItem("token")}`
   },
   data: details
  }
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: ADD_VIEW_REQUEST_SUCCESS,
    });
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: ADD_VIEW_REQUEST_ERROR,
      msg: error,
     });
    } else if (data.status === 422) {
     let validationError = data.data.message;
     dispatch({
      type: ADD_VIEW_REQUEST_ERROR,
      msg: validationError,
     });
    }
   });
 };
};

export const deleteViewRequest = (details) => {
 return (dispatch) => {
  const requestOptions = {
   method: "POST",
   url: deleteViewRequest_Url,
   headers: {
    'Authorization': `Bearer ${localStorage.getItem("token")}`
   },
   data: details
  }
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: DELETE_VIEW_REQUEST_SUCCESS,
    });
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: DELETE_VIEW_REQUEST_ERROR,
      msg: error,
     });
    } else if (data.status === 422) {
     let validationError = data.data.message;
     dispatch({
      type: DELETE_VIEW_REQUEST_ERROR,
      msg: validationError,
     });
    }
   });
 };
};