import React from "react";
import { Modal } from "react-bootstrap";

const StonePreviewModal = (props) => {
  return (
    <Modal show={props.show} centered onHide={props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Stone Preview</Modal.Title>
      </Modal.Header>
      {!!props.details && props.details !== "" && props.details !== "N/A" ? (
        <>
          <Modal.Body className="text-center">
            <img
              src={props.details}
              alt="Stone preview"
              className="img-fluid"
            />
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Body className="text-center my-5">
            <h4>No Image Available!!!</h4>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default StonePreviewModal;
