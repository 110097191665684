import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { checkDiamondsTypeAction, logout } from "../../userStore/action/authAction";
import { LOGOUT_RESET } from "../../userStore/common/constant";

const Sidebar = (props) => {
  const getPath = useLocation();
  const navigate = useNavigate();
  const profileData = props.profileReducer?.data;
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (props.logoutReducer.success) {
      navigate("/login");
      props.resetLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.logoutReducer.success, navigate]);

  useEffect(() => {
    if (props.profileReducer.success) {
      const data = props.profileReducer.data;
      setProfile(data);
    }
  }, [props.profileReducer]);
  return (
    <>
      {profileData?.diamond_type === 3 ? (
        <>
          <aside>
            <ul>
              <li>
                <Link
                  to="/dashboard"
                  className={getPath.pathname === "/dashboard" ? "active" : ""}
                >
                  <i className="ri-dashboard-line"></i> Dashboard
                </Link>
              </li>
            </ul>
          </aside>
        </>
      ) : (
        <>
          <aside>
            <ul>
              <li>
                <Link
                  to="/dashboard"
                  className={getPath.pathname === "/dashboard" ? "active" : ""}
                >
                  <i className="ri-dashboard-line"></i> Dashboard
                </Link>
              </li>
              <li>
                <Link
                  to={
                    !!profile && profile.diamond_type === 1
                      ? "/natural/search"
                      : "/labGrown/search"
                  }
                  className={
                    getPath.pathname === "/labGrown/search" ||
                      getPath.pathname === "/natural/search"
                      ? "active"
                      : ""
                  }
                >
                  <i className="ri-search-line"></i> Search
                </Link>
              </li>
              <li>
                <Link
                  to="/view-request"
                  className={
                    getPath.pathname === "/view-request" ? "active" : ""
                  }
                >
                  <i className="ri-eye-line"></i> View Request
                </Link>
              </li>
              <li>
                <Link
                  to="/demand-list"
                  className={
                    getPath.pathname === "/demand-list" ? "active" : ""
                  }
                >
                  <i className="ri-file-list-3-line"></i> Demand List
                </Link>
              </li>
              <li>
                <Link
                  to="/cart"
                  className={getPath.pathname === "/cart" ? "active" : ""}
                >
                  <i className="ri-shopping-cart-2-line"></i> Cart List
                </Link>
              </li>
              <li>
                <Link
                  to="/inquiry-list"
                  className={
                    getPath.pathname === "/inquiry-list" ? "active" : ""
                  }
                >
                  <i className="ri-price-tag-3-line"></i> Inquiry List
                </Link>
              </li>
              <li>
                <Link
                  to="/confirmed"
                  className={getPath.pathname === "/confirmed" ? "active" : ""}
                >
                  <i className="ri-shield-check-line"></i> Confirmed Stone
                </Link>
              </li>
              <li>
                <Link
                  to="/holdedStone"
                  className={
                    getPath.pathname === "/holdedStone" ? "active" : ""
                  }
                >
                  <i className="ri-hand-coin-line"></i> Holded Stone
                </Link>
              </li>
              <li>
                <Link
                  to="/book-appointment"
                  className={
                    getPath.pathname === "/book-appointment" ? "active" : ""
                  }
                >
                  <i className="ri-calendar-check-line"></i> Appointment
                </Link>
              </li>
              <li>
                <Link
                  href="/"
                  onClick={() => props.setIsLogout(true)}
                //   className={getPath.pathname === "/login" ? "active" : ""}
                >
                  <i className="ri-logout-box-line"></i> Logout
                </Link>
              </li>
            </ul>
          </aside>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  logoutReducer: state.authReducer.logout,
  profileReducer: state.authReducer.profile,
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  checkDiamondsTypeAction: (details) =>
    dispatch(checkDiamondsTypeAction(details)),
  resetLogout: () => dispatch({ type: LOGOUT_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
