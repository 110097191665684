import React, { useEffect } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MERCHANT_EMAIL_ACTIVAT_RESET } from "../userStore/common/constant";
import { MerchantEmailActivationFunc } from "../userStore/action/EmailActivationAction";

const MerchantEmailActivation = (props) => {
  const { id } = useParams();

  useEffect(() => {
    if (props.merchantEmailActivateReducer.success) {
      toast.success("Email Activate Successfully...");
      // navigate("/");
      props.resetemailactivate();
    }
    if (props.merchantEmailActivateReducer.error) {
      toast.error(props.merchantEmailActivateReducer.errors);
      props.resetemailactivate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.merchantEmailActivateReducer]);

  const onSubmitInput = () => {
    props.MerchantEmailActivationFunc(id);
  };

  return (
    <>
      <Header />
      <Container fluid className="banner">
        <Container>
          <h2 className="pageName" data-aos="zoom-out" data-aos-duration="1000">
            Merchant Activation
          </h2>
        </Container>
      </Container>
      <section className="container d-flex justify-content-center align-items-center">
        <Button onClick={onSubmitInput}>
          <span className=" text-light" style={{ fontWeight: "bold" }}>
            Email Activation
          </span>
        </Button>
      </section>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  merchantEmailActivateReducer:
    state.emailActivateReducer.merchantEmailActivate,
});
const mapDispatchToProps = (dispatch) => ({
  MerchantEmailActivationFunc: (details) =>
    dispatch(MerchantEmailActivationFunc(details)),
  resetemailactivate: () => dispatch({ type: MERCHANT_EMAIL_ACTIVAT_RESET }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchantEmailActivation);
