import axios from "axios";
import { cartList_Url, createCart_Url, deleteCart_Url } from "../common/apiSheet";
import { ADD_CART_LIST_ERROR, ADD_CART_LIST_SUCCESS, DELETE_CART_LIST_ERROR, DELETE_CART_LIST_SUCCESS, GET_CART_LIST_ERROR, GET_CART_LIST_SUCCESS } from "../common/constant";

export const getCartList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: cartList_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    }
    axios(requestOptions)
      .then((resp) => {
        // localStorage.setItem('token', resp?.data.data.token)
        dispatch({
          type: GET_CART_LIST_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_CART_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_CART_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const addCartList = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: createCart_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data: details
    }
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_CART_LIST_SUCCESS,
          msg: resp.data.data.error_message,
          count: resp.data.data.count
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_CART_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_CART_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const deleteCartList = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: deleteCart_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data: details
    }
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_CART_LIST_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_CART_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_CART_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};