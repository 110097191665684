import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addCartList } from "../userStore/action/cartAction";
import { addHoldStoneList } from "../userStore/action/holdStoneAction";
import { addInquiryList } from "../userStore/action/inquiryAction";
import { getSlotDetails } from "../userStore/action/masterAction";
import { addViewRequest } from "../userStore/action/viewRequestAction";
import { addConfirmStoneList } from "../userStore/action/confirmStoneAction";
import {
  ADD_CART_LIST_RESET,
  ADD_CONFIRM_STONE_LIST_RESET,
  ADD_HOLD_STONE_LIST_RESET,
  ADD_INQUIRY_LIST_RESET,
  ADD_VIEW_REQUEST_RESET,
  EXPORT_EXCEL_RESET,
  EXPORT_PDF_RESET,
  NEW_ARRIVAL_LIST_RESET,
  SEARCH_DIAMONDS_RESET,
} from "../userStore/common/constant";
import CompareStone from "./modals/CompareStone";
import ConfirmStoneModal from "./modals/ConfirmStoneModal";
import SearchTable from "./searchTable";
import Footer from "./userLayout/Footer";
import Header from "./userLayout/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { EmailExcelAction } from "../userStore/action/EmailExcelAction";
import { exportExcel, exportPdf } from "../userStore/action/exportFileAction";
import { abbreviateNumber } from "../userStore/common/helper";
import { Tooltip } from "react-tooltip";
import Loader from "../Components/Loader";

const SearchResult = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const diamondType = useSelector(
    (state) => state.authReducer.profile.data?.diamond_type
  );
  const [exportPdfLoading, setExportPdfLoading] = useState(false);
  const [exportExcelLoading, setExportExcelLoading] = useState(false);
  const [showComparison, setShowComparison] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showInquiry, setShowInquiry] = useState(false);
  const [ShowConfirmStone, setShowConfirmStone] = useState(false);
  const [showSelected, setShowSelected] = useState(false);
  const [details, setDetails] = useState([]);
  const [isHold, setIsHold] = useState(false);
  const [selected, setSelected] = useState({});
  const [comment, setComment] = useState("");
  const [remark, setremark] = useState("");
  const [error, setError] = useState({
    comment: false,
    time: false,
    remark: false,
  });
  const [value, setValue] = useState({
    Total_Cts: 0,
    $_Carat: 0,
    Average_discount: 0,
    Total_Amount: 0,
  });
  const [loadedData, setLoadedData] = useState([]);
console.log("details", details);
  const [requestDetails, setRequestDetails] = useState({
    day: "",
    time: "",
  });

  const slotDetails = useSelector(
    (state) => state.masterReducer.slotDetails.data
  );
  const dayList = [
    {
      id: 0,
      value: "Sunday",
    },
    {
      id: 1,
      value: "Monday",
    },
    {
      id: 2,
      value: "Tuesday",
    },
    {
      id: 3,
      value: "Wednesday",
    },
    {
      id: 4,
      value: "Thursday",
    },
    {
      id: 5,
      value: "Friday",
    },
    {
      id: 6,
      value: "Saturday",
    },
  ];

  const [showRequest, setShowRequest] = useState(false);

  useEffect(() => {
    if (!!showRequest && !props.slotDetailsReducer.success) {
      props.getSlotDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRequest]);

  useEffect(() => {
    if (props.getNewArrivalListReducer.success) {
      const data = props.getNewArrivalListReducer.data;
      if (!!data) {
        const _list = [
          ...new Map(
            [...loadedData, ...data].map((e) => [e["id"], e])
          ).values(),
        ];
        setLoadedData(_list);
      }
    }
  }, [props.getNewArrivalListReducer.data]);

  useEffect(() => {
    if (props.searchDiamondsReducer.success) {
      const data = props.searchDiamondsReducer.data;
      if (!!data) {
        const _list = [
          ...new Map(
            [...loadedData, ...data].map((e) => [e["id"], e])
          ).values(),
        ];
        setLoadedData(_list);
      }
    }
  }, [props.searchDiamondsReducer.data]);

  useEffect(() => {
    if (
      Object.keys(selected).length !== 0 &&
      !!props.searchDiamondsReducer.data
    ) {
      // const data = props.searchDiamondsReducer.data;
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false;
      });
      const _selected = idSelected.map((e) => {
        const list = loadedData.find((data, index) => index == e);
        // const list = data.find((data) => data.id === e);
        return list;
      });

      setDetails([..._selected].filter((e) => !!e));
      // setDetails([..._selected]);
    }
  }, [selected, props.searchDiamondsReducer.data, loadedData]);
  useEffect(() => {
    if (
      Object.keys(selected).length !== 0 &&
      !!props.getNewArrivalListReducer.data
    ) {
      // const data = props.searchDiamondsReducer.data;
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false;
      });
      const _selected = idSelected.map((e) => {
        const list = loadedData.find((data, index) => index == e);
        // const list = data.find((data) => data.id === e);
        return list;
      });

      setDetails([..._selected].filter((e) => !!e));

      // setDetails([..._selected]);
    }
  }, [selected, props.getNewArrivalListReducer.data, loadedData]);

  useEffect(() => {
    if (details.length !== 0) {
      if (Object.values(selected).every((e) => e === true)) {
        const data =
          location?.state?.page === "new_arrival"
            ? props.getNewArrivalListReducer.details
            : props.searchDiamondsReducer.details;
        setValue({
          Total_Cts: data.Totalcarat.toFixed(2),
          $_Carat: parseFloat(data.Totalamount) / parseFloat(data.Totalcarat),
          Average_discount: data.Totaldiscount.toFixed(2),
          Total_Amount: data.Totalamount.toFixed(2),
        });
      } else if (Object.values(selected).every((e) => e === false)) {
        setValue({
          Total_Cts: 0,
          $_Carat: 0,
          Average_discount: 0,
          Total_Amount: 0,
        });
      } else {
        setValue((pre) => ({
          ...pre,
          Total_Cts: details
            ?.map((e) => e?.carat)
            ?.reduce((a, b) => a + b)
            ?.toFixed(2),
          $_Carat:
            details
              ?.map((e) => e?.amount)
              ?.reduce((a, b) => parseFloat(a) + parseFloat(b)) /
            details
              ?.map((e) => e?.carat)
              ?.reduce((a, b) => parseFloat(a) + parseFloat(b))
              ?.toFixed(2),
          Average_discount: (
            details
              .map((e) => e?.discount)
              ?.reduce((a, b) => parseFloat(a) + parseFloat(b)) / details.length
          )?.toFixed(2),
          Total_Amount: details
            .map((e) => e?.amount)
            .reduce((a, b) => parseFloat(a) + parseFloat(b))
            ?.toFixed(2),
        }));
      }
    }
  }, [details]);

  useEffect(() => {
    if (props.searchDiamondsReducer.success) {
      props.resetsearchdiamond();
    }
  }, [props.searchDiamondsReducer]);
  useEffect(() => {
    if (props.getNewArrivalListReducer.success) {
      props.resetnewArrival();
    }
  }, [props.getNewArrivalListReducer]);

  const handleAddToCart = () => {
    const idSelected = Object.keys(selected).filter((e) => {
      if (selected[e] === true) {
        return e;
      } else return false;
    });
    if (idSelected.length !== 0) {
      const _selected = idSelected.map((e) => {
        const list = loadedData.find((data, index) => index == e);
        // const list = data.find((data) => data.id === e);
        return list;
      });
      props.addCartList({
        product_id: JSON.stringify([..._selected].map((e) => e.id)),
      });
    } else {
      toast.error("select stone for add to cart..");
    }
  };

  const handleHoldCreate = () => {
    const idSelected = Object.keys(selected).filter((e) => {
      if (selected[e] === true) {
        return e;
      } else return false;
    });
    if (idSelected.length !== 0) {
      setIsHold(true);
    } else {
      toast.error("select stone for add to hold list..");
    }
  };

  const handleAddHold = () => {
    const idSelected = Object.keys(selected).filter((e) => {
      if (selected[e] === true) {
        return e;
      } else return false;
    });
    const _selected = idSelected.map((e) => {
      const list = loadedData.find((data, index) => index == e);
      return list;
    });
    props.addHoldStoneList({
      product_id: JSON.stringify([..._selected].map((e) => e.id)),
    });
  };

  const handleSendInquiry = () => {
    if (comment === "") {
      setError((prev) => ({
        ...prev,
        comment: "Required",
      }));
    }

    if (comment !== "") {
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false;
      });
      const _selected = idSelected.map((e) => {
        const list = loadedData.find((data, index) => index == e);
        // const list = data.find((data) => data.id === e);
        return list;
      });
      props.addInquiryList({
        product_id: JSON.stringify([..._selected].map((e) => e.id)),
        comment: comment,
      });
    }
  };

  const handleSendConfirmStone = () => {
    if (remark === "") {
      setError((prev) => ({
        ...prev,
        remark: "Required",
      }));
    }

    if (remark !== "") {
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false;
      });
      const _selected = idSelected.map((e) => {
        const list = loadedData.find((data, index) => index == e);
        // const list = data.find((data) => data.id === e);
        return list;
      });
      props.addConfirmStoneList({
        product_id: JSON.stringify([..._selected].map((e) => e.id)),
        remark: remark,
      });
    }
  };

  const handleViewRequest = () => {
    if (requestDetails.time === "") {
      setError((pre) => ({
        ...pre,
        time: "Time Required",
      }));
    }

    if (requestDetails.time !== "") {
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false;
      });
      const _selected = idSelected.map((e) => {
        const list = loadedData?.find((data, index) => index == e);
        // const list = data.find((data) => data.id === e);
        return list;
      });
      props.addViewRequest({
        product_id: JSON.stringify([..._selected].map((e) => e.id)),
        slot_id: requestDetails.time,
      });
    }
  };

  useEffect(() => {
    if (props.createCartReducer.success) {
      const msg = props.createCartReducer.msg;
      const count = props.createCartReducer.count;
      const list = msg
        .split(", ")
        .map((e, i) => {
          if (e !== "" && i !== msg.split(", ") - 1) return `${e}<br />`;
          else if (e === "") {
            return false;
          } else return e;
        })
        .filter((e) => {
          return e !== undefined;
        });
      if (parseInt(count) !== 0) {
        toast.success("Selected Stone added to Cart..");
      }
      !!msg &&
        toast.error(
          <div dangerouslySetInnerHTML={{ __html: list.join(",") }} />
        );
      setComment("");
      props.resetAddToCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createCartReducer]);

  useEffect(() => {
    if (props.createInquiryReducer.success) {
      toast.success("Inquiry Created..");
      setComment("");
      setShowInquiry(false);
      props.resetAddInquiry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createInquiryReducer]);

  useEffect(() => {
    if (props.createConfirmStoneReducer.success) {
      toast.success("Selected Stone Added to Confirm Diamond table..");
      setremark("");
      setShowConfirmStone(false);
      props.resetAddConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createConfirmStoneReducer]);

  useEffect(() => {
    if (props.createViewRequestReducer.success) {
      toast.success("Selected Stone Added to view request table..");
      setRequestDetails({
        day: "",
        time: "",
      });
      setShowRequest(false);
      props.resetAddRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createViewRequestReducer]);

  useEffect(() => {
    if (props.createHoldStoneReducer.success) {
      const msg = props.createHoldStoneReducer.msg;
      const count = props.createHoldStoneReducer.count;
      const list = msg
        .split(", ")
        .map((e, i) => {
          if (e !== "" && i !== msg.split(", ") - 1) return `${e}<br />`;
          else if (e === "") {
            return false;
          } else return e;
        })
        .filter((e) => {
          return e !== undefined;
        });
      if (parseInt(count) !== 0) {
        toast.success("Selected Stone Added to hold Diamond table..");
      }
      !!msg &&
        toast.error(
          <div dangerouslySetInnerHTML={{ __html: list.join(",") }} />
        );
      props.resetAddHold();
      setIsHold(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createHoldStoneReducer]);

  const SendEmail = () => {
    const data =
      location?.state?.page === "new_arrival"
        ? props.getNewArrivalListReducer.details
        : props.searchDiamondsReducer.details;
    const allSelected = Object.values(selected).every((e) => e === true);
    if (allSelected) {
      props.EmailExcelAction(data?.ProductIds, 0);
    } else {
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false;
      });
      const stonIds = idSelected.map((e) => {
        // const list = data.find((data) => parseInt(data.id) === parseInt(e))?.stone_id;
        const list = loadedData.find((data, index) => {
          return index == e;
        })?.stone_id;
        return list;
      });
      if (loadedData.length === stonIds.length) {
        props.EmailExcelAction(stonIds, 0);
      } else if (stonIds.length !== 0 && stonIds.length < loadedData.length) {
        props.EmailExcelAction(stonIds, 1);
      } else {
        toast.error("Please Select list..");
      }
    }
  };

  const handleExportPdf = () => {
    const data =
      location?.state?.page === "new_arrival"
        ? props.getNewArrivalListReducer.details
        : props.searchDiamondsReducer.details;
    const allSelected = Object.values(selected).every((e) => e === true);
    if (allSelected) {
      props.exportPdf({
        details: data?.ProductIds,
        dt: diamondType,
      });
      setExportPdfLoading(true);
    } else {
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false;
      });
      const stonIds = idSelected.map((e) => {
        const list = loadedData.find((data, index) => {
          return index == e;
        })?.stone_id;
        return list;
      });
      if (stonIds.length !== 0) {
        props.exportPdf({
          details: stonIds,
          dt: diamondType,
        });
        setExportPdfLoading(true);
      } else {
        toast.error("Please Select stone for PDF export..");
      }
    }
  };

  const handleExportExcel = () => {
    const data =
      location?.state?.page === "new_arrival"
        ? props.getNewArrivalListReducer.details
        : props.searchDiamondsReducer.details;
    const allSelected = Object.values(selected).every((e) => e === true);
    if (allSelected) {
      props.exportExcel({
        details: data?.ProductIds,
        dt: diamondType,
      });
      setExportExcelLoading(true);
    } else {
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false;
      });
      const stonIds = idSelected.map((e) => {
        const list = loadedData.find((data, index) => index == e).stone_id;
        return list;
      });
      if (stonIds.length !== 0) {
        props.exportExcel({
          details: stonIds,
          dt: diamondType,
        });
        setExportExcelLoading(true);
      } else {
        toast.error("Please Select stone for Excel export..");
      }
    }
  };

  useEffect(() => {
    if (props.exportExcelReducer.success) {
      setExportExcelLoading(false);
      props.resetExportExcel();
    }
  }, [props.exportExcelReducer]);
  useEffect(() => {
    if (props.exportPdfREducer.success) {
      setExportPdfLoading(false);
      props.resetExportPdf();
    }
  }, [props.exportPdfREducer]);
  return (
    <>
      <Header />
      <main className="userMain">
        <div className="d-flex justify-content-between  mt-5 mb-4 flex-wrap">
          <h2 className="heading mb-0">
            {location?.state?.page === "new_arrival"
              ? "New Arrival Items"
              : "Search Result"}
          </h2>

          <div className="text-end mt-3 mt-sm-0 ms-auto navigate-buttons position-relative">
            {/* Modify search */}
            <Button
              variant="secondary"
              className="me-sm-2 me-1 tooltip-labels"
              data-tooltip-content="Modify search"
              onClick={() => {
                if (!!location.state) {
                  navigate("/labGrown/search", {
                    state: {
                      ...location.state,
                      // state: location.state,
                    },
                  });
                } else {
                  navigate("/labGrown/search");
                }
              }}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
            </Button>

            {/* Add to cart */}
            <Button
              variant="secondary"
              className="me-sm-2 me-1 tooltip-labels"
              onClick={handleAddToCart}
              data-tooltip-content="Add to Cart"
            >
              <i className="fa-solid fa-cart-shopping"></i>
            </Button>

            {/* Send Inquiry */}
            <Button
              variant="secondary"
              className="me-sm-2 me-1 tooltip-labels"
              data-tooltip-content="Send Inquiry"
              onClick={() => {
                const idSelected = Object.keys(selected).filter((e) => {
                  if (selected[e] === true) {
                    return e;
                  } else return false;
                });
                if (idSelected.length !== 0) {
                  setShowInquiry(true);
                } else {
                  toast.error("select stone for add Inquiry list..");
                }
              }}
            >
              <i className="fa-solid fa-clipboard-question"></i>
            </Button>

            {/* Add to watch list */}
            <Button
              variant="secondary"
              className="me-sm-2 me-1 tooltip-labels"
              data-tooltip-content="View Request"
              onClick={() => {
                const idSelected = Object.keys(selected).filter((e) => {
                  if (selected[e] === true) {
                    return e;
                  } else return false;
                });
                if (idSelected.length !== 0) {
                  setShowRequest(true);
                } else {
                  toast.error("select stone for add view request list..");
                }
              }}
            >
              <i className="fa-solid fa-clock"></i>
            </Button>
            {/* Show selected */}

            <Button
              variant="secondary"
              className="me-sm-2 me-1 tooltip-labels"
              data-tooltip-content="Show selected"
              onClick={() => setShowSelected(!showSelected)}
            >
              <i className="fa-solid fa-clipboard-list"></i>
            </Button>

            {/* Stone Comparison */}
            <Button
              variant="secondary"
              data-tooltip-content="Stone Comparison"
              onClick={() => {
                const idSelected = Object.keys(selected).filter((e) => {
                  if (selected[e] === true) {
                    return e;
                  } else return false;
                });
                if (idSelected.length !== 0) {
                  setShowComparison(true);
                } else {
                  toast.error("select stone for compair stone details..");
                }
                if (idSelected.length === 2) {
                  setShowComparison(true);
                } else {
                  setShowComparison(false);
                  toast.error("Select Maximum 2 Stone compair");
                }
              }}
              className="me-sm-2 me-1 tooltip-labels"
            >
              <i className="fa-solid fa-scale-balanced"></i>
            </Button>

            {/* Print PDF */}
            {/* <Button
                variant="secondary"
                // onClick={handleConfirmCreate}
                className="me-2"
              >
                <i className="fa-solid fa-circle-check"></i>
              </Button> */}
            <Button
              variant="secondary"
              className="me-sm-2 me-1 tooltip-labels"
              data-tooltip-content="Confirm Stone"
              onClick={() => {
                const idSelected = Object.keys(selected).filter((e) => {
                  if (selected[e] === true) {
                    return e;
                  } else return false;
                });
                if (idSelected.length !== 0) {
                  setShowConfirmStone(true);
                } else {
                  toast.error("select stone for add Confirm Stone..");
                }
              }}
            >
              <i className="fa-solid fa-clipboard-question"></i>
            </Button>

            {/* Download Excel */}
            <Button
              variant="secondary"
              className="me-2 tooltip-labels"
              data-tooltip-content="Hold Stone"
              onClick={handleHoldCreate}
            >
              <i className="fa-regular fa-hourglass-half"></i>
            </Button>

            {/* Email Excel */}
            <Button
              variant="secondary"
              className="me-sm-2 me-1 tooltip-labels"
              onClick={SendEmail}
              data-tooltip-content="Email Excel"
            >
              <i className="fa-solid fa-envelope-open-text"></i>
            </Button>

            <Button
              variant="secondary"
              className="me-sm-2 me-1 tooltip-labels"
              onClick={exportPdfLoading ? () => {} : handleExportPdf}
              data-tooltip-content="Export PDF"
            >
              {exportPdfLoading ? (
                <Spinner style={{ width: "1rem", height: "1rem" }} />
              ) : (
                <i className="fa-solid fa-file-pdf"></i>
              )}{" "}
            </Button>

            <Button
              variant="secondary"
              className="me-sm-2 me-1 tooltip-labels"
              onClick={exportExcelLoading ? () => {} : handleExportExcel}
              data-tooltip-content="Export Excel"
            >
              {exportExcelLoading ? (
                <Spinner style={{ width: "1rem", height: "1rem" }} />
              ) : (
                <i className="fa-solid fa-file-excel"></i>
              )}{" "}
            </Button>

            <Tooltip anchorSelect=".tooltip-labels" />
          </div>
        </div>

        <div className="content-block">
          {/* Info blocks */}
          <div className="row g-1 g-lg-3 mb-4 mt-lg-0 mt-3">
            <div className="col-lg-3 col-md-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> Total Cts</h6>
                <h2 className="mb-0 table-value">
                  {details.length === 0 ? 0 : value.Total_Cts}
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> $/Carat</h6>
                <h2 className="mb-0">
                  {details.length === 0
                    ? `$ 0`
                    : `$
                      ${abbreviateNumber(value.$_Carat.toFixed(2))}`}
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> Average Discount</h6>
                <h2 className="mb-0">
                  {details.length === 0
                    ? `0 %`
                    : `
                      ${value.Average_discount} %`}
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ">
              <div className="info-block ">
                <h6 className="mb-lg-3 text-primary"> Total Amount</h6>
                <h2 className="mb-0">
                  {details.length === 0
                    ? `$ 0`
                    : `$
                      ${abbreviateNumber(value.Total_Amount)} `}
                </h2>
              </div>
            </div>
          </div>
          <SearchTable
            setSelected={setSelected}
            showSelected={showSelected}
            page={location?.state?.page}
          />
        </div>
      </main>
      <Footer />
      {showComparison && (
        <CompareStone
          show={showComparison}
          onHide={() => {
            setShowComparison(false);
          }}
          data={details}
        />
      )}
      {showConfirm && (
        <ConfirmStoneModal
          show={showConfirm}
          onHide={() => setShowConfirm(false)}
        />
      )}
      {showInquiry && (
        <>
          <Modal
            show={showInquiry}
            onHide={() => {
              setShowInquiry(false);
              setComment("");
              error["comment"] = false;
            }}
            backdrop={"static"}
          >
            <Modal.Header closeButton>
              <Modal.Title>Inquiry List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={12} className="mb-3">
                  <label>Comment</label>
                  <textarea
                    name="name"
                    placeholder="Enter comment"
                    className="form-control"
                    value={comment}
                    rows={3}
                    maxLength={"256"}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  ></textarea>

                  <div className="d-flex justify-content-between align-items-center">
                    {error.comment ? (
                      <span className="form-error">{error.comment}</span>
                    ) : (
                      <span></span>
                    )}
                    <span
                      className="text-end text-muted mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      {256 - comment.length} characters left
                    </span>
                  </div>
                </Col>
              </Row>
              <Button
                className="btn btn-primary text-white ms-auto d-block"
                onClick={handleSendInquiry}
                type="submit"
              >
                Submit
              </Button>
            </Modal.Body>
          </Modal>
        </>
      )}

      {showConfirm && (
        <ConfirmStoneModal
          show={showConfirm}
          onHide={() => setShowConfirm(false)}
        />
      )}
      {ShowConfirmStone && (
        <>
          <Modal
            show={ShowConfirmStone}
            onHide={() => {
              setShowConfirmStone(false);
              setremark("");
              error["remark"] = false;
            }}
            backdrop={"static"}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Stone</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={12} className="mb-3">
                  <label>Remark</label>
                  <textarea
                    name="name"
                    placeholder="Enter Remark"
                    className="form-control"
                    value={remark}
                    rows={3}
                    maxLength={"256"}
                    onChange={(e) => {
                      setremark(e.target.value);
                    }}
                  ></textarea>

                  <div className="d-flex justify-content-between align-items-center">
                    {error.remark ? (
                      <span className="form-error">{error.remark}</span>
                    ) : (
                      <span></span>
                    )}
                    <span
                      className="text-end text-muted mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      {256 - remark.length} characters left
                    </span>
                  </div>
                </Col>
              </Row>
              <Button
                className="btn btn-primary text-white ms-auto d-block"
                onClick={handleSendConfirmStone}
                type="submit"
              >
                Submit
              </Button>
            </Modal.Body>
          </Modal>
        </>
      )}

      {showRequest && props.slotDetailsReducer.success && (
        <>
          <Modal
            show={showRequest}
            onHide={() => {
              setShowRequest(false);
            }}
            backdrop={"static"}
          >
            <Modal.Header closeButton>
              <Modal.Title>Diamond Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={12} className="mb-3">
                  <label>Day</label>
                  <select
                    id="day"
                    className="form-control mb-3"
                    value={requestDetails.day}
                    onChange={(e) => {
                      setRequestDetails((pre) => ({
                        ...pre,
                        day: e.target.value,
                      }));
                    }}
                    name="location"
                  >
                    <option value={""}>Select Day</option>
                    {dayList?.map((data, i) => {
                      return (
                        <option key={i} value={data.id}>
                          {data.value}
                        </option>
                      );
                    })}
                  </select>
                  {error.comment && (
                    <span className="form-error">{error.comment}</span>
                  )}
                </Col>
                {requestDetails.day !== "" &&
                slotDetails?.filter(
                  (e) => parseInt(e.day) === parseInt(requestDetails.day)
                ).length !== 0 ? (
                  <>
                    <Col md={12} className="mb-3">
                      <label>Time</label>
                      <select
                        id="day"
                        className="form-control mb-3"
                        value={requestDetails.time}
                        onChange={(e) => {
                          setRequestDetails((pre) => ({
                            ...pre,
                            time: e.target.value,
                          }));
                        }}
                        name="time"
                      >
                        <option value={""}>Select Time Slot</option>
                        {!!slotDetails &&
                          slotDetails
                            ?.filter(
                              (e) =>
                                parseInt(e.day) === parseInt(requestDetails.day)
                            )
                            ?.map((data, i) => {
                              return (
                                <option key={i} value={data.id}>
                                  {data.from_time} - {data.to_time}
                                </option>
                              );
                            })}
                      </select>
                      {error.time && (
                        <span className="form-error">{error.time}</span>
                      )}
                    </Col>
                  </>
                ) : (
                  <span className="text-center"> No Time Slot available</span>
                )}
              </Row>
              <Button
                className="btn btn-primary text-white ms-auto d-block"
                onClick={handleViewRequest}
                type="submit"
              >
                Submit
              </Button>
            </Modal.Body>
          </Modal>
        </>
      )}

      <Modal
        show={isHold}
        size="md"
        backdrop="static"
        onHide={() => setIsHold(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="p-4">
          <div className="">
            <div className="text-center my-5">
              <h5>Are you sure you want to hold stone ?</h5>
            </div>
            <Modal.Footer className="mt-3">
              <Button
                className="py-2 px-4 border rounded pointer bg-secondary text-white mx-2 pointer"
                onClick={() => {
                  setIsHold(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="py-2 px-4 border rounded pointer bg-primary text-white mx-2 pointer"
                onClick={handleAddHold}
              >
                Hold
              </Button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  searchDiamondsReducer: state.labGrownReducer.searchDiamonds,
  createCartReducer: state.cartReducer.createCart,
  createInquiryReducer: state.inquiryReducer.createInquiry,
  getNewArrivalListReducer: state.masterReducer.getNewArrivalList,
  slotDetailsReducer: state.masterReducer.slotDetails,
  createViewRequestReducer: state.viewRequestReducer.createViewRequest,
  createHoldStoneReducer: state.holdStoneReducer.createHoldStone,
  createConfirmStoneReducer: state.confirmStoneReducer.createConfirmStone,
  exportExcelReducer: state.exportFileReducer.exportExcel,
  exportPdfREducer: state.exportFileReducer.exportPdf,
});
const mapDispatchToProps = (dispatch) => ({
  addCartList: (details) => dispatch(addCartList(details)),
  EmailExcelAction: (Email) => dispatch(EmailExcelAction(Email)),
  addInquiryList: (details) => dispatch(addInquiryList(details)),
  getSlotDetails: (details) => dispatch(getSlotDetails(details)),
  addViewRequest: (details) => dispatch(addViewRequest(details)),
  addHoldStoneList: (details) => dispatch(addHoldStoneList(details)),
  addConfirmStoneList: (details) => dispatch(addConfirmStoneList(details)),
  exportPdf: (details) => dispatch(exportPdf(details)),
  exportExcel: (details) => dispatch(exportExcel(details)),
  resetsearchdiamond: () => dispatch({ type: SEARCH_DIAMONDS_RESET }),
  resetnewArrival: () => dispatch({ type: NEW_ARRIVAL_LIST_RESET }),
  resetAddToCart: () => dispatch({ type: ADD_CART_LIST_RESET }),
  resetAddInquiry: () => dispatch({ type: ADD_INQUIRY_LIST_RESET }),
  resetAddRequest: () => dispatch({ type: ADD_VIEW_REQUEST_RESET }),
  resetAddHold: () => dispatch({ type: ADD_HOLD_STONE_LIST_RESET }),
  resetAddConfirm: () => dispatch({ type: ADD_CONFIRM_STONE_LIST_RESET }),
  resetExportPdf: () => dispatch({ type: EXPORT_PDF_RESET }),
  resetExportExcel: () => dispatch({ type: EXPORT_EXCEL_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
