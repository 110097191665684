import { ADD_SAVE_SEARCH_ERROR, ADD_SAVE_SEARCH_RESET, ADD_SAVE_SEARCH_SUCCESS, DELETE_SAVE_SEARCH_ERROR, DELETE_SAVE_SEARCH_RESET, DELETE_SAVE_SEARCH_SUCCESS, SAVE_SEARCH_LIST_ERROR, SAVE_SEARCH_LIST_RESET, SAVE_SEARCH_LIST_SUCCESS } from "../common/constant";

let initialState = {
 getSaveSearchList: {
  error: false,
  data: null,
  errors: null,
  success: false,
 },
 addSaveSearchList: {
  error: false,
  errors: null,
  success: false,
 },
 deleteSaveSearch: {
  error: false,
  errors: null,
  success: false,
 }
};

export default function saveSearchListReducer(state = initialState, action) {
 switch (action.type) {
  case SAVE_SEARCH_LIST_SUCCESS:
   return { ...state, getSaveSearchList: { ...state.getSaveSearchList, success: true, error: false, errors: null, data: action.response }, };

  case SAVE_SEARCH_LIST_ERROR:
   return { ...state, getSaveSearchList: { ...state.getSaveSearchList, success: false, error: true, errors: action.msg } };

  case SAVE_SEARCH_LIST_RESET:
   return { ...state, getSaveSearchList: { ...state.getSaveSearchList, success: false, error: false, errors: null } };

  //  add

  case ADD_SAVE_SEARCH_SUCCESS:
   return { ...state, addSaveSearchList: { ...state.addSaveSearchList, success: true, error: false, errors: null }, };

  case ADD_SAVE_SEARCH_ERROR:
   return { ...state, addSaveSearchList: { ...state.addSaveSearchList, success: false, error: true, errors: action.msg } };

  case ADD_SAVE_SEARCH_RESET:
   return { ...state, addSaveSearchList: { ...state.addSaveSearchList, success: false, error: false, errors: null } };



  //  delete

  case DELETE_SAVE_SEARCH_SUCCESS:
   return { ...state, deleteSaveSearch: { ...state.deleteSaveSearch, success: true, error: false, errors: null }, };

  case DELETE_SAVE_SEARCH_ERROR:
   return { ...state, deleteSaveSearch: { ...state.deleteSaveSearch, success: false, error: true, errors: action.msg } };

  case DELETE_SAVE_SEARCH_RESET:
   return { ...state, deleteSaveSearch: { ...state.deleteSaveSearch, success: false, error: false, errors: null } };

  default:
   return state;
 }
}