import React from "react";
import { getSelectedState, Grid, GridColumn } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-data-query";
import { connect } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import {
  deleteDemandList,
  getDemandList,
} from "../../userStore/action/demandAction";
import {
  ADD_DEMAND_LIST_RESET,
  DELETE_DEMAND_LIST_RESET,
} from "../../userStore/common/constant";
import moment from "moment/moment";
import { getMastersList } from "../../userStore/action/masterAction";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const DemandTable = (props) => {
  const [masters, setMasters] = useState({});
  const [details, setDetails] = useState([]);
  const [IsDeleteCart, setIsDeleteCart] = useState(false);

  const createState = (skip, take) => {
    let pagerSettings = {
      buttonCount: 5,
      info: true,
      type: "numeric",
      pageSizes: true,
      previousNext: true,
    };
    return {
      items: details.slice(skip, skip + take),
      total: details.length,
      skip: skip,
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = React.useState(createState(0, 10));

  const [dataState, setDataState] = React.useState(
    state.items.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    )
  );

  useEffect(() => {
    const list = state.items.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    );
    setDataState([...list]);
  }, [state.items]);

  const [selectedState, setSelectedState] = React.useState({});

  useEffect(() => {
    if (!props.masterListReducer.success) {
      props.getMastersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.setSelected(selectedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  useEffect(() => {
    if (props.masterListReducer.success) {
      const data = props.masterListReducer.data;
      setMasters({ ...data });
    }
  }, [props.masterListReducer]);

  useEffect(() => {
    if (masters !== {}) {
      const _details = details.map((e, i) => {
        return {
          // size:
          //   !e.demand?.size_id || e.demand?.size_id?.length === 0
          //     ? "N/A"
          //     : JSON.parse(e.demand?.size_id)?.map((e) => {
          //         const list = masters.Size.find(
          //           (item) => item.id === parseInt(e)
          //         );
          //         return list.name;
          //       }),
          carat_from:
            !e.demand?.carat_from || e.demand?.carat_from?.length === 0
              ? "N/A"
              : e.demand?.carat_from,
          carat_To:
            !e.demand?.carat_to || e.demand?.carat_to?.length === 0
              ? "N/A"
              : e.demand?.carat_to,
          diamond_type_name:
            !e.demand?.diamond_type || e.demand?.diamond_type?.length === 0
              ? "N/A"
              : e.demand?.diamond_type?.map((e) => {
                  return masters?.DiamondType[e];
                }),
          shape:
            !e.demand?.shape_id || e.demand?.shape_id?.length === 0
              ? "N/A"
              : JSON.parse(e.demand?.shape_id)?.map((e) => {
                  const list = masters.Shape.find(
                    (item) => item.id === parseInt(e)
                  );
                  return list.name;
                }),
          color:
            !e.demand?.color_id || e.demand?.color_id?.length === 0
              ? "N/A"
              : JSON.parse(e.demand?.color_id)?.map((e) => {
                  const list = masters.Color.find(
                    (item) => item.id === parseInt(e)
                  );
                  return list.name;
                }),
          fancy_color:
            !e.demand?.colors_id || e.demand?.colors_id?.length === 0
              ? "N/A"
              : JSON.parse(e.demand?.colors_id)?.map((e) => {
                  const list = masters.FancyColor.find(
                    (item) => item.id === parseInt(e)
                  );
                  return list.name;
                }),
          Overtone:
            !e.demand?.overtone_id || e.demand?.overtone_id?.length === 0
              ? "N/A"
              : JSON.parse(e.demand?.overtone_id)?.map((e) => {
                  const list = masters.FancyColor.find(
                    (item) => item.id === parseInt(e)
                  );
                  return list.name;
                }),
          intensity:
            !e.demand?.intensity_id || e.demand?.intensity_id?.length === 0
              ? "N/A"
              : JSON.parse(e.demand?.intensity_id)?.map((e) => {
                  const list = masters.FancyColor.find(
                    (item) => item.id === parseInt(e)
                  );
                  return list.name;
                }),
          clarity:
            !e.demand?.clarity_id || e.demand?.clarity_id?.length === 0
              ? "N/A"
              : JSON.parse(e.demand?.clarity_id)?.map((e) => {
                  const list = masters.Clarity.find(
                    (item) => item.id === parseInt(e)
                  );
                  return list.name;
                }),
          cut:
            !e.demand?.cut_id || e.demand?.cut_id?.length === 0
              ? "N/A"
              : JSON.parse(e.demand?.cut_id)?.map((e) => {
                  const list = masters.Finish.find(
                    (item) => item.id === parseInt(e)
                  );
                  return list.name;
                }),
          polish:
            !e.demand?.polish_id || e.demand?.polish_id?.length === 0
              ? "N/A"
              : JSON.parse(e?.demand?.polish_id)?.map((e) => {
                  const list = masters.Finish.find(
                    (item) => item.id === parseInt(e)
                  );
                  return list.name;
                }),
          symmetry:
            !e.demand?.symmetry_id || e.demand?.symmetry_id?.length === 0
              ? "N/A"
              : JSON.parse(e.demand?.symmetry_id)?.map((e) => {
                  const list = masters.Finish.find(
                    (item) => item.id === parseInt(e)
                  );
                  return list.name;
                }),
          fluorescence:
            !e.demand?.fluorescence_id ||
            e.demand?.fluorescence_id?.length === 0
              ? "N/A"
              : JSON.parse(e.demand?.fluorescence_id)?.map((e) => {
                  const list = masters.Fluorescence.find(
                    (item) => item.id === parseInt(e)
                  );
                  return list.name;
                }),

          disc_from:
            !e.demand?.discount_from || e.demand?.discount_from?.length === 0
              ? "N/A"
              : e.demand?.discount_from,
          disc_to:
            !e.demand?.discount_to || e.demand?.discount_to?.length === 0
              ? "N/A"
              : e.demand?.discount_to,

          Table_from:
            !e.demand?.table_from || e.demand?.table_from?.length === 0
              ? "N/A"
              : e.demand?.table_from,
          Table_to:
            !e.demand?.table_to || e.demand?.table_to?.length === 0
              ? "N/A"
              : e.demand?.table_to,

          depth_from:
            !e.demand?.depth_from || e.demand?.depth_from?.length === 0
              ? "N/A"
              : e.demand?.depth_from,
          depth_to:
            !e.demand?.depth_to || e.demand?.depth_to?.length === 0
              ? "N/A"
              : e.demand?.depth_to,

          ratio_from:
            !e.demand?.ratio_from || e.demand?.ratio_from?.length === 0
              ? "N/A"
              : e.demand?.ratio_from,
          ratio_to:
            !e.demand?.ratio_to || e.demand?.ratio_to?.length === 0
              ? "N/A"
              : e.demand?.ratio_to,

          length_from:
            !e.demand?.length_from || e.demand?.length_from?.length === 0
              ? "N/A"
              : e.demand?.length_from,
          length_to:
            !e.demand?.length_to || e.demand?.length_to?.length === 0
              ? "N/A"
              : e.demand?.length_to,

          width_from:
            !e.demand?.width_from || e.demand?.width_from?.length === 0
              ? "N/A"
              : e.demand?.width_from,
          width_to:
            !e.demand?.width_to || e.demand?.width_to?.length === 0
              ? "N/A"
              : e.demand?.width_to,

          stone_id: !!e.demand.stone_id ? e.demand.stone_id : "N/A",
          carat: e.demand.carat,
          // fluorescence: e.demand.fluorescence_name
          //   ? e.demand.fluorescence_name
          //   : "N/A",
          // rate: e.demand.rate ? e.demand.rate : "N/A",
          // Table: e.demand.table ? e.demand.table : "N/A",
          depth: e.demand.depth ? e.demand.depth : "N/A",
          // measurement: e.demand.measurement ? e.demand.measurement : "N/A",
          ratio: e.demand.ratio ? e.demand.ratio : "N/A",
          length: e.demand.length ? e.demand.length : "N/A",
          width: e.demand.width ? e.demand.width : "N/A",
          table: e.demand.table_per ? e.demand.table_per : "N/A",
          depth_pr: e.demand.depth_per ? e.demand.depth_per : "N/A",
          qty: e.quantity ?? "N/A",
          demand_name: e.name,
          demand_date: moment(e.created_at).format("DD/MM/yyyy HH:mm:ss A"),
          // SrNo: i + 1,
          // status: e.status,
          id: e.id,
        };
      });
      setState((prev) => ({
        ...prev,
        items: [..._details],
      }));
    }
  }, [details, masters, state.pageSize, state.skip]);
  useEffect(() => {
    // if (!props.demandListReducer.success) {
    // }
    props.getDemandList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.deleteDemandReducer.success) {
      props.getDemandList();
      toast.success("Selected Demand deleted successfully..");
      props.resetDelete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deleteDemandReducer]);

  useEffect(() => {
    if (props.addDemandReducer.success) {
      props.getDemandList();
      props.resetAddDemand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addDemandReducer]);

  useEffect(() => {
    if (props.demandListReducer.success) {
      const data = props.demandListReducer.data;

      const list = data.map((e) => {
        return {
          ...e,
          demand: JSON.parse(e.demand),
        };
      });
      setDetails([...list]);
    }
  }, [props.demandListReducer]);

  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const pageChange = (event) => {
    setState(createState(event.page.skip, event.page.take));
  };
  const CustomCell = (cellProps) => {
    return (
      <td
        className="k-grid-content-sticky"
        style={{ left: "50px", right: "0px" }}
      >
        <div
          className="btn btn-sm p-0 "
          onClick={() => {
            props.deleteDemandAction({
              demand_id: JSON.stringify([cellProps.dataItem.id]),
            });
          }}
        >
          <i className="fa-solid fa-trash"></i>
        </div>
      </td>
    );
  };

  const MyCustomCell = (props) => <CustomCell {...props} />;
  const grid = (
    <Grid
      style={{
        height: "70vh",
      }}
      data={dataState.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)],
      }))}
      skip={state.skip}
      take={state.take}
      total={state.total}
      pageable={state.pageable}
      pageSize={state.pageSize}
      onPageChange={pageChange}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      selectable={{
        enabled: true,
        drag: false,
        cell: false,
        mode: "multiple",
      }}
      onSelectionChange={onSelectionChange}
      onHeaderSelectionChange={onHeaderSelectionChange}
    >
      {/* <GridColumn field="view">sldkjklf</GridColumn> */}
      <GridColumn
        locked={true}
        field={SELECTED_FIELD}
        width="50px"
        headerSelectionValue={
          dataState.length === 0
            ? false
            : dataState.findIndex((item) => !selectedState[idGetter(item)]) ===
              -1
        }
      />
      <GridColumn
        field="Action"
        width="55"
        locked={true}
        cell={MyCustomCell}
        onClick={() => {
          setIsDeleteCart(true);
          CustomCell();
        }}
      />
      {/* <GridColumn field="SrNo" title="SrNo" width="75" /> */}
      <GridColumn field="demand_name" title="Demand Name" width="110" />
      <GridColumn field="qty" title=" Qty" width="40" />
      <GridColumn field="demand_date" title="Demand Date" width="110" />

      {/* <GridColumn field="cert" title=" Certificate" width="100" /> */}
      <GridColumn field="stone_id" title="Stone ID" width="75" />
      {/* <GridColumn field="cert_no" title="Certificate No" width="200" /> */}
      {/* <GridColumn field="size" title="Size" width="90" /> */}
      <GridColumn field="diamond_type_name" title="Diamond Type" width="105" />
      <GridColumn field="carat_from" title="Carat From" width="85" />
      <GridColumn field="carat_To" title="Carat to" width="65" />
      <GridColumn field="shape" title="Shape" width="55" />
      <GridColumn field="color" title="Color" width="45" />
      <GridColumn field="fancy_color" title="Fancy Color" width="90" />
      <GridColumn field="Overtone" title="Overtone" width="70" />
      <GridColumn field="intensity" title="Intensity" width="70" />
      <GridColumn field="clarity" title="Clarity" width="55" />
      <GridColumn field="cut" title="Cut" width="40" />
      <GridColumn field="polish" title="Poilish" width="55" />
      <GridColumn field="symmetry" title="Symmetry" width="80" />
      <GridColumn field="fluorescence" title="Fluorescence" width="100" />
      {/* <GridColumn field="rapRate" title="Rap Rate" width="200" /> */}
      {/* <GridColumn field="disc" title="Discount" width="200" /> */}
      <GridColumn field="disc_from" title="Discount From" width="110" />
      <GridColumn field="disc_to" title="Discount To" width="90" />
      {/* <GridColumn field="rate" title="Rate $/CT" width="200" /> */}
      {/* <GridColumn field="amount" title="Amount" width="200" /> */}
      <GridColumn field="Table_from" title="Table From" width="85" />
      <GridColumn field="Table_to" title="Table To" width="65" />
      <GridColumn field="depth_from" title="Depth From" width="90" />
      <GridColumn field="depth_to" title="Depth To" width="70" />
      {/* <GridColumn field="depth" title="Depth" width="200" /> */}
      {/* <GridColumn field="measurement" title="Measurement" width="200" /> */}
      <GridColumn field="ratio_from" title="Ratio From" width="85" />
      <GridColumn field="ratio_to" title="Ratio To" width="65" />
      <GridColumn field="length_from" title="Length From" width="95" />
      <GridColumn field="length_to" title="Length To" width="75" />
      <GridColumn field="width_from" title="Width From" width="95" />
      <GridColumn field="width_to" title="Width To" width="75" />
      {/* <GridColumn field="table" title="Table %" width="200" /> */}
      {/* <GridColumn field="depth_pr" title="Depth %" width="200" /> */}
      {/* <GridColumn field="city" title="City" width="200" /> */}
      {/* <GridColumn field="country" title="Country" width="200" /> */}
      {/* <GridColumn field="rapamount" title="Rap Amount" width="200" /> */}

      {/* <GridColumn field="stone_id" title="Stone ID" width="200" /> */}
      {/* <GridColumn field="DiamondType" title="Diamond Type" width="200" /> */}
      {/* <GridColumn field="Shape" title="Shape" width="200" /> */}
      {/* <GridColumn field="Size" title="Size" width="200" /> */}
      {/* <GridColumn field="Color" title="Color" width="200" /> */}
      {/* <GridColumn field="Clarity" title="Clarity" width="200" /> */}
      {/* <GridColumn field="Cut" title="Cut" width="200" /> */}
      {/* <GridColumn field="Polish" title="Polish" width="200" /> */}
      {/* <GridColumn field="Symmentry" title="Symmentry" width="200" /> */}
      {/* <GridColumn field="UnitPrice" title="Status" width="100" /> */}
    </Grid>
  );

  <Modal
    show={IsDeleteCart}
    size="lg"
    backdrop="static"
    onHide={() => setIsDeleteCart(false)}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <div className="p-4">
      <div className="">
        <div className="text-center my-5">
          <h5>Are you sure you want to delete this Data ?</h5>
        </div>
        <Modal.Footer className="mt-3">
          <div
            className="py-2 px-4 border rounded pointer bg-secondary text-white mx-2 pointer"
            onClick={() => {
              setIsDeleteCart(false);
            }}
          >
            Cancel
          </div>
          <div
            className="py-2 px-4 border rounded pointer bg-primary text-white mx-2 pointer"
            onClick={CustomCell}
          >
            Delete Cart
          </div>
        </Modal.Footer>
      </div>
    </div>
  </Modal>;

  return <>{grid}</>;
};

const mapStateToProps = (state) => ({
  masterListReducer: state.masterReducer.masterList,
  demandListReducer: state.demandReducer.demandList,
  addDemandReducer: state.demandReducer.addDemand,
  deleteDemandReducer: state.demandReducer.deleteDemand,
});
const mapDispatchToProps = (dispatch) => ({
  getDemandList: () => dispatch(getDemandList()),
  deleteDemandAction: (details) => dispatch(deleteDemandList(details)),
  getMastersList: (payload) => dispatch(getMastersList(payload)),
  resetAddDemand: () => dispatch({ type: ADD_DEMAND_LIST_RESET }),
  resetDelete: () => dispatch({ type: DELETE_DEMAND_LIST_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DemandTable);
