import axios from "axios";
import { pairedDiamondList_Url } from "../common/apiSheet";
import { GET_PAIRED_STONE_LIST_ERROR, GET_PAIRED_STONE_LIST_SUCCESS } from "../common/constant";

export const getPairedStoneList = () => {
 return (dispatch) => {
  const requestOptions = {
   method: "GET",
   url: `${pairedDiamondList_Url}`,
   headers: {
    'Authorization': `Bearer ${localStorage.getItem("token")}`
   },
  }
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: GET_PAIRED_STONE_LIST_SUCCESS,
     data: resp.data.data.result
    });
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: GET_PAIRED_STONE_LIST_ERROR,
      msg: error,
     });
    } else if (data.status === 422) {
     let validationError = data.data.message;
     dispatch({
      type: GET_PAIRED_STONE_LIST_ERROR,
      msg: validationError,
     });
    }
   });
 };
};