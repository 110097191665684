import axios from "axios";
// import fileDownload from "js-file-download";
import moment from "moment";
import { exportExcel_Url, exportPdf_Url } from "../common/apiSheet";
import { EXPORT_EXCEL_ERROR, EXPORT_EXCEL_SUCCESS, EXPORT_PDF_ERROR, EXPORT_PDF_SUCCESS } from "../common/constant";

export const exportPdf = ({ details, dt }) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: `${exportPdf_Url}${JSON.stringify(details)}&dt=${dt}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      'responseType': 'blob'

    }
    axios(requestOptions)
      .then((resp) => {
        const blob = new Blob([resp.data]);
        const fileName = `DD_WEB_${moment().format('DDMMyyyy')}_${Math.floor(100000 + Math.random() * 9000)}.pdf`
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        dispatch({
          type: EXPORT_PDF_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: EXPORT_PDF_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: EXPORT_PDF_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const exportExcel = ({ details, dt }) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: `${exportExcel_Url}${JSON.stringify(details)}&dt=${dt}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      'responseType': 'blob'
    }
    axios(requestOptions)
      .then((resp) => {
        const blob = new Blob([resp.data]);
        const fileName = `DD_WEB_${moment().format('DDMMyyyy')}_${Math.floor(100000 + Math.random() * 9000)}.xlsx`
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        dispatch({
          type: EXPORT_EXCEL_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: EXPORT_EXCEL_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: EXPORT_EXCEL_ERROR,
            msg: validationError,
          });
        }
      });
  };
};
