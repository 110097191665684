import React from "react";
import { Modal } from "react-bootstrap";

const StoneVideoPreviewModal = (props) => {

  return (
    <Modal show={props.show} centered onHide={props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Video Preview</Modal.Title>
      </Modal.Header>
      {!!props.details && props.details !== "" && props.details !== "N/A" ? (
        <>
          <Modal.Body className="text-center">
            <iframe
              src={props.details.replace("http:", "https:")}
              title="Video Preview"
              width="100%"
              height="620"
            ></iframe>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Body className="text-center py-5">
            <h4>No Video Available!!!</h4>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default StoneVideoPreviewModal;
