import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
// import Footer from "./userLayout/Footer";
// import Header from "./userLayout/Header";

const NotFound = () => {
  return (
    <>
      {/* <Header /> */}
      <Header />
      <Container
        fluid
        className="banner"
        style={{ height: "120px", padding: "0", minHeight: "auto" }}
      >
        <Container>
          {/* <h2 className="pageName" data-aos="zoom-out"  data-aos-duration="1000">About Us</h2> */}
        </Container>
      </Container>
      <main className="pt-5">
        <div className="text-center bg-transparent">
          <div className="notFound-block">
            <h1>404</h1>
            <h2>
              <span className="text-primary">Oops!</span> Page Not Found
            </h2>
            <p className="mt-4">The page you are looking for doesn't exist.</p>
            {/* <i className="fa-regular fa-face-surprise fa-5x text-muted"></i> */}
          </div>
        </div>
      </main>
      <Footer />
      {/* <Footer /> */}
    </>
  );
};

export default NotFound;
