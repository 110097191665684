// const baseUrl = "http://192.168.0.4/delight-diamond-laravel/public/api"; 
// const vendorUrl = "http://192.168.0.34:8080/vendor"; 
// const merchantUrl = "http://192.168.0.34:8080/api/merchant"; 

const baseUrl = "https://server.delightdiamonds.com/api";
const vendorUrl = "https://server.delightdiamonds.com/vendor";
const merchantUrl = "https://server.delightdiamonds.com/api/merchant";


export const countryList = baseUrl + "/country"
// export const countryList = baseUrl + "/country"

export const login_Url = baseUrl + "/login"
export const logout_Url = baseUrl + "/logout"
export const register_Url = baseUrl + "/register"

export const forgotPassword_URL = baseUrl + "/forgotpassword"
export const verifyOtp_URL = baseUrl + "/verifyotp"
export const resetPassword_URL = baseUrl + "/updatepassword"

//  forgot pass
export const forgotPass = `${baseUrl}/forgotpassword`
export const verifyOtp_Url = `${baseUrl}/verifyotp`
export const updateForgotPass = `${baseUrl}/updatepassword`

//  get diamond details
export const getDiamonrDetails = `${baseUrl}/details`

//  dashboard details
export const getDashboardDetails_Url = `${baseUrl}/dashboard`

export const profile = `${baseUrl}/profile`
export const changePassword_Url = `${baseUrl}/change-password`

export const masterList_Url = `${baseUrl}/master`

export const demandList_Url = `${baseUrl}/demand`
export const addDemand_Url = `${baseUrl}/demand/store`
export const deleteDemand_Url = `${baseUrl}/demand/delete`

export const cartList_Url = `${baseUrl}/cart`
export const createCart_Url = `${baseUrl}/cart/store`
export const deleteCart_Url = `${baseUrl}/cart/delete`

export const inquiryList_Url = `${baseUrl}/inquiry`
export const addInquiry_Url = `${baseUrl}/inquiry/store`
export const deleteInquiry_Url = `${baseUrl}/inquiry/delete`

export const exportPdf_Url = `${baseUrl}/export/pdf?stone_id=`
export const exportExcel_Url = `${baseUrl}/export/excel?stone_id=`

export const holdStoneList_Url = `${baseUrl}/hold`
export const addHoldStone_Url = `${baseUrl}/hold/store`
export const deleteHoldStone_Url = `${baseUrl}/hold/delete`

export const confirmStoneList_Url = `${baseUrl}/confirm`
export const addConfirmStone_Url = `${baseUrl}/confirm/store`
export const deleteConfirmStone_Url = `${baseUrl}/confirm/delete`

export const viewRequest_Url = `${baseUrl}/request`
export const addViewRequest_Url = `${baseUrl}/request/store`
export const deleteViewRequest_Url = `${baseUrl}/request/delete`

export const getAppointment_Url = `${baseUrl}/appointment`
export const addAppointment_Url = `${baseUrl}/appointment/store`
export const deleteAppointment_Url = `${baseUrl}/appointment/delete`

export const searchDiamonds_Url = `${baseUrl}/search`

export const saveSearchList_Url = `${baseUrl}/search/list`
export const AddsaveSearch_Url = `${baseUrl}/search/store`
export const DeletesaveSearch_Url = `${baseUrl}/search/delete`

export const searchStone_Url = `${baseUrl}/mainsearch`

export const getSlotList_Url = `${baseUrl}/slot`

export const getNewArrivalList_Url = `${baseUrl}/newarrival`

//Get Policy
export const getPolicy_Url = `${baseUrl}/policy`

//Check Terms
export const checkTerms_Url = `${baseUrl}/terms`

//Emai; Excel
export const emailExcel_Url = `${baseUrl}/export/email?stone_id=`

export const email_Activat_Url = `${baseUrl}/activation`

export const marketing_Url = `${baseUrl}/marketing`

//Check Diamonds type

export const diamondsType_Url = `${baseUrl}/changediamond`

//star Melee

export const starmeleeDiamond_Url = `${baseUrl}/starmelee/shape`

// starmelee carat

export const starmeleeCarat_Url = `${baseUrl}/starmelee/carat`

// starmelee price

export const starmeleePrice_Url = `${baseUrl}/starmelee/price`

// starmelee inquiry Submit

export const starmeleeInquairy_Url = `${baseUrl}/starmelee/inquiry`

// starmelee inquiryList

export const starmeleeInquairyList_Url = `${baseUrl}/starmelee/inquiry`

//  vendor country list
export const vendorCountryList_Url = `${vendorUrl}/country`
export const vendorRegister_Url = `${vendorUrl}/register`
export const vendorEmailActivation_Url = `${vendorUrl}/activation`

// merchant
export const merchantCountryList_Url = `${merchantUrl}/country`
export const merchantRegister_Url = `${merchantUrl}/register`
export const merchantEmailActivation_Url = `${merchantUrl}/activation`


//  paired 
export const pairedDiamondList_Url = `${baseUrl}/pair`
