import React from "react"

const Footer = () => {
      return (
            <footer className="user-footer text-center">
                  &copy; Copyright 2023, All Rights Reserved.
            </footer>
      );
}

export default Footer;
