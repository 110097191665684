import { EMAIL_ACTIVAT_ERROR, EMAIL_ACTIVAT_RESET, EMAIL_ACTIVAT_SUCCESS, MERCHANT_EMAIL_ACTIVAT_ERROR, MERCHANT_EMAIL_ACTIVAT_RESET, MERCHANT_EMAIL_ACTIVAT_SUCCESS, VENDOR_EMAIL_ACTIVAT_ERROR, VENDOR_EMAIL_ACTIVAT_RESET, VENDOR_EMAIL_ACTIVAT_SUCCESS } from "../common/constant";

let initialState = {
  emailActivate: {
    error: false,
    errors: null,
    success: false,
  },
  vendorEmailActivate: {
    error: false,
    errors: null,
    success: false,
  },
  merchantEmailActivate: {
    error: false,
    errors: null,
    success: false,
  },
};

export default function emailActivateReducer(state = initialState, action) {
  switch (action.type) {
    case EMAIL_ACTIVAT_SUCCESS:
      return {
        ...state,
        emailActivate: {
          ...state.emailActivate,
          success: true,
          error: false,
          errors: null,
        },
      };

    case EMAIL_ACTIVAT_ERROR:
      return {
        ...state,
        emailActivate: {
          ...state.emailActivate,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case EMAIL_ACTIVAT_RESET:
      return {
        ...state,
        emailActivate: {
          ...state.emailActivate,
          success: false,
          error: false,
          errors: null,
        },
      };

    case VENDOR_EMAIL_ACTIVAT_SUCCESS:
      return {
        ...state,
        vendorEmailActivate: {
          ...state.vendorEmailActivate,
          success: true,
          error: false,
          errors: null,
        },
      };

    case VENDOR_EMAIL_ACTIVAT_ERROR:
      return {
        ...state,
        vendorEmailActivate: {
          ...state.vendorEmailActivate,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case VENDOR_EMAIL_ACTIVAT_RESET:
      return {
        ...state,
        vendorEmailActivate: {
          ...state.vendorEmailActivate,
          success: false,
          error: false,
          errors: null,
        },
      };


    case MERCHANT_EMAIL_ACTIVAT_SUCCESS:
      return {
        ...state,
        merchantEmailActivate: {
          ...state.merchantEmailActivate,
          success: true,
          error: false,
          errors: null,
        },
      };

    case MERCHANT_EMAIL_ACTIVAT_ERROR:
      return {
        ...state,
        merchantEmailActivate: {
          ...state.merchantEmailActivate,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case MERCHANT_EMAIL_ACTIVAT_RESET:
      return {
        ...state,
        merchantEmailActivate: {
          ...state.merchantEmailActivate,
          success: false,
          error: false,
          errors: null,
        },
      };

    default:
      return state;
  }
}
