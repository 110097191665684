import { ADD_HOLD_STONE_LIST_ERROR, ADD_HOLD_STONE_LIST_RESET, ADD_HOLD_STONE_LIST_SUCCESS, ADD_INQUIRY_LIST_ERROR, ADD_INQUIRY_LIST_RESET, ADD_INQUIRY_LIST_SUCCESS, DELETE_HOLD_STONE_LIST_ERROR, DELETE_HOLD_STONE_LIST_RESET, DELETE_HOLD_STONE_LIST_SUCCESS, DELETE_INQUIRY_LIST_ERROR, DELETE_INQUIRY_LIST_RESET, DELETE_INQUIRY_LIST_SUCCESS, GET_HOLD_STONE_LIST_ERROR, GET_HOLD_STONE_LIST_RESET, GET_HOLD_STONE_LIST_SUCCESS, GET_INQUIRY_LIST_ERROR, GET_INQUIRY_LIST_RESET, GET_INQUIRY_LIST_SUCCESS } from "../common/constant";

let initialState = {
  HoldStoneList: {
    error: false,
    data: null,
    errors: null,
    success: false,
  },
  createHoldStone: {
    error: false,
    errors: null,
    success: false,
    msg: null,
    count: 0
  },
  deleteHoldStone: {
    error: false,
    errors: null,
    success: false,
  }
};

export default function holdStoneReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HOLD_STONE_LIST_SUCCESS:
      return { ...state, HoldStoneList: { ...state.HoldStoneList, success: true, error: false, errors: null, data: action.response }, };

    case GET_HOLD_STONE_LIST_ERROR:
      return { ...state, HoldStoneList: { ...state.HoldStoneList, success: false, error: true, errors: action.msg } };

    case GET_HOLD_STONE_LIST_RESET:
      return { ...state, HoldStoneList: { ...state.HoldStoneList, success: false, error: false, errors: null } };

    //  add

    case ADD_HOLD_STONE_LIST_SUCCESS:
      return { ...state, createHoldStone: { ...state.createHoldStone, success: true, error: false, errors: null, msg: action.msg, count: action.count }, };

    case ADD_HOLD_STONE_LIST_ERROR:
      return { ...state, createHoldStone: { ...state.createHoldStone, success: false, error: true, errors: action.msg } };

    case ADD_HOLD_STONE_LIST_RESET:
      return { ...state, createHoldStone: { ...state.createHoldStone, success: false, error: false, errors: null } };



    //  delete

    case DELETE_HOLD_STONE_LIST_SUCCESS:
      return { ...state, deleteHoldStone: { ...state.deleteHoldStone, success: true, error: false, errors: null }, };

    case DELETE_HOLD_STONE_LIST_ERROR:
      return { ...state, deleteHoldStone: { ...state.deleteHoldStone, success: false, error: true, errors: action.msg } };

    case DELETE_HOLD_STONE_LIST_RESET:
      return { ...state, deleteHoldStone: { ...state.deleteHoldStone, success: false, error: false, errors: null } };

    default:
      return state;
  }
}