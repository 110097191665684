import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import imageurl from "../images";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

const Knowlege = () => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
      return (<>
            <Header />
            <Container fluid className="banner">
                  <Container>
                        <h2 className="pageName" data-aos="zoom-out"  data-aos-duration="1000">Knowledge</h2>
                  </Container>
            </Container>

            <main className="container">
                  <section>
                        <h2 className="heading" data-aos="fade-right" data-aos-duration="1000">Shapes</h2>
                        <p data-aos="zoom-in-up" data-aos-duration="1000">While diamonds have been around for many years, it was only recently that humans realized that an uncut diamond can be turned into a faceted one that involves having different cuts, sizes, shapes etc. Once this concept became known, people can now demand diamonds in the shape of their choice. Often times, people confuse cut and shape as they both are almost synonymous in nature. But what one needs to know is that they are two separate things that incorporate separate details.</p>
                        <p data-aos="zoom-in-up" data-aos-duration="1000">If you are looking for an ideal diamond, then the first thing you need to do is select your shape. The diamond’s shape is generally referred to as the basic outline of the diamond. The shape of the diamond is generally associated with the proportions and size, like square, round triangle, etc.</p>

                        {/* Begin:: Shapes */}
                        <Row className="mt-5">
                              <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="1000">
                                    <div className="shape-card" >
                                          <div className="d-flex justify-content-between mb-3">
                                                <h4 className="fw-semibold my-3">Round</h4>
                                                <span className="diam-icon icon-round ab text-primary"></span>
                                          </div>
                                          <p className="mb-0">Being the most preferred shape, it has a 360degree symmetrical shape. From traditional solitaires to contemporary designs, this cut suits all mountings.</p>
                                          <button className="btn btn-link px-0 text-primary fw-bold  py-0 mt-2 d-none" onClick={handleShow}>360 view</button>
                                    </div>
                              </Col>
                              <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="1000">
                                    <div className="shape-card">
                                          <div className="d-flex justify-content-between mb-3">
                                                <h4 className="fw-semibold my-3">Oval</h4>
                                                <span className="diam-icon icon-Single_6 ab text-primary"></span>
                                          </div>
                                          <p className="mb-0">Oval is considered to be the perfect shape to accentuate long and slender fingers. The oval diamonds traditional ratio can be said to be from 1.33 to 1.66.</p>
                                          <button className="btn btn-link px-0 text-primary fw-bold  py-0 mt-2 d-none" onClick={handleShow}>360 view</button>
                                    </div>
                              </Col>
                              <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="1000">
                                    <div className="shape-card">
                                          <div className="d-flex justify-content-between mb-3">
                                                <h4 className="fw-semibold my-3">Emerald</h4>
                                                <span className="diam-icon icon-Single_9 text-primary"></span>
                                          </div>
                                          <p className="mb-0">This cut is unique as it creates an alluring optical appearance with its cut in parallel facets. Usually, in a rectangular shape, the emerald’s length and width ratios may vary.</p>
                                          <button className="btn btn-link px-0 text-primary fw-bold  py-0 mt-2 d-none" onClick={handleShow}>360 view</button>
                                    </div>
                              </Col>
                              <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="1000">
                                    <div className="shape-card">
                                          <div className="d-flex justify-content-between mb-3">
                                                <h4 className="fw-semibold my-3">Heart</h4>
                                                <span className="diam-icon icon-Single_8 text-primary"></span>
                                          </div>
                                          <p className="mb-0">Staying true to the name, this shape steals hearts. this cut is usually given to those celebrating love and togetherness.</p>
                                          <button className="btn btn-link px-0 text-primary fw-bold  py-0 mt-2 d-none" onClick={handleShow}>360 view</button>
                                    </div>
                              </Col>
                              <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="1000">
                                    <div className="shape-card">
                                          <div className="d-flex justify-content-between mb-3">
                                                <h4 className="fw-semibold my-3">Pear</h4>
                                                <span className="diam-icon icon-Single_5 text-primary"></span>
                                          </div>
                                          <p className="mb-0">This well-proportioned cut gives it an elegant depth. In the shape of a teardrop, it is a combination of the beautiful marquise and round cut. Polished with 58 facets, it’s ratio is between 150 to 1.75:1.</p>
                                          <button className="btn btn-link px-0 text-primary fw-bold  py-0 mt-2 d-none" onClick={handleShow}>360 view</button>
                                    </div>
                              </Col>
                              <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="1000">
                                    <div className="shape-card">
                                          <div className="d-flex justify-content-between mb-3">
                                                <h4 className="fw-semibold my-3">Princess</h4>
                                                <span className="diam-icon icon-Single_4 text-primary"></span>
                                          </div>
                                          <p className="mb-0">Quite similar to the cutting techniques of an emerald cut diamond, it creates an everlasting look with their angled and cropped corners.</p>
                                          <button className="btn btn-link px-0 text-primary fw-bold  py-0 mt-2 d-none" onClick={handleShow}>360 view</button>
                                    </div>
                              </Col>
                              <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="1000">
                                    <div className="shape-card">
                                          <div className="d-flex justify-content-between mb-3">
                                                <h4 className="fw-semibold my-3">SQ Emerald</h4>
                                                <span className="diam-icon icon-Single_11 text-primary"></span>
                                          </div>
                                          <p className="mb-0">Mostly suited in vintage-styled jewellery, this cut has been in popular demand. It combines the characteristics of both, oval and round.</p>
                                          <button className="btn btn-link px-0 text-primary fw-bold  py-0 mt-2 d-none" onClick={handleShow}>360 view</button>
                                    </div>
                              </Col>
                              <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="1000">
                                    <div className="shape-card">
                                          <div className="d-flex justify-content-between mb-3">
                                                <h4 className="fw-semibold my-3">Radiant</h4>
                                                <span className="diam-icon icon-Single_12 text-primary"></span>
                                          </div>
                                          <p className="mb-0">This is the perfect combination of the emerald's outline with the brilliant round cut. there is more weight in the depth of a radiant diamond with higher colour concentration compared to other shapes.</p>
                                          <button className="btn btn-link px-0 text-primary fw-bold  py-0 mt-2 d-none" onClick={handleShow}>360 view</button>
                                    </div>
                              </Col>

                              <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="1000">
                                    <div className="shape-card">
                                          <div className="d-flex justify-content-between mb-3">
                                                <h4 className="fw-semibold my-3">Cushion</h4>
                                                <span className="diam-icon icon-Single_14 text-primary"></span>
                                          </div>
                                          <p className="mb-0">Mostly suited in vintage-styled jewellery, this cut has been in popular demand. It combines the characteristics of both, oval and round.</p>
                                          <button className="btn btn-link px-0 text-primary fw-bold  py-0 mt-2 d-none" onClick={handleShow}>360 view</button>
                                    </div>
                              </Col>
                        </Row>
                        {/* End:: Shapes */}
                  </section>

                  <section>
                        <h2 className="heading" data-aos="fade-right" data-aos-duration="1000">4CS OF DIAMONDS</h2>
                        <p data-aos="zoom-in-up" data-aos-duration="1000">Diamonds are the most desired and valued stones of our times. Every diamond present has its own set of various characteristics which makes every diamond unique. Only after considering every little characteristic, one can truly determine the value a diamond holds.</p>
                        <p data-aos="zoom-in-up" data-aos-duration="1000">In order to grade a diamond, one has to consider the 4Cs. They are Cut, Colour, Clarity and Carat. This has now become a standard grading system used by numerous people who trade as well as diamond enthusiasts. These four characteristics also play a pivotal role in finalizing the price of the diamond. The colour, clarity and weight are natural elements whereas the cut is later added after the mining process and this is also one of the main distinguishing features while determining the true value of the diamond.</p>

                        <Row className="g-5">
                              <Col lg="4" className="border-end"  data-aos="zoom-in-right" data-aos-duration="1000">
                                    <h4 className="mt-5 fw-semibold">Carat</h4>
                                    <p>Carat is the unit of measurement for the physical weight of diamonds.</p>
                                    <div className="table-responsive">
                                          <table className="table">
                                                <thead>
                                                      <tr>
                                                            <th>Size</th>
                                                            <th>Size Range</th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      <tr>
                                                            <td>+0.30</td>
                                                            <td>0.30 to 0.399</td>
                                                      </tr>
                                                      <tr>
                                                            <td>+0.40</td>
                                                            <td>0.40 to 0.499</td>
                                                      </tr>
                                                      <tr>
                                                            <td>+0.50</td>
                                                            <td>0.50 to 0.699</td>
                                                      </tr>
                                                      <tr>
                                                            <td>+0.70</td>
                                                            <td>0.70 to 0.899</td>
                                                      </tr>
                                                      <tr>
                                                            <td>+0.90</td>
                                                            <td>0.90 to 0.999</td>
                                                      </tr>
                                                      <tr>
                                                            <td>+1.00</td>
                                                            <td>1.00 to 1.499</td>
                                                      </tr>
                                                      <tr>
                                                            <td>+1.50</td>
                                                            <td>1.50 to 1.999</td>
                                                      </tr>
                                                      <tr>
                                                            <td>+2.00</td>
                                                            <td>2.00 to 2.499</td>
                                                      </tr>
                                                      <tr>
                                                            <td>+2.50</td>
                                                            <td>2.50 to 2.999</td>
                                                      </tr>
                                                      <tr>
                                                            <td>	+3.00</td>
                                                            <td>3.00 to 3.999</td>
                                                      </tr>
                                                      <tr>
                                                            <td>	+4.00</td>
                                                            <td>4.00 to 10.999</td>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    </div>
                              </Col>
                              <Col lg="8"data-aos="zoom-in-left"  data-aos-duration="1000">
                                    <h4 className="mt-5 fw-semibold">Color</h4>
                                    <p>Diamonds that ranges from colorless to light yellow fall within the normal color range. The diamond color scale starts with the letter D and ends with Z. D-colored diamonds have the least amount of colors while Z colored diamonds have the most color. As diamonds are closer to D on the measuring scale, the more expensive they will be. Beyond the Z color, diamonds are graded as Fancy Color, which uses a separate set of parameters.</p>
                                    <p>For a diamond to be more precious, they must reflect all the shades of color. Any color tint in the diamond will obstruct this. Thus, the less color there is, the more will it reflect other shades of color. Colorless diamonds are very rare. They contain little to no impurities and are essentially chemically pure. As the diamond shows more tint of color, it goes down the color grading scale.</p>

                                    <img src={imageurl.diamondChart} className="img-fluid" alt="chart" />
                              </Col>
                        </Row>
                  </section>

                  <section>
                        <h4 className="mt-5 fw-semibold" data-aos="fade-right" data-aos-duration="1000" >Clarity</h4>
                        <p data-aos="zoom-in-up" data-aos-duration="1000">Virtually all diamonds contain identifying characteristics, most of them too small to be seen with the unaided eye. These "inclusions" are nature’s birthmarks; when viewed using magnification they like tiny crystals, clouds or feathers. To determine a diamond’s clarity grade, a gemmologist will consider the size, number, position, color and general of any inclusions. Diamonds exhibition no such inclusions are categorized as flawless (FL) or internally flawless (IF). In general, the greater the number and size of inclusions within a diamond, the lower its clarity grade. Our diamonds are selected from the top clarity grades; any inclusions only become visible under 10x power magnification.</p>
                        <ul>
                              <li data-aos="zoom-in-up" data-aos-duration="1000">FL - Flawless</li>
                              <li data-aos="zoom-in-up" data-aos-duration="1000">IF- Inernally Flawless</li>
                              <li data-aos="zoom-in-up" data-aos-duration="1000">VVS1 - Very Very Slightly Included-1</li>
                              <li data-aos="zoom-in-up" data-aos-duration="1000">VVS2	- Very Very Slightly Included-2</li>
                              <li data-aos="zoom-in-up" data-aos-duration="1000">VS1	- Very Slightly Included-1</li>
                              <li data-aos="zoom-in-up" data-aos-duration="1000">VS2	- Very Slightly Included-2</li>
                              <li data-aos="zoom-in-up" data-aos-duration="1000">SI1	- Slightly Included-1</li>
                              <li data-aos="zoom-in-up" data-aos-duration="1000">SI2	- Slightly Included-2</li>
                              <li data-aos="zoom-in-up" data-aos-duration="1000">SI3	- Slightly Included-3</li>
                              <li data-aos="zoom-in-up" data-aos-duration="1000">I1	- Included-1</li>
                              <li data-aos="zoom-in-up" data-aos-duration="1000">I2	- Included-2</li>
                              <li data-aos="zoom-in-up" data-aos-duration="1000">I3	- Included-3</li>
                        </ul>
                  </section>

                  <section>
                        <Row className="g-5">
                              <Col lg="6" className="border-end1" data-aos="fade-right" data-aos-duration="1000">
                                    <h4 className="fw-semibold">Cut</h4>
                                    <p>Diamond cut is a style or design guide used when shaping a diamond for polishing such as the brilliant cut. Cut does not refer to shape (pear, oval), but the symmetry, proportioning and polish of a diamond. The cut of a diamond greatly affects a diamond's brilliance; this means if it is cut poorly, it will be less luminous.</p>
                                    <div className="table-responsive">
                                          <table className="table">
                                                <tbody>
                                                      <tr>
                                                            <td>
                                                                  ID</td>
                                                            <td>Ideal</td>
                                                      </tr>
                                                      <tr>
                                                            <td>EX</td>
                                                            <td>Excellent</td>
                                                      </tr>
                                                      <tr>
                                                            <td>VG</td>
                                                            <td>Very Good</td>
                                                      </tr>
                                                      <tr>
                                                            <td>G</td>
                                                            <td>Good</td>
                                                      </tr>
                                                      <tr>
                                                            <td>F</td>
                                                            <td>Fair</td>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    </div>
                              </Col>
                              <Col lg="6" data-aos="fade-left" data-aos-duration="1000">
                                    <h4 className="fw-semibold">Polish</h4>
                                    <p>Polish refers to the degree of smoothness of each facet of a diamond as measured by a gemmologist. When a diamond is cut and polished, microscopic surface defects may be created by the polishing wheel as it drags tiny dislodged crystals across the diamond's surface. Depending on the severity, these defects may disrupt light patterns as the light rays enter and exit the diamond.</p>
                                    <div className="table-responsive">
                                          <table className="table">
                                                <tbody>
                                                      <tr>
                                                            <td>EX</td>
                                                            <td>Excellent</td>
                                                      </tr>
                                                      <tr>
                                                            <td>VG</td>
                                                            <td>Very Good</td>
                                                      </tr>
                                                      <tr>
                                                            <td>G</td>
                                                            <td>Good</td>
                                                      </tr>
                                                      <tr>
                                                            <td>F</td>
                                                            <td>Fair</td>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    </div>
                              </Col>
                              <Col lg="6" className="mt-5" data-aos="fade-right" data-aos-duration="1000">
                                    <h4 className="fw-semibold">Symmetry</h4>
                                    <p>Symmetry refers to how precisely the various facets of a diamond align and intersect. This can include extra or misshapen facets, off centre culets and tables, and wavy girdles.</p>
                                    <p>A diamond with poor symmetry may misdirect light that travels into the diamond, sending it off at slightly wrong angles, and thereby reducing the diamond's brilliance. Often, a diamond cutter will purposefully allow a minor reduction in symmetry as a way of preventing a defect present in the rough stone from being retained as part of the finished diamond.</p>
                                    <div className="table-responsive">
                                          <table className="table">
                                                <tbody>
                                                      <tr>
                                                            <td>EX</td>
                                                            <td>Excellent</td>
                                                      </tr>
                                                      <tr>
                                                            <td>VG</td>
                                                            <td>Very Good</td>
                                                      </tr>
                                                      <tr>
                                                            <td>G</td>
                                                            <td>Good</td>
                                                      </tr>
                                                      <tr>
                                                            <td>F</td>
                                                            <td>Fair</td>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    </div>
                              </Col>
                              <Col lg="6" className="mt-5" data-aos="fade-left" data-aos-duration="1000">
                                    <h4 className="fw-semibold">Fluorescence</h4>
                                    <p>Fluorescence is a greatly misunderstood concept. Fluorescence is the reaction of trace minerals within the diamond that cause the diamond to glow when exposed to ultraviolet light. In the laboratory, special ultraviolet lamps are used to check for this but the sun is also a source of ultraviolet rays so diamonds will react in daylight as well. About one third of all diamonds fluoresce. Of these, the most common color is blue, but diamonds can fluoresce other colors. More than 95% of those that do fluoresce will fluoresce blue. The next most common color is yellow. Any other color of fluorescence would be rare.</p>
                                    <div className="table-responsive">
                                          <table className="table">
                                                <tbody>
                                                      <tr>
                                                            <td>N</td>
                                                            <td>None</td>
                                                      </tr>
                                                      <tr>
                                                            <td>F</td>
                                                            <td>Faint</td>
                                                      </tr>
                                                      <tr>
                                                            <td>M</td>
                                                            <td>Medium</td>
                                                      </tr>
                                                      <tr>
                                                            <td>ST</td>
                                                            <td>Strong</td>
                                                      </tr>
                                                      <tr>
                                                            <td>VST</td>
                                                            <td>Very Strong</td>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    </div>
                              </Col>
                        </Row>
                  </section>

                  <section>
                        <h2 data-aos="fade-right" data-aos-duration="1000">Components of A Diamond</h2>
                        <p data-aos="zoom-in-up" data-aos-duration="1000">Every diamond is unique, thanks to its structural features. The basic anatomy of a diamond includes the proportion, dispersion, brilliance and scintillation. A diamond also comprises components such as Table, Girdle, Crown, Cutlet and Pavilion, which need to be looked in before determining its worth.</p>
                        <Row className="diam-componants small">
                              <Col className="col-lg col-md-4 col-sm-6 col-12" data-aos="zoom-in-up" data-aos-duration="1000">
                                    <img src={imageurl.table} className="img-fluid" alt="table" />
                                    <h4 className="fw-semibold">Table</h4>
                                    <ul>
                                          <li>Largest facet of diamond</li>
                                          <li>Flat surface</li>
                                          <li>Similar to a table-top</li>
                                    </ul>
                              </Col>
                              <Col className="col-lg col-md-4 col-sm-6 col-12" data-aos="zoom-in-up" data-aos-duration="1000">
                                    <img src={imageurl.crown} className="img-fluid" alt="table" />
                                    <h4 className="fw-semibold">Crown</h4>
                                    <ul>
                                          <li>Extension of the tale.</li>
                                          <li>Ends at the girdle's top.</li>
                                          <li>Step or brilliant-cut facets.</li>
                                    </ul>
                              </Col>
                              <Col className="col-lg col-md-4 col-sm-6 col-12" data-aos="zoom-in-up" data-aos-duration="1000">
                                    <img src={imageurl.girdle} className="img-fluid" alt="table" />
                                    <h4 className="fw-semibold">Gridle</h4>
                                    <ul>
                                          <li>Diamond's outer-edge</li>
                                          <li>Widest point.</li>
                                          <li>Meeting place of crown and pavilion.</li>
                                          <li>Can be rough, faceted, or polished.</li>
                                    </ul>
                              </Col>
                              <Col className="col-lg col-md-4 col-sm-6 col-12" data-aos="zoom-in-up" data-aos-duration="1000">
                                    <img src={imageurl.pavilion} className="img-fluid" alt="table" />
                                    <h4 className="fw-semibold">Pavilion</h4>
                                    <ul>
                                          <li>At the bottom.</li>
                                          <li>Acts as a bridge between girdle & cutlet.</li>
                                          <li>Reflection of light is determined from here.</li>
                                          <li>Quality of the cut can allow maximum light.</li>
                                    </ul>
                              </Col>
                              <Col className="col-lg col-md-4 col-sm-6 col-12" data-aos="zoom-in-up" data-aos-duration="1000">
                                    <img src={imageurl.culet} className="img-fluid" alt="table" />
                                    <h4 className="fw-semibold">Culet</h4>
                                    <ul>
                                          <li>Smallest facet.</li>
                                          <li>At the bottom most tip.</li>
                                          <li>Protects the pavilion</li>
                                          <li>Although rendered unnecessary, it is still being used.</li>
                                    </ul>
                              </Col>
                        </Row>
                  </section>
            </main>
            <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                  <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                              Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                              Save Changes
                        </Button>
                  </Modal.Footer>
            </Modal>
            <Footer />
      </>)
}

export default Knowlege;