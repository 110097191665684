import React from "react";
import { Spinner } from "react-bootstrap";
// import { MagnifyingGlass } from "react-loader-spinner";

function Loader() {
  return (
   <Spinner />
  );
}

export default Loader;
