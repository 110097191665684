import axios from "axios";
import { searchDiamonds_Url } from "../common/apiSheet";
import { SEARCH_DIAMONDS_ERROR, SEARCH_DIAMONDS_SUCCESS } from "../common/constant";

export const searchDiamonds = (details, skip = 0) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: `${searchDiamonds_Url}?$count=true&$top=60&$skip=${skip}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data: details
    }
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SEARCH_DIAMONDS_SUCCESS,
          response: resp.data.data.result,
          count: resp.data.data.count,
          details: {
            ProductIds: resp.data.data.ProductIds,
            Totalamount: resp.data.data.Totalamount,
            Totalcarat: resp.data.data.Totalcarat,
            Totaldiscount: resp.data.data.Totaldiscount,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SEARCH_DIAMONDS_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: SEARCH_DIAMONDS_ERROR,
            msg: validationError,
          });
        }
      });
  };
};
