import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

const ReturnPolicy = () => {
  return (
    <> 
      <Header />
      <Container fluid className="banner">
        <Container>
          <h2 className="pageName" data-aos="zoom-out" data-aos-duration="1000">
            Return Policy
          </h2>
        </Container>
      </Container>
      <section className="container">
        <h2
          data-aos="fade-right"
          data-aos-duration="1000"
          className="heading mb-4"
        >
          Shipping Information
        </h2>
        <ul data-aos="zoom-out-up" data-aos-duration="1000">
          <li>
            All shipments are free of charges to any place in the world upon
            purchase.
          </li>
          <li>
            All orders are fully insured before shipping. Items are usually
            shipped within 2/3 business days of our confirming an order -
            depending on item availability.
          </li>
          <li>Shipments cannot be made to P.O. boxes.</li>
          <li>No item is shipped until full payment has been received.</li>
          <li>
            Every item is shipped with an invoice and an original certificate,
            if one has been requested.
          </li>
          <li>
            Unless the buyer requests that their purchases be shipped some other
            way, goods with a purchase value of up to $8,000 USD are shipped by
            registered mail and are insured by Delight Diamonds. while in
            transit.
          </li>
          <li>
            Goods with a purchase value of over $10,000 USD are shipped Free of
            Cost.
          </li>
          <li>
            Goods with a purchase value of Less Than $10,000 USD are Charged
            100$ of Shipping Cost.
          </li>
          <li>
            Any Goods with A Purchase of Fancy Color Diamonds are Shipped Free
            Of Cost.
          </li>
        </ul>
      </section>
      <Footer />
    </>
  );
};

export default ReturnPolicy;
