import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_RESET,
  CHANGE_PASSWORD_SUCCESS,
  CHECK_DIAMONDS_TYPE_ERROR,
  CHECK_DIAMONDS_TYPE_RESET,
  CHECK_DIAMONDS_TYPE_SUCCESS,
  CHECK_TERMS_ERROR,
  CHECK_TERMS_RESET,
  CHECK_TERMS_SUCCESS,
  ERROR_COUNTRY,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD_SUCCESS,
  GET_COUNTRY,
  GET_MERCHANT_COUNTRY_LIST_ERROR,
  GET_MERCHANT_COUNTRY_LIST_RESET,
  GET_MERCHANT_COUNTRY_LIST_SUCCESS,
  GET_POLICY_ERROR,
  GET_POLICY_RESET,
  GET_POLICY_SUCCESS,
  GET_VENDOR_COUNTRY_LIST_ERROR,
  GET_VENDOR_COUNTRY_LIST_RESET,
  GET_VENDOR_COUNTRY_LIST_SUCCESS,
  LOGIN_ERROR,
  LOGIN_RESET,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_RESET,
  LOGOUT_SUCCESS,
  MARKETING_USER_ERROR,
  MARKETING_USER_RESET,
  MARKETING_USER_SUCCESS,
  MERCHANT_REGISTER_ERROR,
  MERCHANT_REGISTER_RESET,
  MERCHANT_REGISTER_SUCCESS,
  PROFILE_ERROR,
  PROFILE_RESET,
  PROFILE_SUCCESS,
  REGISTER_ERROR,
  REGISTER_RESET,
  REGISTER_SUCCESS,
  UPDATE_FORGOT_PASSWORD_ERROR,
  UPDATE_FORGOT_PASSWORD_RESET,
  UPDATE_FORGOT_PASSWORD_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_RESET,
  UPDATE_PROFILE_SUCCESS,
  VENDOR_REGISTER_ERROR,
  VENDOR_REGISTER_RESET,
  VENDOR_REGISTER_SUCCESS,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_RESET,
  VERIFY_OTP_SUCCESS,
} from "../common/constant";

let initialState = {
  login: {
    error: false,
    errors: null,
    success: false,
    data: [],
  },
  logout: {
    error: false,
    errors: null,
    success: false,
  },
  register: {
    error: false,
    errors: null,
    success: false,
  },
  getCountry: {
    error: false,
    errors: null,
    success: false,
    data: null,
  },
  profile: {
    error: false,
    errors: null,
    success: false,
    data: null,
  },
  marketinguser: {
    error: false,
    errors: null,
    success: false,
    data: null,
  },
  updateProfile: {
    error: false,
    errors: null,
    success: false,
    data: null,
  },
  changePass: {
    error: false,
    errors: null,
    success: false,
  },
  forgotPass: {
    error: false,
    errors: null,
    success: false,
  },
  verifyOtp: {
    error: false,
    errors: null,
    success: false,
  },
  updateForgotPass: {
    error: false,
    errors: null,
    success: false,
  },
  policy: {
    error: false,
    errors: null,
    success: false,
    data: null,
  },
  checkTerms: {
    error: false,
    errors: null,
    success: false,
  },
  checkDiamondsType: {
    error: false,
    errors: null,
    success: false,
  },
  vendorCountryList: {
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  venderRegister: {
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  merchantCountryList: {
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  merchantRegister: {
    data: null,
    error: false,
    errors: null,
    success: false,
  },
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          success: true,
          error: false,
          errors: null,
          data: action.data,
        },
      };

    case LOGIN_ERROR:
      return {
        ...state,
        login: {
          ...state.login,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case LOGIN_RESET:
      return {
        ...state,
        login: { ...state.login, success: false, error: false, errors: null },
      };

    //  logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        logout: { ...state.logout, success: true, error: false, errors: null },
      };

    case LOGOUT_ERROR:
      return {
        ...state,
        logout: { ...state.logout, success: false, error: true },
      };
    case LOGOUT_RESET:
      return {
        ...state,
        logout: { ...state.logout, success: false, error: false, errors: null },
      };

    //  register

    case REGISTER_SUCCESS:
      return {
        ...state,
        register: {
          ...state.register,
          success: true,
          error: false,
          errors: null,
        },
      };

    case REGISTER_ERROR:
      return {
        ...state,
        register: { ...state.register, success: false, error: true },
      };

    case REGISTER_RESET:
      return {
        ...state,
        register: {
          ...state.register,
          success: false,
          error: false,
          errors: null,
        },
      };

    //  country

    case GET_COUNTRY:
      return {
        ...state,
        getCountry: {
          ...state.getCountry,
          success: true,
          error: false,
          errors: null,
          data: action.response,
        },
      };

    case ERROR_COUNTRY:
      return {
        ...state,
        getCountry: { ...state.getCountry, success: false, error: true },
      };

    //  profile

    case PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          success: true,
          error: false,
          errors: null,
          data: action.response,
        },
      };

    case PROFILE_ERROR:
      return {
        ...state,
        profile: {
          ...state.profile,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case PROFILE_RESET:
      return {
        ...state,
        profile: {
          ...state.profile,
          success: false,
          error: false,
          errors: null,
        },
      };

    //  Update

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfile: {
          ...state.updateProfile,
          success: true,
          error: false,
          errors: null,
          data: action.response,
        },
      };

    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        updateProfile: { ...state.updateProfile, success: false, error: true },
      };

    case UPDATE_PROFILE_RESET:
      return {
        ...state,
        updateProfile: {
          ...state.updateProfile,
          success: false,
          error: false,
          errors: null,
        },
      };

    //  change pass

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePass: {
          ...state.changePass,
          success: true,
          error: false,
          errors: null,
        },
      };

    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePass: {
          ...state.changePass,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case CHANGE_PASSWORD_RESET:
      return {
        ...state,
        changePass: {
          ...state.changePass,
          success: false,
          error: false,
          errors: null,
        },
      };

    //  forgot pass

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPass: {
          ...state.forgotPass,
          success: true,
          error: false,
          errors: null,
        },
      };

    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPass: {
          ...state.forgotPass,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case FORGOT_PASSWORD_RESET:
      return {
        ...state,
        forgotPass: {
          ...state.forgotPass,
          success: false,
          error: false,
          errors: null,
        },
      };

    //  verify otp

    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        verifyOtp: {
          ...state.verifyOtp,
          success: true,
          error: false,
          errors: null,
        },
      };

    case VERIFY_OTP_ERROR:
      return {
        ...state,
        verifyOtp: {
          ...state.verifyOtp,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case VERIFY_OTP_RESET:
      return {
        ...state,
        verifyOtp: {
          ...state.verifyOtp,
          success: false,
          error: false,
          errors: null,
        },
      };

    //  updateForgotpass

    case UPDATE_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        updateForgotPass: {
          ...state.updateForgotPass,
          success: true,
          error: false,
          errors: null,
        },
      };

    case UPDATE_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        updateForgotPass: {
          ...state.updateForgotPass,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case UPDATE_FORGOT_PASSWORD_RESET:
      return {
        ...state,
        updateForgotPass: {
          ...state.updateForgotPass,
          success: false,
          error: false,
          errors: null,
        },
      };

    case GET_POLICY_SUCCESS:
      return {
        ...state,
        policy: {
          ...state.policy,
          success: true,
          error: false,
          errors: null,
          data: action.response,
        },
      };

    case GET_POLICY_ERROR:
      return {
        ...state,
        policy: {
          ...state.policy,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case GET_POLICY_RESET:
      return {
        ...state,
        policy: {
          ...state.policy,
          success: false,
          error: false,
          errors: null,
        },
      };

    // Check Terms

    case CHECK_TERMS_SUCCESS:
      return {
        ...state,
        checkTerms: {
          ...state.checkTerms,
          success: true,
          error: false,
          errors: null,
        },
      };

    case CHECK_TERMS_ERROR:
      return {
        ...state,
        checkTerms: { ...state.checkTerms, success: false, error: true },
      };

    case CHECK_TERMS_RESET:
      return {
        ...state,
        checkTerms: {
          ...state.checkTerms,
          success: false,
          error: false,
          errors: null,
        },
      };

    //  profile

    case MARKETING_USER_SUCCESS:
      return {
        ...state,
        marketinguser: {
          ...state.marketinguser,
          success: true,
          error: false,
          errors: null,
          data: action.response,
        },
      };

    case MARKETING_USER_ERROR:
      return {
        ...state,
        marketinguser: {
          ...state.marketinguser,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case MARKETING_USER_RESET:
      return {
        ...state,
        marketinguser: {
          ...state.marketinguser,
          success: false,
          error: false,
          errors: null,
        },
      };

    case CHECK_DIAMONDS_TYPE_SUCCESS:
      return {
        ...state,
        checkDiamondsType: {
          ...state.checkDiamondsType,
          success: true,
          error: false,
          errors: null,
          data: action.response,
        },
      };

    case CHECK_DIAMONDS_TYPE_ERROR:
      return {
        ...state,
        checkDiamondsType: {
          ...state.checkDiamondsType,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case CHECK_DIAMONDS_TYPE_RESET:
      return {
        ...state,
        checkDiamondsType: {
          ...state.checkDiamondsType,
          success: false,
          error: false,
          errors: null,
        },
      };

    case GET_VENDOR_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        vendorCountryList: {
          ...state.vendorCountryList,
          success: true,
          error: false,
          errors: null,
          data: action.response,
        },
      };

    case GET_VENDOR_COUNTRY_LIST_ERROR:
      return {
        ...state,
        vendorCountryList: {
          ...state.vendorCountryList,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case GET_VENDOR_COUNTRY_LIST_RESET:
      return {
        ...state,
        vendorCountryList: {
          ...state.vendorCountryList,
          success: false,
          error: false,
          errors: null,
        },
      };


    case VENDOR_REGISTER_SUCCESS:
      return {
        ...state,
        venderRegister: {
          ...state.venderRegister,
          success: true,
          error: false,
          errors: null,
          data: action.response,
        },
      };

    case VENDOR_REGISTER_ERROR:
      return {
        ...state,
        venderRegister: {
          ...state.venderRegister,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case VENDOR_REGISTER_RESET:
      return {
        ...state,
        venderRegister: {
          ...state.venderRegister,
          success: false,
          error: false,
          errors: null,
        },
      };




    case GET_MERCHANT_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        merchantCountryList: {
          ...state.merchantCountryList,
          success: true,
          error: false,
          errors: null,
          data: action.response,
        },
      };

    case GET_MERCHANT_COUNTRY_LIST_ERROR:
      return {
        ...state,
        merchantCountryList: {
          ...state.merchantCountryList,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case GET_MERCHANT_COUNTRY_LIST_RESET:
      return {
        ...state,
        merchantCountryList: {
          ...state.merchantCountryList,
          success: false,
          error: false,
          errors: null,
        },
      };


    case MERCHANT_REGISTER_SUCCESS:
      return {
        ...state,
        merchantRegister: {
          ...state.merchantRegister,
          success: true,
          error: false,
          errors: null,
          data: action.response,
        },
      };

    case MERCHANT_REGISTER_ERROR:
      return {
        ...state,
        merchantRegister: {
          ...state.merchantRegister,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case MERCHANT_REGISTER_RESET:
      return {
        ...state,
        merchantRegister: {
          ...state.merchantRegister,
          success: false,
          error: false,
          errors: null,
        },
      };



    default:
      return state;
  }
}


