import React, { useEffect, useState } from "react";
import Header from "./userLayout/Header";
import PairStoneTable from "./Tables/PairStoneTable";
import {
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import { getPairedStoneList } from "../userStore/action/PairedStoneListAction";
import {
  ADD_CONFIRM_STONE_LIST_RESET,
  ADD_HOLD_STONE_LIST_RESET,
  ADD_INQUIRY_LIST_RESET,
  ADD_VIEW_REQUEST_RESET,
  EXPORT_EXCEL_RESET,
  EXPORT_PDF_RESET,
} from "../userStore/common/constant";
import { exportExcel, exportPdf } from "../userStore/action/exportFileAction";
import { addInquiryList } from "../userStore/action/inquiryAction";
import { addHoldStoneList } from "../userStore/action/holdStoneAction";
import { addConfirmStoneList } from "../userStore/action/confirmStoneAction";
import ConfirmStoneModal from "./modals/ConfirmStoneModal";
import { addViewRequest } from "../userStore/action/viewRequestAction";
import { getSlotDetails } from "../userStore/action/masterAction";
import CompareStone from "./modals/CompareStone";
import { EmailExcelAction } from "../userStore/action/EmailExcelAction";

function PairedStoneList(props) {
  const [selected, setSelected] = useState({});
  const [showInquiry, setShowInquiry] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [ShowConfirmStone, setShowConfirmStone] = useState(false);
  const [comment, setComment] = useState("");
  const [remark, setremark] = useState("");

  const [details, setDetails] = useState([]);

  const [showRequest, setShowRequest] = useState(false);
  const [showSelected, setShowSelected] = useState(false);
  const [showComparison, setShowComparison] = useState(false);
  const [isHold, setIsHold] = useState(false);
  // const [IsDeleteCart, setIsDeleteCart] = useState(false);

  const diamondType = useSelector(
    (state) => state?.authReducer?.profile?.data?.diamond_type
  );

  const slotDetails = useSelector(
    (state) => state.masterReducer.slotDetails.data
  );

  const dayList = [
    {
      id: 0,
      value: "Sunday",
    },
    {
      id: 1,
      value: "Monday",
    },
    {
      id: 2,
      value: "Tuesday",
    },
    {
      id: 3,
      value: "Wednesday",
    },
    {
      id: 4,
      value: "Thursday",
    },
    {
      id: 5,
      value: "Friday",
    },
    {
      id: 6,
      value: "Saturday",
    },
  ];

  const [error, setError] = useState({
    comment: false,
    time: false,
    remark: false,
  });
  const [requestDetails, setRequestDetails] = useState({
    day: "",
    time: "",
  });

  useEffect(() => {
    if (!!showRequest && !props.slotDetailsReducer.success) {
      props.getSlotDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRequest]);

  useEffect(() => {
    if (Object.keys(selected).length !== 0) {
      const data = props.getPairedStoneListReducer.data;
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false
      });
      const _selected = idSelected.map((e) => {
        const list = data.find((data) => data.id === e);
        return list;
      });
      setDetails([..._selected]);
    } else {
      setDetails([]);
    }
  }, [selected, props.getPairedStoneListReducer.data]);

  useEffect(() => {
    if (props.createHoldStoneReducer.success) {
      const msg = props.createHoldStoneReducer.msg;
      const count = props.createHoldStoneReducer.count;
      const list = msg
        .split(", ")
        .map((e, i) => {
          if (e !== "" && i !== msg.split(", ") - 1) return `${e}<br />`;
          else if (e === "") {
            return false;
          } else return e;
        })
        .filter((e) => {
          return e !== undefined;
        });
      if (parseInt(count) !== 0) {
        toast.success("Selected Stone Added to hold Diamond table..");
      }
      !!msg &&
        toast.error(
          <div dangerouslySetInnerHTML={{ __html: list.join(",") }} />
        );
      props.resetAddHold();
      setIsHold(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createHoldStoneReducer]);

  useEffect(() => {
    if (props.createConfirmStoneReducer.success) {
      const msg = props.createConfirmStoneReducer.msg;
      const count = props.createConfirmStoneReducer.count;
      const list = msg
        .split(", ")
        .map((e, i) => {
          if (e !== "" && i !== msg.split(", ") - 1) return `${e}<br />`;
          else if (e === "") {
            return false;
          } else return e;
        })
        .filter((e) => {
          return e !== undefined;
        });
      if (parseInt(count) !== 0) {
        toast.success("Selected Stone Added to Confirm Diamond table..");
      }
      !!msg &&
        toast.error(
          <div dangerouslySetInnerHTML={{ __html: list.join(",") }} />
        );
      toast.success("Selected Stone Added to Confirm Diamond table..");
      setremark("");
      setShowConfirmStone(false);
      props.resetAddConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createConfirmStoneReducer]);

  useEffect(() => {
    if (props.createInquiryReducer.success) {
      toast.success("Inquiry Created..");
      setComment("");
      setShowInquiry(false);
      props.resetAddInquiry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createInquiryReducer]);

  useEffect(() => {
    if (props.createViewRequestReducer.success) {
      toast.success("Selected Stone Added to view request table..");
      setRequestDetails({
        day: "",
        time: "",
      });
      setShowRequest(false);
      props.resetAddRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createViewRequestReducer]);

  const SendEmail = () => {
    const data = props.getPairedStoneListReducer.data;
    const idSelected = Object.keys(selected).filter((e) => {
      if (selected[e] === true) {
        return e;
      } else return false
    });
    const stonIds = idSelected.map((e) => {
      const list = data.find((data) => data.id === e).stone_id;
      return list;
    });
    if (stonIds.length !== 0) {
      props.EmailExcelAction(stonIds);
    } else {
      toast.error("Please Select list..");
    }
  };

  const handleExportPdf = () => {
    if (props.getPairedStoneListReducer.success) {
      const data = props.getPairedStoneListReducer.data;
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false
      });
      const stonIds = idSelected.map((e) => {
        const list = data.find((data) => data.id === e).stone_id;
        return list;
      });
      if (stonIds.length !== 0) {
        props.exportPdf({
          details: stonIds,
          dt: diamondType,
        });
      } else {
        toast.error("Please Select list..");
      }
      props.resetExportPdf();
    }
  };

  const handleExportExcel = () => {
    if (props.getPairedStoneListReducer.success) {
      const data = props.getPairedStoneListReducer.data;
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false
      });
      const stonIds = idSelected.map((e) => {
        const list = data.find((data) => data.id === e).stone_id;
        return list;
      });
      if (stonIds.length !== 0) {
        props.exportExcel({
          details: stonIds,
          dt: diamondType,
        });
      } else {
        toast.error("Please Select list..");
      }
      props.resetExportExcel();
    }
  };

  const handleHoldCreate = () => {
    const idSelected = details.map((e) => {
      return e.id;
    });

    if (idSelected.length !== 0) {
      props.addHoldStoneList({
        product_id: JSON.stringify([...idSelected]),
      });
    } else {
      toast.error("select stone for add to hold list..");
    }
  };

  const handleSendConfirmStone = () => {
    if (remark === "") {
      setError((prev) => ({
        ...prev,
        remark: "Required",
      }));
    }

    if (remark !== "") {
      const idSelected = details.map((e) => {
        return e.id;
      });
      props.addConfirmStoneList({
        product_id: JSON.stringify([...idSelected]),
        remark: remark,
      });
    }
  };

  const handleSendInquiry = () => {
    if (comment === "") {
      setError((prev) => ({
        ...prev,
        comment: "Required",
      }));
    }

    if (comment !== "") {
      const idSelected = details.map((e) => {
        return e.id;
      });
      props.addInquiryList({
        product_id: JSON.stringify([...idSelected]),
        comment: comment,
      });
    }
  };

  const handleViewRequest = () => {
    if (requestDetails.time === "") {
      setError((pre) => ({
        ...pre,
        time: "Time Required",
      }));
    }

    if (requestDetails.time !== "") {
      const idSelected = details.map((e) => {
        return e.id;
      });
      props.addViewRequest({
        product_id: JSON.stringify([...idSelected]),
        slot_id: requestDetails.time,
      });
    }
  };

  return (
    <>
      <Header />
      <main className="userMain mt-3">
        <div className="d-flex justify-content-between  mt-5 mb-4 flex-wrap">
          <h2 className="heading mb-0"> Paired Stone List</h2>
          <div className="d-flex justify-content-between me-4">
            <div className="text-end mt-3 mt-sm-0 ms-auto navigate-buttons">
              {/* Send Inquiry */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Send Inquiry</Tooltip>}
              >
                <Button
                  variant="secondary"
                  className="me-sm-2 me-1"
                  onClick={() => {
                    const idSelected = Object.keys(selected).filter((e) => {
                      if (selected[e] === true) {
                        return e;
                      } else return false
                    });
                    if (idSelected.length !== 0) {
                      setShowInquiry(true);
                    } else {
                      toast.error("select stone for add Inquiry list..");
                    }
                  }}
                >
                  <i className="fa-solid fa-clipboard-question"></i>
                </Button>
              </OverlayTrigger>

              {/* Add to watch list */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">View Request</Tooltip>}
              >
                <Button
                  variant="secondary"
                  className="me-sm-2 me-1"
                  onClick={() => {
                    const idSelected = Object.keys(selected).filter((e) => {
                      if (selected[e] === true) {
                        return e;
                      } else return false
                    });

                    if (idSelected.length !== 0) {
                      setShowRequest(true);
                    } else {
                      toast.error("select stone for add view request list..");
                    }
                  }}
                >
                  <i className="fa-solid fa-clock"></i>
                </Button>
              </OverlayTrigger>

              {/* Show selected */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Show selected</Tooltip>}
              >
                <Button
                  variant="secondary"
                  className="me-sm-2 me-1"
                  onClick={() => setShowSelected(!showSelected)}
                >
                  <i className="fa-solid fa-clipboard-list"></i>
                </Button>
              </OverlayTrigger>

              {/* Stone Comparison */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Stone Comparison</Tooltip>}
              >
                <Button
                  variant="secondary"
                  onClick={() => {
                    const idSelected = Object.keys(selected).filter((e) => {
                      if (selected[e] === true) {
                        return e;
                      } else return false
                    });
                    if (idSelected.length !== 0) {
                      setShowComparison(true);
                    } else {
                      toast.error("select stone for compair stone details..");
                    }
                  }}
                  className="me-sm-2 me-1"
                >
                  <i className="fa-solid fa-scale-balanced"></i>
                </Button>
              </OverlayTrigger>

              {/* Print PDF */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Confirm Stone</Tooltip>}
              >
                {/* <Button
                  variant="secondary"
                  onClick={handleConfirmCreate}
                  className="me-2"
                >
                  <i className="fa-solid fa-circle-check"></i>
                </Button> */}
                <Button
                  variant="secondary"
                  className="me-sm-2 me-1"
                  onClick={() => {
                    const idSelected = Object.keys(selected).filter((e) => {
                      if (selected[e] === true) {
                        return e;
                      } else return false
                    });
                    if (idSelected.length !== 0) {
                      setShowConfirmStone(true);
                    } else {
                      toast.error("select stone for add Confirm Stone..");
                    }
                  }}
                >
                  <i className="fa-solid fa-clipboard-question"></i>
                </Button>
              </OverlayTrigger>

              {/* Download Excel */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Hold Stone</Tooltip>}
              >
                <Button
                  variant="secondary"
                  className="me-2"
                  onClick={() => {
                    setIsHold(true);
                  }}
                >
                  <i className="fa-regular fa-hourglass-half"></i>
                </Button>
              </OverlayTrigger>

              {/* Email Excel */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Email Excel</Tooltip>}
              >
                <Button
                  variant="secondary"
                  className="me-sm-2 me-1"
                  onClick={SendEmail}
                >
                  <i className="fa-solid fa-envelope-open-text"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Export PDF</Tooltip>}
              >
                <Button
                  variant="secondary"
                  className="me-sm-2 me-1"
                  onClick={handleExportPdf}
                >
                  <i className="fa-solid fa-file-pdf"></i>{" "}
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Export Excel</Tooltip>}
              >
                <Button
                  variant="secondary"
                  className="me-sm-2 me-1"
                  onClick={handleExportExcel}
                >
                  <i className="fa-solid fa-file-excel"></i>
                </Button>
              </OverlayTrigger>
            </div>
          </div>
        </div>

        <div className="content-block mt-3">
          <PairStoneTable
            setSelected={setSelected}
            showSelected={showSelected}
          />
        </div>
      </main>

      <Modal
        show={isHold}
        size="md"
        backdrop="static"
        onHide={() => setIsHold(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="p-4">
          <div className="">
            <div className="text-center my-5">
              <h5>Are you sure you want to hold stone ?</h5>
            </div>
            <Modal.Footer className="mt-3">
              <Button
                className="py-2 px-4 border rounded pointer bg-secondary text-white mx-2 pointer"
                onClick={() => {
                  setIsHold(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="py-2 px-4 border rounded pointer bg-primary text-white mx-2 pointer"
                onClick={handleHoldCreate}
              >
                Hold
              </Button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>

      <Modal
        show={ShowConfirmStone}
        onHide={() => {
          setShowConfirmStone(false);
          setremark("");
          error["remark"] = false;
        }}
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Stone</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="mb-3">
              <label>Remark</label>
              <textarea
                name="name"
                placeholder="Enter Remark"
                className="form-control"
                value={remark}
                rows={3}
                maxLength={"256"}
                onChange={(e) => {
                  setremark(e.target.value);
                }}
              ></textarea>

              <div className="d-flex justify-content-between align-items-center">
                {error.remark ? (
                  <span className="form-error">{error.remark}</span>
                ) : (
                  <span></span>
                )}
                <span
                  className="text-end text-muted mt-2"
                  style={{ fontSize: "14px" }}
                >
                  {256 - remark.length} characters left
                </span>
              </div>
            </Col>
          </Row>
          <Button
            className="btn btn-primary text-white ms-auto d-block"
            onClick={handleSendConfirmStone}
            type="submit"
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showInquiry}
        onHide={() => {
          setShowInquiry(false);
          setComment("");
          error["comment"] = false;
        }}
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Inquiry List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="mb-3">
              <label>Comment</label>
              <textarea
                name="name"
                placeholder="Enter comment"
                className="form-control"
                value={comment}
                rows={3}
                maxLength={"256"}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              ></textarea>

              <div className="d-flex justify-content-between align-items-center">
                {error.comment ? (
                  <span className="form-error">{error.comment}</span>
                ) : (
                  <span></span>
                )}
                <span
                  className="text-end text-muted mt-2"
                  style={{ fontSize: "14px" }}
                >
                  {256 - comment.length} characters left
                </span>
              </div>
            </Col>
          </Row>
          <Button
            className="btn btn-primary text-white ms-auto d-block"
            onClick={handleSendInquiry}
            type="submit"
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>

      {showRequest && props.slotDetailsReducer.success && (
        <>
          <Modal
            show={showRequest}
            onHide={() => {
              setShowRequest(false);
            }}
            backdrop={"static"}
          >
            <Modal.Header closeButton>
              <Modal.Title>Diamond Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={12} className="mb-3">
                  <label>Day</label>
                  <select
                    id="day"
                    className="form-control mb-3"
                    value={requestDetails.day}
                    onChange={(e) => {
                      setRequestDetails((pre) => ({
                        ...pre,
                        day: e.target.value,
                      }));
                    }}
                    name="location"
                  >
                    <option value={""}>Select Day</option>
                    {dayList?.map((data, i) => {
                      return (
                        <option key={i} value={data.id}>
                          {data.value}
                        </option>
                      );
                    })}
                  </select>
                  {error.comment && (
                    <span className="form-error">{error.comment}</span>
                  )}
                </Col>
                {requestDetails.day !== "" &&
                  slotDetails?.filter(
                    (e) => parseInt(e.day) === parseInt(requestDetails.day)
                  ).length !== 0 ? (
                  <>
                    <Col md={12} className="mb-3">
                      <label>Time</label>
                      <select
                        id="day"
                        className="form-control mb-3"
                        value={requestDetails.time}
                        onChange={(e) => {
                          setRequestDetails((pre) => ({
                            ...pre,
                            time: e.target.value,
                          }));
                        }}
                        name="time"
                      >
                        <option value={""}>Select Time Slot</option>
                        {!!slotDetails &&
                          slotDetails
                            ?.filter(
                              (e) =>
                                parseInt(e.day) === parseInt(requestDetails.day)
                            )
                            ?.map((data, i) => {
                              return (
                                <option key={i} value={data.id}>
                                  {data.from_time} - {data.to_time}
                                </option>
                              );
                            })}
                      </select>
                      {error.time && (
                        <span className="form-error">{error.time}</span>
                      )}
                    </Col>
                  </>
                ) : (
                  <span className="text-center"> No Time Slot available</span>
                )}
              </Row>
              <Button
                className="btn btn-primary text-white ms-auto d-block"
                onClick={handleViewRequest}
                type="submit"
              >
                Submit
              </Button>
            </Modal.Body>
          </Modal>
        </>
      )}

      {showComparison && (
        <CompareStone
          show={showComparison}
          onHide={() => {
            setShowComparison(false);
          }}
          data={details.map((e) => e)}
        />
      )}

      <ConfirmStoneModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  getPairedStoneListReducer: state.pairedStoneListReducer.getPairedStoneList,
  profileReducer: state.authReducer.profile,
  slotDetailsReducer: state.masterReducer.slotDetails,
  createInquiryReducer: state.inquiryReducer.createInquiry,
  createViewRequestReducer: state.viewRequestReducer.createViewRequest,
  createConfirmStoneReducer: state.confirmStoneReducer.createConfirmStone,
  createHoldStoneReducer: state.holdStoneReducer.createHoldStone,
});
const mapDispatchToProps = (dispatch) => ({
  getPairedStoneList: (details) => dispatch(getPairedStoneList(details)),
  EmailExcelAction: (Email) => dispatch(EmailExcelAction(Email)),
  exportPdf: (details) => dispatch(exportPdf(details)),
  exportExcel: (details) => dispatch(exportExcel(details)),
  addViewRequest: (details) => dispatch(addViewRequest(details)),
  getSlotDetails: (details) => dispatch(getSlotDetails(details)),
  addInquiryList: (details) => dispatch(addInquiryList(details)),
  addHoldStoneList: (details) => dispatch(addHoldStoneList(details)),
  addConfirmStoneList: (details) => dispatch(addConfirmStoneList(details)),
  resetAddInquiry: () => dispatch({ type: ADD_INQUIRY_LIST_RESET }),
  resetAddRequest: () => dispatch({ type: ADD_VIEW_REQUEST_RESET }),
  resetAddHold: () => dispatch({ type: ADD_HOLD_STONE_LIST_RESET }),
  resetAddConfirm: () => dispatch({ type: ADD_CONFIRM_STONE_LIST_RESET }),
  resetExportPdf: () => dispatch({ type: EXPORT_PDF_RESET }),
  resetExportExcel: () => dispatch({ type: EXPORT_EXCEL_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PairedStoneList);
