import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getPairedStoneList } from "../../userStore/action/PairedStoneListAction";
import { getter, groupBy } from "@progress/kendo-data-query";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import Loader from "../../Components/Loader";
import StonePreviewModal from "../modals/StonePreviewModal";
import StoneVideoPreviewModal from "../modals/StoneVideoPreviewModal";
import PdfModal from "../modals/PdfModal";
import { toast } from "react-toastify";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

function PairStoneTable(props) {
  const profileData = props.profileReducer.data;
  const [isFirst, setIsFirst] = useState(true);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imgShow, setImgShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [videoShow, setVideoShow] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [certificateShow, setCertificateShow] = useState(false);
  const [selectedCert, setSelectedCert] = useState("");
  const [selectedState, setSelectedState] = React.useState({});
  const [dataState, setDataState] = React.useState(
    state.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    )
  );

  useEffect(() => {
    props.setSelected(selectedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  useEffect(() => {
    const list = state.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    );
    setDataState([...list]);
  }, [state]);

  useEffect(() => {
    props.getPairedStoneList();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.getPairedStoneListReducer.success) {
      const data = props.getPairedStoneListReducer.data;
      const list = data.map((e, i) => {
        return {
          image: e.image ? e.image : "N/A",
          video: e.video ? e.video : "N/A",
          cert_url: e.cert_url ? e.cert_url : "N/A",
          stone_id: e.stone_id ? e.stone_id : "N/A",
          cert: e.cert ? e.cert : "N/A",
          cert_no: e.cert_no ? e.cert_no : "N/A",
          country: e.country ? e.country : "N/A",
          growth_type: e.growth_type ? e.growth_type : "N/A",
          shape: e.shape_name ? e.shape_name : "N/A",
          color: e.color_name ? e.color_name : "N/A",
          clarity: e.clarity_name ? e.clarity_name : "N/A",
          cut: e.cut_name ? e.cut_name : "N/A",
          polish: e.polish_name ? e.polish_name : "N/A",
          symmetry: e.symmetry_name ? e.symmetry_name : "N/A",
          rapRate: e.rapo_rate ? e.rapo_rate : "N/A",
          disc: e.discount ? e.discount : "N/A",
          carat: e.carat ? e.carat : "N/A",
          amount: e.amount ? e.amount : "N/A",
          measurement: e.measurement ? e.measurement : "N/A",
          ratio: e.ratio ? e.ratio : "N/A",
          tabel_per: e.table_per ? e.table_per : "N/A",
          depth_per: e.depth_per ? e.depth_per : "N/A",
          bgm: e.bgm_name ? e.bgm_name : "N/A",
          fluorescence: e.fluorescence_name ? e.fluorescence_name : "N/A",
          fancy_color: e.colors_name ? e.colors_name : "N/A",
          key_to_symbols: !!e.key_to_symbols ? e.key_to_symbols : "N/A",
          id: e?.id,
          selected: false,
          product_id: e.product_id ? e.product_id : "N/A",
          pair: e.pair ? e.pair : "N/A",
          rate: e.rate ? e.rate : "N/A",
          Overtone: e.overtone_name ? e.overtone_name : "N/A",
          intensity: e.intensity_name ? e.intensity_name : "N/A",

          // // size: e.size_name ? e.size_name : "N/A",
          // Overtone: e.overtone_name ? e.overtone_name : "N/A",
          // intensity: e.intensity_name
          //   ? e.intensity_name
          //   : "N/A",
          // fluorescence_color: e.fluorescence_color_name
          //   ? e.fluorescence_color_name
          //   : "N/A",
          // table: e.table ? e.table : "N/A",
          // depth: e.depth ? e.depth : "N/A",
          // length: e.length ? e.length : "N/A",
          // width: e.width ? e.width : "N/A",
          // height: e.height ? e.height : "N/A",
          // milky: e.milky ? e.milky : "N/A",
          // shade: e.shade ? e.shade : "N/A",
          // crown_angle: e.crown_angle ? e.crown_angle : "N/A",
          // crown_height: e.crown_height
          //   ? e.crown_height
          //   : "N/A",
          // crown_open: e.crown_open ? e.crown_open : "N/A",
          // pavilion_angle: e.pavilion_angle
          //   ? e.pavilion_angle
          //   : "N/A",
          // pavilion_height: e.pavilion_height
          //   ? e.pavilion_height
          //   : "N/A",
          // pavilion_open: e.pavilion_open
          //   ? e.pavilion_open
          //   : "N/A",
          // white_table: e.white_table ? e.white_table : "N/A",
          // white_side: e.white_side ? e.white_side : "N/A",
          // table_black: e.table_black ? e.table_black : "N/A",
          // side_black: e.side_black ? e.side_black : "N/A",
          // table_open: e.table_open ? e.table_open : "N/A",
          // girdle: e.girdle ? e.girdle : "N/A",
          // girdle_desc: e.girdle_desc ? e.girdle_desc : "N/A",
          // culet: e.culet ? e.culet : "N/A",
          // h_a: e.h_a ? e.h_a : "N/A",
          // eye_clean: e.eye_clean ? e.eye_clean : "N/A",
        };
      });
      setState([...list]);
      setLoading(false);
    } else if (props.getPairedStoneListReducer.error) {
      setLoading(false);
    }
  }, [props.getPairedStoneListReducer]);

  useEffect(() => {
    if (!!props.getPairedStoneListReducer.data) {
      const data = props.getPairedStoneListReducer.data;
      const idSelected = Object.keys(selectedState).filter((e) => {
        if (selectedState[e] === true) {
          return e;
        } else return false
      });
      if (idSelected.length !== 0) {
        const filterData = props.showSelected
          ? data?.filter((e) => {
              if (idSelected.includes(e.id.toString())) {
                return e;
              } else return false;
            })
          : data;
        if (filterData.length !== 0) {
          const list = filterData.map((e, i) => {
            return {
              image: !!e.image && e.image !== "" ? e.image : "N/A",
              video: !!e.video && e.video !== "" ? e.video : "N/A",
              cert_url: !!e.cert_url && e.cert_url !== "" ? e.cert_url : "N/A",
              stone_id: !!e.stone_id && e.stone_id !== "" ? e.stone_id : "N/A",
              cert: !!e.cert && e.cert !== "" ? e.cert : "N/A",
              cert_no: !!e.cert_no && e.cert_no !== "" ? e.cert_no : "N/A",
              country: !!e.country && e.country !== "" ? e.country : "N/A",
              growth_type:
                !!e.growth_type && e.growth_type !== "" ? e.growth_type : "N/A",
              shape:
                !!e.shape_name && e.shape_name !== "" ? e.shape_name : "N/A",
              color:
                !!e.color_name && e.color_name !== "" ? e.color_name : "N/A",
              clarity:
                !!e.clarity_name && e.clarity_name !== ""
                  ? e.clarity_name
                  : "N/A",
              cut: !!e.cut_name && e.cut_name !== "" ? e.cut_name : "N/A",
              polish:
                !!e.polish_name && e.polish_name !== "" ? e.polish_name : "N/A",
              symmetry:
                !!e.symmetry_name && e.symmetry_name !== ""
                  ? e.symmetry_name
                  : "N/A",
              rapRate:
                !!e.rapo_rate && e.rapo_rate !== "" ? e.rapo_rate : "N/A",
              disc: !!e.discount && e.discount !== "" ? e.discount : "N/A",
              carat: !!e.carat && e.carat !== "" ? e.carat : "N/A",
              amount: !!e.amount && e.amount !== "" ? e.amount : "N/A",
              measurement:
                !!e.measurement && e.measurement !== "" ? e.measurement : "N/A",
              ratio: !!e.ratio && e.ratio !== "" ? e.ratio : "N/A",
              tabel_per:
                !!e.table_per && e.table_per !== "" ? e.table_per : "N/A",
              depth_per:
                !!e.depth_per && e.depth_per !== "" ? e.depth_per : "N/A",
              bgm: !!e.bgm_name && e.bgm_name !== "" ? e.bgm_name : "N/A",
              fluorescence:
                !!e.fluorescence_name && e.fluorescence_name !== ""
                  ? e.fluorescence_name
                  : "N/A",
              fancy_color:
                !!e.colors_name && e.colors_name !== "" ? e.colors_name : "N/A",
              key_to_symbols:
                !!e.key_to_symbols && e.key_to_symbols !== ""
                  ? e.key_to_symbols
                  : "N/A",
              product_id: e.product_id,
              id: e.id,
              selected: false,
              pair: e.pair,
              rate: !!e.rate && e.rate !== "" ? e.rate : "N/A",
              Overtone:
                !!e.overtone_name && e.overtone_name !== ""
                  ? e.overtone_name
                  : "N/A",
              intensity:
                !!e.intensity_name && e.intensity_name !== ""
                  ? e.intensity_name
                  : "N/A",

              // // size: e.size_name,
              // Overtone: e.overtone_name,
              // intensity: e.intensity_name,

              // fluorescence_color: e.fluorescence_color_name,

              // table: e.table,
              // depth: e.depth,
              // length: e.length,
              // width: e.width,
              // height: e.height,
              // milky: e.milky,
              // shade: e.shade,
              // crown_angle: e.crown_angle,
              // crown_height: e.crown_height,

              // crown_open: e.crown_open,
              // pavilion_angle: e.pavilion_angle,

              // pavilion_height: e.pavilion_height,

              // pavilion_open: e.pavilion_open,

              // white_table: e.white_table,
              // white_side: e.white_side,
              // table_black: e.table_black,
              // side_black: e.side_black,
              // table_open: e.table_open,
              // girdle: e.girdle,
              // girdle_desc: e.girdle_desc,
              // culet: e.culet,

              // h_a: e.h_a,
              // eye_clean: e.eye_clean,
            };
          });
          setState(list);
        }
      } else {
        if (isFirst) {
          setIsFirst(false);
        } else {
          toast.error(
            "There are no selected stone please select stone first..."
          );
        }
      }
    } else {
      if (isFirst) {
        setIsFirst(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showSelected]);

  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const CustomCell = (cellProps) => {
    if (cellProps.rowType !== "groupHeader") {
      return (
        <td
          className="k-grid-content-sticky"
          style={{ left: "50px", right: "0px" }}
        >
          <button
            className="btn btn-sm p-0 me-2"
            onClick={() => {
              setSelectedImage(cellProps.dataItem.image);
              setImgShow(true);
            }}
          >
            <i className="fa-solid fa-camera-retro"></i>
          </button>
          <button
            className="btn btn-sm p-0 me-2"
            onClick={() => {
              setSelectedVideo(cellProps.dataItem.video);
              setVideoShow(true);
            }}
          >
            <i className="fa-solid fa-video"></i>
          </button>
          <button
            className="btn btn-sm p-0 me-2"
            onClick={() => {
              setSelectedCert(cellProps.dataItem.cert_url);
              setCertificateShow(true);
            }}
          >
            <i className="fa-solid fa-certificate"></i>
          </button>
          {/* <div
            className="btn btn-sm p-0"
            onClick={() => {
              navigate(`/detail/${cellProps.dataItem.product_id}`, {
                state: {
                  path: location.pathname,
                },
              });
            }}
          >
            <i className="fa-solid fa-eye"></i>
          </div> */}
        </td>
      );
    }
  };

  const MyCustomCell = (props) => <CustomCell {...props} />;

  const grid = (
    <Grid
      style={{
        height: "70vh",
      }}
      data={groupBy(
        state.map((item) => ({
          ...item,
          [SELECTED_FIELD]: selectedState[idGetter(item)],
        })),
        [
          {
            field: "pair",
          },
        ]
      )}
      // skip={state.skip}
      // take={state.take}
      // total={state.total}
      // pageable={state.pageable}
      // pageSize={state.pageSize}
      // onPageChange={pageChange}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      selectable={{
        enabled: true,
        drag: false,
        cell: false,
        mode: "multiple",
      }}
      group={[
        {
          field: "pair",
        },
      ]}
      onSelectionChange={onSelectionChange}
      onHeaderSelectionChange={onHeaderSelectionChange}
    >
      {/* <GridColumn field="view">sldkjklf</GridColumn> */}
      <GridColumn
        locked={true}
        field={SELECTED_FIELD}
        width="50px"
        headerSelectionValue={
          dataState.length === 0
            ? false
            : dataState.findIndex((item) => !selectedState[idGetter(item)]) ===
              -1
        }
      />
      <GridColumn
        field="pair"
        width="40"
        // groupable={true}
        // cell={MyCustomCell}
      />
      <GridColumn field="Action" width="88" locked={true} cell={MyCustomCell} />
      <GridColumn field="stone_id" title="Stone ID" width="75" />
      <GridColumn field="cert_no" title="Certificate No" width="105" />

      <GridColumn field="shape" title="Shape" width="55" />
      <GridColumn field="carat" title="Carat" width="45" />
      <GridColumn field="color" title="Color" width="45" />
      <GridColumn field="fancy_color" title="Fancy Color" width="90" />
      <GridColumn field="intensity" title="Intensity" width="70" />
      <GridColumn field="Overtone" title="Overtone" width="70" />
      <GridColumn field="clarity" title="Clarity" width="55" />
      <GridColumn field="cut" title="Cut" width="40" />
      <GridColumn field="polish" title="Poilish" width="55" />
      <GridColumn field="symmetry" title="Symmetry" width="80" />
      <GridColumn field="rapRate" title="Rap Rate" width="70" />
      <GridColumn field="rate" title="$/CT" width="45" />
      <GridColumn field="amount" title="$ Total" width="65" />
      <GridColumn field="measurement" title="Measurement" width="105" />
      <GridColumn field="ratio" title="Ratio" width="50" />
      <GridColumn field="depth_per" title="Depth Per" width="78" />
      <GridColumn field="tabel_per" title="Table Per" width="70" />
      <GridColumn field="bgm" title="BGM" width="50" />
      <GridColumn field="fluorescence" title="Fluorescence" width="100" />

      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="key_to_symbols" title="Key of Symbols" width="150" />
      )}

      <GridColumn field="cert" title=" Certificate" width="80" />
      <GridColumn field="country" title="Country" width="65" />
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="growth_type" title="Growth Type" width="95" />
      )}

      {/* <GridColumn field="rate" title="Rate $/CT" width="100" />
      {!!profileData && profileData.diamond_type === 2 && (
        
      )}
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="size" title="Size" width="100" />
      )}
      {!!profileData && profileData.diamond_type === 2 && (
        
      )}
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn
          field="fluorescence_color"
          title="Fluorescence Color"
          width="80"
        />
      )}
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="table" title="Table" width="100" />
      )}
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="depth" title="Depth" width="100" />
      )}

      <GridColumn field="length" title="Length" width="80" />
      <GridColumn field="width" title="Width" width="80" />
      <GridColumn field="height" title="Height" width="80" />
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="milky" title="Milky" width="100" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="shade" title="Shade" width="80" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="crown_angle" title="Crown Angle" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="crown_height" title="Crown Height" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="crown_open" title="Crown Open" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="pavilion_angle" title="Pavilion Angle" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn
          field="pavilion_height"
          title="Pavilion Height"
          width="120"
        />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="pavilion_open" title="Pavilion Open" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="white_table" title="White Table" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="white_side" title="White Side" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="table_black" title="Table Black" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="side_black" title="Side Black" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="table_open" title="Table Open" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="girdle" title="Girdle" width="80" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="girdle_desc" title="Girdle Desc" width="120" />
      )}
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="culet" title="Culet" width="80" />
      )}
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="pair" title="Pair" width="160" />
      )}
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="h_a" title="H & A" width="160" />
      )}
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="eye_clean" title="Eye Clean" width="160" />
      )} */}
    </Grid>
  );

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {grid}
          {imgShow && (
            <StonePreviewModal
              show={imgShow}
              onHide={() => {
                setImgShow(false);
                setSelectedImage("");
              }}
              details={selectedImage}
            />
          )}
          {videoShow && (
            <StoneVideoPreviewModal
              show={videoShow}
              onHide={() => {
                setVideoShow(false);
                setSelectedVideo("");
              }}
              details={selectedVideo}
            />
          )}
          {certificateShow && (
            <PdfModal
              show={certificateShow}
              onHide={() => {
                setCertificateShow(false);
                setSelectedCert("");
              }}
              details={selectedCert}
            />
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  getPairedStoneListReducer: state.pairedStoneListReducer.getPairedStoneList,
  profileReducer: state.authReducer.profile,
});
const mapDispatchToProps = (dispatch) => ({
  getPairedStoneList: (details) => dispatch(getPairedStoneList(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PairStoneTable);
