import { GET_PAIRED_STONE_LIST_ERROR, GET_PAIRED_STONE_LIST_RESET, GET_PAIRED_STONE_LIST_SUCCESS } from "../common/constant";

let initialState = {
 getPairedStoneList: {
  error: false,
  data: null,
  errors: null,
  success: false,
 }
};

export default function pairedStoneListReducer(state = initialState, action) {
 switch (action.type) {
  case GET_PAIRED_STONE_LIST_SUCCESS:
   return { ...state, getPairedStoneList: { ...state.getPairedStoneList, success: true, error: false, errors: null, data: action.data }, };

  case GET_PAIRED_STONE_LIST_ERROR:
   return { ...state, getPairedStoneList: { ...state.getPairedStoneList, success: false, error: true, errors: action.msg } };

  case GET_PAIRED_STONE_LIST_RESET:
   return { ...state, getPairedStoneList: { ...state.getPairedStoneList, success: false, error: false, errors: null } };


  default:
   return state;
 }
}