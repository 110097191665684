import React, { useEffect, useState } from "react";
import { getSelectedState, Grid, GridColumn } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-data-query";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {  deleteSaveSearchList,  getSaveSearchList} from "../../userStore/action/saveSearchList";
import { getMastersList } from "../../userStore/action/masterAction";
import {  ADD_SAVE_SEARCH_RESET,  DELETE_SAVE_SEARCH_RESET} from "../../userStore/common/constant";
import moment from "moment";
import Loader from "../../Components/Loader";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const SavedSearchTable = (props) => {
  const [loading, setLoading] = useState(true);
  const [masters, setMasters] = useState({});
  const [details, setDetails] = useState([]);
  const navigate = useNavigate();
  const createState = (skip, take) => {
    let pagerSettings = {
      buttonCount: 5,
      info: true,
      type: "numeric",
      pageSizes: true,
      previousNext: true,
    };
    return {
      items: details.slice(skip, skip + take),
      total: details.length,
      skip: skip,
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const [state, setState] = React.useState(createState(0, 10));
  const [selectedState, setSelectedState] = React.useState({});
  
  useEffect(() => {
    if (!props.masterListReducer.success) {
      props.getMastersList();
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.setSelected(selectedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  useEffect(() => {
    if (props.masterListReducer.success) {
      const data = props.masterListReducer.data;
      setMasters({ ...data });
      setLoading(false);
    }
  }, [props.masterListReducer]);

  useEffect(() => {
    if (masters !== {}) {
      const _details = details.map((e, i) => {
        return {
          size:
            !e.search?.size_id || e.search?.size_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.size_id)?.map((e) => {
                  const list = masters.Size.find(
                    (item) => parseInt(item.id) === parseInt(e)
                  );
                  return list.name;
                }),
          carat_from:
            !e.search?.carat_from || e.search?.carat_from?.length === 0
              ? "N/A"
              : e.search?.carat_from,
          carat_to:
            !e.search?.carat_to || e.search?.carat_to?.length === 0
              ? "N/A"
              : e.search?.carat_to,
          growth_type:
            !e.search?.growth_type ||
            JSON.parse(e.search?.growth_type)?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.growth_type)?.map((e) => {
                  return masters?.Type[e];
                }),
          shape:
            !e.search?.shape_id || e.search?.shape_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.shape_id)?.map((e) => {
                  const list = masters?.Shape?.find(
                    (item) => parseInt(item.id) === parseInt(e)
                  );
                  return list?.name;
                }),
          color:
            !e.search?.color_id || e.search?.color_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.color_id)?.map((e) => {
                  const list = masters.Color.find(
                    (item) => parseInt(item.id) === parseInt(e)
                  );
                  return list.name;
                }),
          fancy_color:
            !e.search?.colors_id || e.search?.colors_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.colors_id)?.map((e) => {
                  const list = masters.FancyColor.find(
                    (item) => parseInt(item.id) === parseInt(e)
                  );
                  return list.name;
                }),
          Overtone:
            !e.search?.overtone_id || e.search?.overtone_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.overtone_id)?.map((e) => {
                  const list = masters.FancyColor.find(
                    (item) => parseInt(item.id) === parseInt(e)
                  );
                  return list.name;
                }),
          intensity:
            !e.search?.intensity_id || e.search?.intensity_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.intensity_id)?.map((e) => {
                  const list = masters.FancyColor.find(
                    (item) => parseInt(item.id) === parseInt(e)
                  );
                  return list.name;
                }),
          clarity:
            !e.search?.clarity_id || e.search?.clarity_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.clarity_id)?.map((e) => {
                  const list = masters.Clarity.find(
                    (item) => parseInt(item.id) === parseInt(e)
                  );
                  return list?.name;
                }),
          cut:
            !e.search?.cut_id || e.search?.cut_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.cut_id)?.map((e) => {
                  const list = masters.Finish.find(
                    (item) => parseInt(item.id) === parseInt(e)
                  );
                  return list.name;
                }),
          polish:
            !e.search?.polish_id ||
            JSON.parse(e.search?.polish_id)?.length === 0
              ? "N/A"
              : JSON.parse(e?.search?.polish_id)?.map((e) => {
                  const list = masters.Finish.find(
                    (item) => parseInt(item.id) === parseInt(e)
                  );
                  return list.name;
                }),
          symmetry:
            !e.search?.symmetry_id ||
            JSON.parse(e.search?.symmetry_id)?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.symmetry_id)?.map((e) => {
                  const list = masters.Finish.find(
                    (item) => parseInt(item.id) === parseInt(e)
                  );
                  return list.name;
                }),
          fluorescence:
            !e.search?.fluorescence_id ||
            JSON.parse(e.search?.fluorescence_id)?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.fluorescence_id)?.map((e) => {
                  const list = masters.Fluorescence.find(
                    (item) => parseInt(item.id) === parseInt(e)
                  );
                  return list.name;
                }),

          disc_from:
            !e.search?.discount_from || e.search?.discount_from?.length === 0
              ? "N/A"
              : e.search?.discount_from,
          disc_to:
            !e.search?.discount_to || e.search?.discount_to?.length === 0
              ? "N/A"
              : e.search?.discount_to,

          table_from:
            !e.search?.table_from || e.search?.table_from?.length === 0
              ? "N/A"
              : e.search?.table_from,
          table_to:
            !e.search?.table_to || e.search?.table_to?.length === 0
              ? "N/A"
              : e.search?.table_to,

          depth_from:
            !e.search?.depth_from || e.search?.depth_from?.length === 0
              ? "N/A"
              : e.search?.depth_from,
          depth_to:
            !e.search?.depth_to || e.search?.depth_to?.length === 0
              ? "N/A"
              : e.search?.depth_to,

          ratio_from:
            !e.search?.ratio_from || e.search?.ratio_from?.length === 0
              ? "N/A"
              : e.search?.ratio_from,
          ratio_to:
            !e.search?.ratio_to || e.search?.ratio_to?.length === 0
              ? "N/A"
              : e.search?.ratio_to,

          length_from:
            !e.search?.length_from || e.search?.length_from?.length === 0
              ? "N/A"
              : e.search?.length_from,
          length_to:
            !e.search?.length_to || e.search?.length_to?.length === 0
              ? "N/A"
              : e.search?.length_to,

          width_from:
            !e.search?.width_from || e.search?.width_from?.length === 0
              ? "N/A"
              : e.search?.width_from,
          width_to:
            !e.search?.width_to || e.search?.width_to?.length === 0
              ? "N/A"
              : e.search?.width_to,

          stone_id: !!e.search.stone_id ? e.search.stone_id : "N/A",

          qty: !!e.quantity ?? "N/A",
          demand_name: !!e.name,
          demand_date: moment(e.created_at).format("DD/MM/yyyy HH:mm:ss A"),
          search_name: e.name,
          search_date: moment(e.created_at).format("DD/MM/yyyy HH:mm:ss A"),
          status: e.status,
          id: e.id,
        };
      });
      setState((prev) => ({
        ...prev,
        items: [..._details],
      }));
    }
  }, [details, masters, state.pageSize, state.skip]);
  useEffect(() => {
    props.getSaveSearchList();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.deleteSaveSearchReducer.success) {
      props.getSaveSearchList();
      props.resetDelete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deleteSaveSearchReducer]);

  useEffect(() => {
    if (props.getSaveSearchListReducer.success) {
      const data = props.getSaveSearchListReducer.data;

      const list = data.map((e) => {
        return {
          ...e,
          search: JSON.parse(e.search),
        };
      });
      setDetails([...list]);
      setLoading(false);
    }
  }, [props.getSaveSearchListReducer]);

  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const pageChange = (event) => {
    setState(createState(event.page.skip, event.page.take));
  };
  const CustomCell = (cellProps) => {
    return (
      <td
        className="k-grid-content-sticky"
        style={{ left: "50px", right: "0px" }}
      >
        <div
          className="btn btn-sm p-0 "
          onClick={() => {
            props.deleteSaveSearchList({
              search_id: JSON.stringify([cellProps.dataItem.id]),
            });
          }}
        >
          <i className="fa-solid fa-trash"></i>
        </div>
        <div
          className="btn btn-sm p-0 ms-3"
          onClick={() => {
            const id = cellProps.dataItem.id;

            const list = details.find((e) => {
              return id === e.id;
            }).search;

            for (const key of Object.keys(list)) {
              if (
                key === "location" ||
                key === "shape_id" ||
                key === "size_id" ||
                key === "color_id" ||
                key === "colors_id" ||
                key === "overtone_id" ||
                key === "intensity_id" ||
                key === "clarity_id" ||
                key === "cut_id" ||
                key === "polish_id" ||
                key === "symmetry_id" ||
                key === "fluorescence_id" ||
                key === "fluorescence_color_id" ||
                key === "bgm_id" ||
                key === "lab" ||
                key === "diamond_type"
              ) {
                if (typeof list[key] === "string") {
                  list[key] = JSON.parse(list[key]);
                } else {
                  list[key] = [];
                }
              } else if (
                key === "carat_from" ||
                key === "carat_to" ||
                key === "carat_to"
              ) {
                if (typeof list[key] === "string") {
                  list[key] = JSON.parse(list[key]);
                } else {
                  list[key] = [""];
                }
              } else if (
                key === "stone_id" ||
                key === "ct_from" ||
                key === "ct_to" ||
                key === "discount_from" ||
                key === "discount_to" ||
                key === "value_from" ||
                key === "value_to" ||
                key === "length_from" ||
                key === "length_to" ||
                key === "width_from" ||
                key === "width_to" ||
                key === "height_from" ||
                key === "height_to" ||
                key === "table_from" ||
                key === "table_to" ||
                key === "depth_from" ||
                key === "depth_to" ||
                key === "ratio_from" ||
                key === "ratio_to"
              ) {
                if (typeof list[key] === "string") {
                  list[key] = JSON.parse(list[key]);
                } else {
                  list[key] = "";
                }
              }
            }
            navigate("/labgrown", {
              state: list,
            });
          }}
        >
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
      </td>
    );
  };

  const MyCustomCell = (props) => <CustomCell {...props} />;
  const grid = (
    <Grid
      style={{
        height: "70vh",
      }}
      data={state.items.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)],
      }))}
      skip={state.skip}
      take={state.take}
      total={state.total}
      pageable={state.pageable}
      pageSize={state.pageSize}
      onPageChange={pageChange}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      selectable={{
        enabled: true,
        drag: false,
        cell: false,
        mode: "multiple",
      }}
      onSelectionChange={onSelectionChange}
      onHeaderSelectionChange={onHeaderSelectionChange}
    >
      {/* <GridColumn field="view">sldkjklf</GridColumn> */}
      <GridColumn
        locked={true}
        field={SELECTED_FIELD}
        width="50px"
        headerSelectionValue={
          Object.keys(selectedState).length === 0
            ? false
            : Object.keys(selectedState).every((e) => selectedState[e])
        }
      />
      <GridColumn field="Action" width="75" locked={true} cell={MyCustomCell} />
      <GridColumn field="stone_id" title="Stone ID" width="200" />
      <GridColumn field="size" title="Size" width="200" />
      <GridColumn field="growth_type" title="Growth Type" width="200" />
      <GridColumn field="carat_from" title="Carat From" width="200" />
      <GridColumn field="carat_to" title="Carat To" width="200" />
      <GridColumn field="shape" title="Shape" width="200" />
      <GridColumn field="color" title="Color" width="200" />
      <GridColumn field="fancy_color" title="Fancy Color" width="200" />
      <GridColumn field="Overtone" title="Overtone" width="200" />
      <GridColumn field="intensity" title="Intensity" width="200" />
      <GridColumn field="clarity" title="Clarity" width="200" />
      <GridColumn field="cut" title="Cut" width="200" />
      <GridColumn field="polish" title="Poilish" width="200" />
      <GridColumn field="symmetry" title="Symmetry" width="200" />
      <GridColumn field="fluorescence" title="Fluorescence" width="200" />
      <GridColumn field="disc_from" title="Discount From" width="200" />
      <GridColumn field="disc_to" title="Discount To" width="200" />
      <GridColumn field="table_from" title="table From" width="200" />
      <GridColumn field="table_to" title="table To" width="200" />
      <GridColumn field="depth_from" title="Depth From" width="200" />
      <GridColumn field="depth_to" title="Depth To" width="200" />
      <GridColumn field="ratio_from" title="Ratio From" width="200" />
      <GridColumn field="ratio_to" title="Ratio To" width="200" />
      <GridColumn field="length_from" title="Length From" width="200" />
      <GridColumn field="length_to" title="Length To" width="200" />
      <GridColumn field="width_from" title="Width From" width="200" />
      <GridColumn field="width_to" title="Width To" width="200" />
      <GridColumn field="search_name" title="Search Name" width="200" />
      <GridColumn field="search_date" title="Search Date" width="200" />
      <GridColumn field="status" title="Status" width="100" />
    </Grid>
  );
  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            zIndex: "9999",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            background: "rgba(0,0,0,0.4)",
          }}
        >
          <Loader />
        </div>
      ) : (
        grid
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  masterListReducer: state.masterReducer.masterList,
  profileReducer: state.authReducer.profile,
  getSaveSearchListReducer: state.saveSearchListReducer.getSaveSearchList,
  addSaveSearchListReducer: state.saveSearchListReducer.addSaveSearchList,
  deleteSaveSearchReducer: state.saveSearchListReducer.deleteSaveSearch,
});
const mapDispatchToProps = (dispatch) => ({
  getSaveSearchList: () => dispatch(getSaveSearchList()),
  deleteSaveSearchList: (details) => dispatch(deleteSaveSearchList(details)),
  getMastersList: (payload) => dispatch(getMastersList(payload)),
  resetAddSaveSearch: () => dispatch({ type: ADD_SAVE_SEARCH_RESET }),
  resetDelete: () => dispatch({ type: DELETE_SAVE_SEARCH_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedSearchTable);
