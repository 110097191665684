import React from "react";
import { Modal } from "react-bootstrap";

const CompareStone = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-90w modal-dialog-scrollable"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Stone Comparison</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="table-reponsive">
          <table className="table comparison-tbl">
            <tbody>
              <tr>
                <td></td>
                {props.data.map((e, i) => {
                  return (
                    <td key={i}>
                      <div className="text-center mb-4">
                        <img
                          src={e.image}
                          className="img-fluid"
                          alt="Compare diam"
                        />
                      </div>
                    </td>
                  );
                })}
                {/* <td>
                  <div className="text-center mb-4">
                    <img
                      src={imageurl.blackDiam}
                      className="img-fluid"
                      alt="Compare diam"
                    />
                  </div>
                </td>
                <td>
                  <div className="text-center mb-4">
                    <img
                      src={imageurl.blackDiam}
                      className="img-fluid"
                      alt="Compare diam"
                    />
                  </div>
                </td> */}
              </tr>
              <tr>
                <td></td>
                <td
                  colSpan={props.data.length}
                  className="bg-secondary text-white text-center"
                >
                  Basic Information
                </td>
              </tr>
              <tr>
                <td>Stone ID</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.stone_id}</td>;
                })}
              </tr>
              <tr>
                <td>Cerificate No.</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.cert_no}</td>;
                })}
              </tr>
              <tr>
                <td>Shape</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.shape_name}</td>;
                })}
              </tr>
              <tr>
                <td>Carat</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.carat}</td>;
                })}
              </tr>
              <tr>
                <td>Color</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.color_name}</td>;
                })}
              </tr>
              <tr>
                <td>Clarity</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.clarity_name}</td>;
                })}
              </tr>
              {/* <tr>
                <td>Size</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.size_name}</td>;
                })}
              </tr> */}

              <tr>
                <td>Type</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.diamond_type_name}</td>;
                })}
              </tr>
              <tr>
                <td>Cut</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.cut_name}</td>;
                })}
              </tr>
              <tr>
                <td>Polish</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.polish_name}</td>;
                })}
              </tr>
              <tr>
                <td>Symmetry</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.symmetry_name}</td>;
                })}
              </tr>
              <tr>
                <td>Fluorescence</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.fluorescence_name}</td>;
                })}
              </tr>
              <tr>
                <td>Fancy Color</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.colors_name}</td>;
                })}
              </tr>
              <tr>
                <td>Overtone</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.overtone_name}</td>;
                })}
              </tr>
              <tr>
                <td>Intensity</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.intensity_name}</td>;
                })}
              </tr>
              <tr>
                <td></td>
                <td
                  colSpan={props.data.length}
                  className="bg-secondary text-white text-center"
                >
                  Parameter Details
                </td>
              </tr>
              <tr>
                <td>Measurement</td>
                {props.data.map((e, i) => {
                  return (
                    <td key={i}>
                      {e.length} X {e.width} X {e.height}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>Table(%)</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.table_per}</td>;
                })}
              </tr>
              <tr>
                <td>Depth(%)</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.depth_per}</td>;
                })}
              </tr>
              <tr>
                <td>Avg Dia./Ratio</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.ratio}</td>;
                })}
              </tr>
              <tr>
                <td></td>
                <td
                  colSpan={props.data.length}
                  className="bg-secondary text-white text-center"
                >
                  Price Details
                </td>
              </tr>
              <tr>
                <td>Rap Rate($)</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.rapo_rate.toFixed(2)}</td>;
                })}
              </tr>
              <tr>
                <td>Rap Disc(%)</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.discount.toFixed(2)}</td>;
                })}
              </tr>
              <tr>
                <td>$/Carat</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{(e.amount / e.carat).toFixed(2)}</td>;
                })}
              </tr>
              <tr>
                <td>Amount $</td>
                {props.data.map((e, i) => {
                  return <td key={i}>{e.amount.toFixed(2)}</td>;
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompareStone;
