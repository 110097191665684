import React, { useEffect, useState } from "react";
import Header from "./userLayout/Header";
import Footer from "./userLayout/Footer";
import { connect } from "react-redux";
import { getDiamondDetails } from "../userStore/action/masterAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../Components/Loader";
import { Button } from "reactstrap";

const StoneDetail = (props) => {
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const profileData = props.profileReducer.data;
  useEffect(() => {
    if (!!id) {
      props.getDiamondDetails(id);
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (props.diamondDetailsReducer.success && profileData) {
      const data = props.diamondDetailsReducer.data;
      setDetails({ ...data });
      setLoading(false);
    }
  }, [props.diamondDetailsReducer, profileData]);

  return (
    <>
      <Header />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            zIndex: "9999",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            background: "rgba(0,0,0,0.4)",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {details !== {} && (
            <main className="userMain">
              <div>
                <Button
                  variant="primary"
                  className="my-3"
                  onClick={() => {
                    navigate(
                      !!location.state
                        ? location?.state?.path
                        : "/labGrown/search",
                      {
                        state: location.state,
                      }
                    );
                  }}
                >
                  Back
                </Button>
              </div>
              <div className="d-flex justify-content-between mb-4 flex-wrap">
                <h2 className="heading mb-0">
                  Stone Detail : #{details.stone_id}
                </h2>
              </div>

              {/* Details */}
              <div className="content-block">
                <div className="row">
                  {/* Diamond Detail */}
                  <div className="col-xl-3 col-sm-6 mb-3">
                    <div className="border">
                      <h5 className="bg-primary text-white py-2 px-3">
                        Diamond Details
                      </h5>
                      <table className="table mb-0 detail-table">
                        <tbody>
                          <tr>
                            <th>StoneID</th>
                            <td>{details.stone_id}</td>
                          </tr>
                          <tr>
                            <th>Certificate No</th>
                            <td> {details.cert_no}</td>
                          </tr>
                          <tr>
                            <th>Country</th>
                            <td> {details.country}</td>
                          </tr>
                          {!!profileData && profileData.diamond_type === 2 && (
                            <tr>
                              <th>City</th>
                              <td> {details.city}</td>
                            </tr>
                          )}
                          <tr>
                            <th>Shape</th>
                            <td>{details.shape_name}</td>
                          </tr>
                          <tr>
                            <th>Carat</th>
                            <td>{details.carat} ct.</td>
                          </tr>
                          <tr>
                            <th>Clarity</th>
                            <td>
                              {details.clarity_name !== ""
                                ? details.clarity_name
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Color</th>
                            <td>
                              {" "}
                              {details.color_name !== ""
                                ? details.color_name
                                : "N/A"}
                            </td>
                          </tr>
                          {!!profileData && profileData.diamond_type === 2 && (
                            <>
                              <tr>
                                <th>Fancy Color</th>
                                <td>
                                  {" "}
                                  {details.colors_name !== ""
                                    ? details.colors_name
                                    : "N/A"}
                                </td>
                              </tr>

                              <tr>
                                <th>Intensity</th>
                                <td>
                                  {" "}
                                  {details.intensity_name !== ""
                                    ? details.intensity_name
                                    : "N/A"}
                                </td>
                              </tr>

                              <tr>
                                <th>Overtone</th>
                                <td>
                                  {details.overtone_name !== ""
                                    ? details.overtone_name
                                    : "N/A"}
                                </td>
                              </tr>
                            </>
                          )}
                          <tr>
                            <th>Cut</th>
                            <td>
                              {" "}
                              {details.cut_name !== ""
                                ? details.cut_name
                                : "N/A"}
                            </td>
                          </tr>

                          <tr>
                            <th>Polish</th>
                            <td>
                              {details.polish_name !== ""
                                ? details.polish_name
                                : "N/A"}
                            </td>
                          </tr>

                          <tr>
                            <th>Symmetry</th>
                            <td>
                              {" "}
                              {details.symmetry_name !== ""
                                ? details.symmetry_name
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Flourescence</th>
                            <td>
                              {" "}
                              {details.fluorescence_name !== ""
                                ? details.fluorescence_name
                                : "N/A"}
                            </td>
                          </tr>
                          {!!profileData && profileData.diamond_type === 2 && (
                            <>
                              <tr>
                                <th>Flourescence Color</th>
                                <td>
                                  {" "}
                                  {details.fluorescence_color_name !== ""
                                    ? details.fluorescence_color_name
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>BGM</th>
                                <td>
                                  {" "}
                                  {details.bgm_name !== ""
                                    ? details.bgm_name
                                    : "N/A"}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* Parameter Details*/}
                  <div className="col-xl-3 col-md-6 mb-3">
                    <div className="border">
                      <h5 className="bg-primary text-white py-2 px-3">
                        Parameter Details
                      </h5>
                      <table className="table mb-0 detail-table">
                        <tbody>
                          <tr>
                            <th>Length</th>
                            <td>
                              {" "}
                              {details.length !== "" ? details.length : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Width</th>
                            <td>
                              {" "}
                              {details.width !== "" ? details.width : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Height</th>
                            <td>
                              {" "}
                              {details.height !== "" ? details.height : "N/A"}
                            </td>
                          </tr>

                          {!!profileData && profileData.diamond_type === 2 && (
                            <tr>
                              <th>Measurement</th>
                              <td style={{ wordBreak: "break-all" }}>
                                {details.measurement}
                              </td>
                            </tr>
                          )}
                          {!!profileData && profileData.diamond_type === 2 && (
                            <tr>
                              <th>Table</th>
                              <td>
                                {details.table !== "" ? details.table : "N/A"}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th>Table(%)</th>
                            <td>
                              {details.table_per !== ""
                                ? details.table_per
                                : "N/A"}
                            </td>
                          </tr>
                          {!!profileData && profileData.diamond_type === 2 && (
                            <tr>
                              <th>Depth</th>
                              <td>
                                {" "}
                                {details.depth !== "" ? details.depth : "N/A"}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th>Total Depth(%)</th>
                            <td>
                              {" "}
                              {details.depth_per !== ""
                                ? details.depth_per
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Ratio</th>
                            <td>
                              {details.ratio !== "" ? details.ratio : "N/A"}
                            </td>
                          </tr>
                          {!!profileData && profileData.diamond_type === 1 && (
                            <>
                              <tr>
                                <th>Crown Angle</th>
                                <td>
                                  {details.crown_angle !== ""
                                    ? details.crown_angle
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Crown Height</th>
                                <td>
                                  {details.crown_height !== ""
                                    ? details.crown_height
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Pavilion Angle</th>
                                <td>
                                  {details.pavilion_angle !== ""
                                    ? details.pavilion_angle
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Pavilion Height</th>
                                <td>
                                  {details.pavilion_height !== ""
                                    ? details.pavilion_height
                                    : "N/A"}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* Inclusion Details */}
                  <div className="col-xl-3 col-sm-6 mb-3">
                    <div className="border">
                      <h5 className="bg-primary text-white py-2 px-3">
                        Inclusion Details
                      </h5>
                      <table className="table mb-0 detail-table">
                        <tbody>
                          {!!profileData && profileData.diamond_type === 1 && (
                            <>
                              <tr>
                                <th>Milky</th>
                                <td>
                                  {" "}
                                  {details.milky !== "" ? details.milky : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Shade</th>
                                <td>
                                  {" "}
                                  {details.shade !== "" ? details.shade : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>White Table</th>
                                <td>
                                  {" "}
                                  {details.white_table !== ""
                                    ? details.white_table
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>White Side</th>
                                <td>
                                  {" "}
                                  {details.white_side !== ""
                                    ? details.white_side
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Table Black</th>
                                <td>
                                  {" "}
                                  {details.table_black !== ""
                                    ? details.table_black
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Side Black</th>
                                <td>
                                  {" "}
                                  {details.side_black !== ""
                                    ? details.side_black
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Table Open</th>
                                <td>
                                  {" "}
                                  {details.table_open !== ""
                                    ? details.table_open
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Crown Open</th>
                                <td>
                                  {" "}
                                  {details.crown_open !== ""
                                    ? details.crown_open
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Pavilion Open</th>
                                <td>
                                  {" "}
                                  {details.pavilion_open !== ""
                                    ? details.pavilion_open
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Girdle</th>
                                <td>
                                  {" "}
                                  {details.girdle !== ""
                                    ? details.girdle
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Girdle Desc</th>
                                <td>
                                  {" "}
                                  {details.girdle_desc !== ""
                                    ? details.girdle_desc
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Culet</th>
                                <td>
                                  {" "}
                                  {details.culet !== "" ? details.culet : "N/A"}
                                </td>
                              </tr>
                            </>
                          )}
                          {!!profileData && profileData.diamond_type === 2 && (
                            <>
                              <tr>
                                <th>Pair</th>
                                <td>
                                  {" "}
                                  {details.pair !== "" ? details.pair : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>H & A</th>
                                <td>
                                  {" "}
                                  {details.h_a !== "" ? details.h_a : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Eye Clean</th>
                                <td>
                                  {" "}
                                  {details.eye_clean !== ""
                                    ? details.eye_clean
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th>Growth Type</th>
                                <td>
                                  {" "}
                                  {details.growth_type !== ""
                                    ? details.growth_type
                                    : "N/A"}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* Diamond Detail */}
                  <div className="col-xl-3 col-sm-6 mb-3">
                    <div className="border mb-3">
                      <h5 className="bg-primary text-white py-2 px-3">
                        Price Details
                      </h5>
                      <table className="table mb-0 detail-table">
                        <tbody>
                          <tr>
                            <th>Discount</th>
                            <td>
                              {" "}
                              {details.discount !== ""
                                ? details.discount.toFixed(2)
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th> Rap Rate($)</th>
                            <td>
                              {details.rapo_rate !== ""
                                ? details.rapo_rate.toFixed(2)
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>$/Carat</th>
                            <td>
                              {" "}
                              {(details.amount / details.carat).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <th> Amount $</th>
                            <td>
                              {details.amount !== ""
                                ? details.amount.toFixed(2)
                                : "N/A"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {!!profileData && profileData.diamond_type === 1 && (
                      <div className="border mb-3">
                        <h5 className="bg-primary text-white py-2 px-3">
                          Key To Symbol
                        </h5>
                        <ul>
                          {details?.key_to_symbols?.split(", ").map((e, i) => {
                            return <li key={i}>{e}</li>;
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <hr />

                <div className="row mb-3">
                  <div className="col-lg-6">
                    <img
                      src={details.image}
                      className="img-fluid"
                      alt="Detail"
                      style={{
                        objectFit: "fill",
                        height: "550px",
                        width: "600px",
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <iframe
                      title="stone video"
                      src={details.video}
                      className="detail-frame"
                      width="100%"
                      style={{ objectFit: "fill", height: "550px" }}
                      controls
                      autoPlay
                      loop
                    />
                  </div>
                </div>
                <div className="row ">
                  <div className="col">
                    <iframe
                      src={details.cert_url}
                      width="100%"
                      title="Stone Detail"
                      className="detail-frame"
                    ></iframe>
                  </div>
                </div>
              </div>
            </main>
          )}
          <Footer />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  diamondDetailsReducer: state.masterReducer.diamondDetails,
  profileReducer: state.authReducer.profile,
});
const mapDispatchToProps = (dispatch) => ({
  getDiamondDetails: (id) => dispatch(getDiamondDetails(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoneDetail);
