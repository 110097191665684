import React, { useEffect, useState } from "react";
import { Button, Container, Dropdown, Modal, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import imageurl from "../images";
import { LOGOUT_RESET } from "../userStore/common/constant";
import { logout } from "../userStore/action/authAction";
import { connect } from "react-redux";

const Header = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  // const [userName, setUserName] = useState(null);
  // const [details, setDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const path = useLocation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleLogout = () => {
    props.logout();
    navigate("/");
  };

  useEffect(() => {
    if (props.logoutReducer.success) {
      navigate("/");
      props.resetLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.logoutReducer, navigate]);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  // useEffect(() => {
  //   if (props.profileReducer.success) {
  //     setDetails(props.profileReducer.data);
  //     const name = props.profileReducer.data.fullname;
  //     const _name = name?.split(" ");
  //     if (_name.lenght === 1) {
  //       setUserName(name.slice(0, 2));
  //     } else {
  //       setUserName(`${_name[0][0]}${_name[0][1]}`);
  //     }
  //   }
  // }, [props.profileReducer]);

  const token = localStorage.getItem("token");
  return (
    <>
      <header className={"border-bottom-0 " + (isVisible && "fixed")}>
        <Navbar bg="dark" expand="lg" variant="dark" className="py-4">
          <Container>
            <Navbar.Brand href="#home">
              {path.pathname === "/" ? (
                isVisible && (
                  <img
                    src={imageurl.logo}
                    className="img-fluid"
                    alt="Delight Diamonds"
                  />
                )
              ) : (
                <img
                  src={imageurl.logo}
                  className="img-fluid"
                  alt="Delight Diamonds"
                />
              )}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-main" />
            <Navbar.Offcanvas
              id={`navbar-main`}
              aria-labelledby={`navbar-mainLabel-expand`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`navbar-mainLabel-expand}`}>
                  <img
                    src={imageurl.logo}
                    className="img-fluid logo"
                    alt="Delight Diamonds"
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="ms-auto justify-content-end align-items-lg-center flex-grow-1">
                  <Nav.Link
                    className={path.pathname === "/" ? "active" : ""}
                    as={Link}
                    to="/"
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/about"
                    className={path.pathname === "/about" ? "active" : ""}
                  >
                    About Us
                  </Nav.Link>
                  {/* <Nav.Link
                    as={Link}
                    to="/LabGrownDiamond"
                    className={
                      path.pathname === "/LabGrownDiamond" ? "active" : ""
                    }
                  >
                    Lab Grown
                  </Nav.Link> */}
                  <Nav.Link
                    as={Link}
                    to="/knowledge"
                    className={path.pathname === "/knowledge" ? "active" : ""}
                  >
                    Knowledge
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/contact"
                    className={path.pathname === "/contact" ? "active" : ""}
                  >
                    Contact us
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/star-melee"
                    className={path.pathname === "/star-melee" ? "active" : ""}
                  >
                    Star Melee
                  </Nav.Link>
                  <Dropdown
                    className=""
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Dropdown.Toggle
                      style={{
                        background: "none",
                        border: "none",
                        paddingTop: "11px",
                      }}
                    >
                      <span
                        className="text-light"
                        style={{ fontWeight: "500" }}
                      >
                        {" "}
                        Diamonds
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      show={open}

                    >
                      <Dropdown.Item
                        href="#"
                        onClick={() => navigate("/diamond-natural")}
                      >
                        Natural Diamonds
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => navigate("/LabGrownDiamond")}
                      >
                        Lab Grown Diamond
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {!!token ? (
                    <>
                      <Dropdown className="">
                        <Dropdown.Toggle
                          style={{
                            background: "none",
                            border: "none",
                            paddingTop: "11px",
                          }}
                        >
                          <i className="ri-user-line me-2 text-white"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            left: "-160px",
                          }}
                        >
                          <Dropdown.Item
                            href="#"
                            onClick={() => navigate("/dashboard")}
                          >
                            <i className="fa-solid fa-home me-2 text-primary"></i>{" "}
                            Dashboard
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            onClick={() => navigate("/profile")}
                          >
                            <i className="fa-solid fa-user me-2 text-primary"></i>{" "}
                            Change Profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            onClick={() => navigate("/changePassword")}
                          >
                            <i className="fa-solid fa-unlock me-2 text-primary"></i>{" "}
                            Change Password
                          </Dropdown.Item>
                          {/* <Dropdown.Item href="#/action-3"><i className="fa-solid fa-table-columns me-2 text-primary"></i>Set Column result</Dropdown.Item> */}
                          {/* <Dropdown.Item href="/">
                            <i className="fa-solid fa-address-card me-2 text-primary"></i>
                            Website Profile
                          </Dropdown.Item> */}
                          <Dropdown.Item
                            href="#"
                            onClick={() => setIsLogout(true)}
                          >
                            <i className="fa-solid fa-arrow-right-from-bracket me-2 text-primary"></i>
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : (
                    <Nav.Link
                      href="/login"
                      className="d-flex align-items-center nav-login"
                    >
                      <i className="ri-user-line me-2"></i> Login
                    </Nav.Link>
                  )}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </header>
      <Modal
        show={isLogout}
        onHide={() => setIsLogout(false)}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to logout?</div>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button variant="secondary" onClick={() => setIsLogout(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            className="text-white"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
      {isVisible && (
        <div onClick={scrollToTop}>
          <h3>Go up!</h3>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  profileReducer: state.authReducer.profile,
  logoutReducer: state.authReducer.logout,
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  resetLogout: () => dispatch({ type: LOGOUT_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
