import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal} from "react-bootstrap";
import { connect } from "react-redux";
import { getMastersList } from "../../userStore/action/masterAction";
import { MultiSelect } from "react-multi-select-component";
import { getCountry } from "../../userStore/action/authAction";
import { addDemandList } from "../../userStore/action/demandAction";
import { toast } from "react-toastify";

const AddDemandModal = (props) => {
  const [masters, setMasters] = useState({});
  const [loading, setLoading] = useState(true);
  // const [countryList, setCountryList] = useState([]);
  const [error, setError] = useState({
    name: false,
    location: false,
    // stone_id: false,
    quantity: false,
    carat: false,
  });

  const [state, setState] = useState({
    name: "",
    quantity: "",
    stone_id: "",
    location: "",
    shape_id: [],
    size_id: [],
    color_id: [],
    colors_id: [],
    overtone_id: [],
    intensity_id: [],
    clarity_id: [],
    cut_id: [],
    polish_id: [],
    symmetry_id: [],
    fluorescence_id: [],
    fluorescence_color_id: [],
    bgm_id: [],
    lab: [],
    diamond_type: [],
    carat_from: [""],
    carat_to: [""],
    ct_from: "",
    ct_to: "",
    discount_from: "",
    discount_to: "",
    value_from: "",
    value_to: "",
    length_from: "",
    length_to: "",
    width_from: "",
    width_to: "",
    height_from: "",
    height_to: "",
    table_from: "",
    table_to: "",
    depth_from: "",
    depth_to: "",
    ratio_from: "",
    ratio_to: "",
  });
  useEffect(() => {
    if (!props.masterListReducer.success) {
      props.getMastersList();
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (props.masterListReducer.success) {
      const data = props.masterListReducer.data;
      setMasters({ ...data });
      setLoading(false);
    }
  }, [props.masterListReducer]);

  const addCarat = () => {
    setState((prev) => ({
      ...prev,
      carat_from: [...prev.carat_from, ""],
      carat_to: [...prev.carat_to, ""],
    }));
  };

  const removeCarat = (index) => {
    const cartFrom = [...state.carat_from];
    cartFrom.splice(index, 1);
    const cartTo = [...state.carat_to];
    cartTo.splice(index, 1);
    setState((prev) => ({
      ...prev,
      carat_from: [...cartFrom],
      carat_to: [...cartTo],
    }));
  };

  const handleInput = (e, index) => {
    const { name, value } = e.target;
    if (name === "carat_from") {
      const from = [...state.carat_from];
      const _list = from.map((e, i) => {
        if (i === index) {
          return value;
        } else return e;
      });
      setState((pre) => ({
        ...pre,
        [name]: [..._list],
      }));
    } else if (name === "carat_to") {
      const to = [...state.carat_to];
      const _list = to.map((e, i) => {
        if (i === index) {
          return value;
        } else return e;
      });
      setState((pre) => ({
        ...pre,
        [name]: [..._list],
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    switch (name) {
      case "name":
        if (value === "") {
          error["name"] = "Required";
        } else {
          error["name"] = false;
        }
        break;
      case "quantity":
        if (value === "" || parseInt(value) === 0) {
          error["quantity"] = "Required";
        } else {
          error["quantity"] = false;
        }
        break;
      // case "stone_id":
      //   if (value === "") {
      //     error["stone_id"] = "Required";
      //   } else {
      //     error["stone_id"] = false;
      //   }
      //   break;
      case "location":
        if (value === "") {
          error["location"] = "Required";
        } else {
          error["location"] = false;
        }
        break;
      case "carat_from":
        if (value === "") {
          error["carat"] = "Required";
        } else {
          error["carat"] = false;
        }
        break;
      case "carat_to":
        if (value === "") {
          error["carat"] = "Required";
        } else {
          error["carat"] = false;
        }
        break;
      default:
        break;
    }
  };

  const handleMasters = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: [...value],
    }));
  };

  useEffect(() => {
    props.countryList();
  }, []);

  useEffect(() => {
    if (props.countryReducer.success) {
      // setCountryList([...props.countryReducer.data]);
    }
  }, [props.countryReducer]);

  const handleSubmit = () => {
    const isCarat = state.carat_from
      .map((e, i) => {
        if (e === "" || state.carat_to[i] === "") {
          return false;
        } else return true;
      })
      .every((e) => e === true);

    if (state.name === "") {
      setError((prev) => ({
        ...prev,
        name: "Requierd",
      }));
    } if (state.location === "") {
      setError((prev) => ({
        ...prev,
        location: "Requierd",
      }));
    } if (state.quantity === "") {
      setError((prev) => ({
        ...prev,
        quantity: "Requierd",
      }));
    } 
    // if (state.stone_id === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     stone_id: "Requierd",
    //   }));
    // }
    if (!isCarat) {
      setError((prev) => ({
        ...prev,
        carat: "Add atleast one carat details",
      }));
    }

    if (
      state.name !== "" &&
      // state.stone_id !== "" &&
      state.location !== "" &&
      state.quantity !== "" &&
      isCarat
    ) {
      const details = {
        name: state.name,
        quantity: state.quantity,
        stone_id: state.stone_id,
        location: state.location,
        shape_id: state.shape_id.map((e) => {
          return e.value;
        }),
        size_id: state.size_id.map((e) => {
          return e.value;
        }),
        color_id: state.color_id.map((e) => {
          return e.value;
        }),
        colors_id: state.colors_id.map((e) => {
          return e.value;
        }),
        overtone_id: state.overtone_id.map((e) => {
          return e.value;
        }),
        intensity_id: state.intensity_id.map((e) => {
          return e.value;
        }),
        clarity_id: state.clarity_id.map((e) => {
          return e.value;
        }),
        cut_id: state.cut_id.map((e) => {
          return e.value;
        }),
        polish_id: state.polish_id.map((e) => {
          return e.value;
        }),
        symmetry_id: state.symmetry_id.map((e) => {
          return e.value;
        }),
        fluorescence_id: state.fluorescence_id.map((e) => {
          return e.value;
        }),
        fluorescence_color_id: state.fluorescence_color_id.map((e) => {
          return e.value;
        }),
        bgm_id: state.bgm_id.map((e) => {
          return e.value;
        }),
        lab: state.lab.map((e) => {
          return e.value;
        }),
        diamond_type: state.diamond_type.map((e) => {
          return e.value;
        }),
        carat_from: [...state.carat_from],
        carat_to: [...state.carat_to],
        ct_from: state.ct_from,
        ct_to: state.ct_to,
        discount_from: state.discount_from,
        discount_to: state.discount_to,
        value_from: state.value_from,
        value_to: state.value_to,
        length_from: state.length_from,
        length_to: state.length_to,
        width_from: state.width_from,
        width_to: state.width_to,
        height_from: state.height_from,
        height_to: state.height_to,
        table_from: state.table_from,
        table_to: state.table_to,
        depth_from: state.depth_from,
        depth_to: state.depth_to,
        ratio_from: state.ratio_from,
        ratio_to: state.ratio_to,
      };

      props.addDemandList(details);
    }
  };

  useEffect(() => {
    if (props.addDemandReducer.success) {
      props.setIsDemand(false);
      toast.success('Demand created successfully...')
    }
  }, [props.addDemandReducer]);

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Modal
          show={props.show}
          onHide={props.onHide}
          dialogClassName="modal-dialog-scrollable"
          size="lg"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Demand</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>Demand name *</label>
                <input
                  className="form-control"
                  placeholder="Enter Demand Name"
                  name="name"
                  value={state.name}
                  onChange={handleInput}
                />
                {error.name && <span className="form-error">{error.name}</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label>Quantity *</label>
                <input
                  type="number"
                  className="form-control"
                  name="quantity"
                  value={state.quantity}
                  placeholder="Enter quantity"
                  onChange={handleInput}
                />
                {error.quantity && (
                  <span className="form-error">{error.quantity}</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>Stone ID</label>
                <input
                  className="form-control"
                  placeholder="Enter Stone Id"
                  name="stone_id"
                  value={state.stone_id}
                  onChange={handleInput}
                />
                {/* {error.stone_id && (
                  <span className="form-error">{error.stone_id}</span>
                )} */}
              </div>
              <div className="col-md-6 mb-3">
                <label>Location *</label>
                <select
                  id="location"
                  className="form-control"
                  value={state.location}
                  onChange={handleInput}
                  name="location"
                >
                  <option value={""}>Select Location</option>
                  {masters?.Location?.map((data, i) => {
                    return (
                      <option key={i} value={data.country}>
                        {data.country}
                      </option>
                    );
                  })}
                </select>
                {error.location && (
                  <span className="form-error">{error.location}</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mb-3">
                <label>Shape</label>
                <MultiSelect
                  options={masters?.Shape?.map((e) => {
                    return {
                      label: e.name,
                      value: e.id,
                    };
                  })}
                  value={state.shape_id}
                  onChange={(e) => handleMasters("shape_id", e)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label>Size</label>
                <MultiSelect
                  options={masters?.Size?.map((e) => {
                    return {
                      label: e.name,
                      value: e.id,
                    };
                  })}
                  value={state.size_id}
                  onChange={(e) => handleMasters("size_id", e)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label>Clarity</label>
                <MultiSelect
                  options={masters?.Clarity?.map((e) => {
                    return {
                      label: e.name,
                      value: e.id,
                    };
                  })}
                  value={state.clarity_id}
                  onChange={(e) => handleMasters("clarity_id", e)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label>Color</label>
                <MultiSelect
                  options={masters?.Color?.map((e) => {
                    return {
                      label: e.name,
                      value: e.id,
                    };
                  })}
                  value={state.color_id}
                  onChange={(e) => handleMasters("color_id", e)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-3">
                <label>Colors</label>
                <MultiSelect
                  options={masters?.FancyColor?.filter(
                    (e) => e.type === 0
                  )?.map((e) => {
                    return {
                      label: e.name,
                      value: e.id,
                    };
                  })}
                  value={state.colors_id}
                  onChange={(e) => handleMasters("colors_id", e)}
                />
              </div>
              <div className="col-md-4 mb-3">
                <label>Overtone</label>
                <MultiSelect
                  options={masters?.FancyColor?.filter(
                    (e) => e.type === 1
                  )?.map((e) => {
                    return {
                      label: e.name,
                      value: e.id,
                    };
                  })}
                  value={state.overtone_id}
                  onChange={(e) => handleMasters("overtone_id", e)}
                />
              </div>
              <div className="col-md-4 mb-3">
                <label>Intensity</label>
                <MultiSelect
                  options={masters?.FancyColor?.filter(
                    (e) => e.type === 2
                  )?.map((e) => {
                    return {
                      label: e.name,
                      value: e.id,
                    };
                  })}
                  value={state.intensity_id}
                  onChange={(e) => handleMasters("intensity_id", e)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-3">
                <label>Cut</label>
                <MultiSelect
                  options={masters?.Finish?.filter((e) => e.type === 0)?.map(
                    (e) => {
                      return {
                        label: e.name,
                        value: e.id,
                      };
                    }
                  )}
                  value={state.cut_id}
                  onChange={(e) => handleMasters("cut_id", e)}
                />
              </div>
              <div className="col-md-4 mb-3">
                <label>Polish</label>
                <MultiSelect
                  options={masters?.Finish?.filter((e) => e.type === 1)?.map(
                    (e) => {
                      return {
                        label: e.name,
                        value: e.id,
                      };
                    }
                  )}
                  value={state.polish_id}
                  onChange={(e) => handleMasters("polish_id", e)}
                />
              </div>
              <div className="col-md-4 mb-3">
                <label>Symmetry</label>
                <MultiSelect
                  options={masters?.Finish?.filter((e) => e.type === 2)?.map(
                    (e) => {
                      return {
                        label: e.name,
                        value: e.id,
                      };
                    }
                  )}
                  value={state.symmetry_id}
                  onChange={(e) => handleMasters("symmetry_id", e)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-3">
                <label>Fluorescence</label>
                <MultiSelect
                  options={masters?.Fluorescence?.filter(
                    (e) => e.type === 0
                  )?.map((e) => {
                    return {
                      label: e.name,
                      value: e.id,
                    };
                  })}
                  value={state.fluorescence_id}
                  onChange={(e) => handleMasters("fluorescence_id", e)}
                />
              </div>
              <div className="col-md-4 mb-3">
                <label>Fluorescence Color</label>
                <MultiSelect
                  options={masters?.Fluorescence?.filter(
                    (e) => e.type === 1
                  )?.map((e) => {
                    return {
                      label: e.name,
                      value: e.id,
                    };
                  })}
                  value={state.fluorescence_color_id}
                  onChange={(e) => handleMasters("fluorescence_color_id", e)}
                />
              </div>
              <div className="col-md-4 mb-3">
                <label>BGM</label>
                <MultiSelect
                  options={masters?.Fluorescence?.filter(
                    (e) => e.type === 2
                  )?.map((e) => {
                    return {
                      label: e.name,
                      value: e.id,
                    };
                  })}
                  value={state.bgm_id}
                  onChange={(e) => handleMasters("bgm_id", e)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>Diamond Type</label>
                <MultiSelect
                  options={Object.keys(masters?.DiamondType).map((e) => {
                    return {
                      label: masters?.DiamondType[e],
                      value: e,
                    };
                  })}
                  value={state.diamond_type}
                  onChange={(e) => handleMasters("diamond_type", e)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label>Lab</label>
                <MultiSelect
                  options={Object.keys(masters?.Lab).map((e) => {
                    return {
                      label: masters?.Lab[e],
                      value: e,
                    };
                  })}
                  value={state.lab}
                  onChange={(e) => handleMasters("lab", e)}
                />
              </div>
            </div>
            <div className="row">
              <label>Carat *</label>
              {state.carat_from.map((e, i) => {
                return (
                  i < 6 && (
                    <div className="col-md-6 mb-3">
                      <div className="d-flex align-items-center justify-content-center">
                        <input
                          type="number"
                          name="carat_from"
                          className="form-control"
                          placeholder="From"
                          value={e}
                          onChange={(e) => handleInput(e, i)}
                        />
                        <input
                          type="number"
                          name="carat_to"
                          className="form-control"
                          placeholder="To"
                          onChange={(e) => handleInput(e, i)}
                          value={state.carat_to[i]}
                        />

                        {state.carat_from.length - 1 === i &&
                          state.carat_from.length !== 5 && (
                            <div className="p-2 mx-1 border" onClick={addCarat}>
                              <i className="fa-solid fa-plus"></i>
                            </div>
                          )}
                        {state.carat_from.length !== 1 && (
                          <div
                            className="p-2 mx-1 border"
                            onClick={() => removeCarat(i)}
                          >
                            <i className="fa-solid fa-minus"></i>
                          </div>
                        )}
                      </div>
                      {error.carat && (
                        <span className="form-error">{error.carat}</span>
                      )}
                    </div>
                  )
                );
              })}
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>CT</label>
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="number"
                    name="ct_from"
                    className="form-control"
                    placeholder="From"
                    value={state.ct_from}
                    onChange={handleInput}
                  />
                  <input
                    type="number"
                    name="ct_to"
                    className="form-control"
                    placeholder="To"
                    onChange={handleInput}
                    value={state.ct_to}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label>Discount</label>
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="number"
                    name="discount_from"
                    className="form-control"
                    placeholder="From"
                    value={state.discount_from}
                    onChange={handleInput}
                  />
                  <input
                    type="number"
                    name="discount_to"
                    className="form-control"
                    placeholder="To"
                    onChange={handleInput}
                    value={state.discount_to}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>Value</label>
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="number"
                    name="value_from"
                    className="form-control"
                    placeholder="From"
                    value={state.value_from}
                    onChange={handleInput}
                  />
                  <input
                    type="number"
                    name="value_to"
                    className="form-control"
                    placeholder="To"
                    onChange={handleInput}
                    value={state.value_to}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label>Length</label>
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="number"
                    name="length_from"
                    className="form-control"
                    placeholder="From"
                    value={state.length_from}
                    onChange={handleInput}
                  />
                  <input
                    type="number"
                    name="length_to"
                    className="form-control"
                    placeholder="To"
                    onChange={handleInput}
                    value={state.length_to}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label>Width</label>
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="number"
                    name="width_from"
                    className="form-control"
                    placeholder="From"
                    value={state.width_from}
                    onChange={handleInput}
                  />
                  <input
                    type="number"
                    name="width_to"
                    className="form-control"
                    placeholder="To"
                    onChange={handleInput}
                    value={state.width_to}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label>Height</label>
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="number"
                    name="height_from"
                    className="form-control"
                    placeholder="From"
                    value={state.height_from}
                    onChange={handleInput}
                  />
                  <input
                    type="number"
                    name="height_to"
                    className="form-control"
                    placeholder="To"
                    onChange={handleInput}
                    value={state.height_to}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>Table</label>
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="number"
                    name="table_from"
                    className="form-control"
                    placeholder="From"
                    value={state.table_from}
                    onChange={handleInput}
                  />
                  <input
                    type="number"
                    name="table_to"
                    className="form-control"
                    placeholder="To"
                    onChange={handleInput}
                    value={state.table_to}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label>Depth</label>
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="number"
                    name="depth_from"
                    className="form-control"
                    placeholder="From"
                    value={state.depth_from}
                    onChange={handleInput}
                  />
                  <input
                    type="number"
                    name="depth_to"
                    className="form-control"
                    placeholder="To"
                    onChange={handleInput}
                    value={state.depth_to}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label>Ratio</label>
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="number"
                    name="ratio_from"
                    className="form-control"
                    placeholder="From"
                    value={state.ratio_from}
                    onChange={handleInput}
                  />
                  <input
                    type="number"
                    name="ratio_to"
                    className="form-control"
                    placeholder="To"
                    onChange={handleInput}
                    value={state.ratio_to}
                  />
                </div>
              </div>
            </div>

            <Button
              type="submit"
              className="btn btn-Primary text-white"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  masterListReducer: state.masterReducer.masterList,
  countryReducer: state.authReducer.getCountry,
  addDemandReducer: state.demandReducer.addDemand,
});
const mapDispatchToProps = (dispatch) => ({
  getMastersList: (payload) => dispatch(getMastersList(payload)),
  addDemandList: (details) => dispatch(addDemandList(details)),
  countryList: () => dispatch(getCountry()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDemandModal);
