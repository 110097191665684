import { ADD_CONFIRM_STONE_LIST_ERROR, ADD_CONFIRM_STONE_LIST_RESET, ADD_CONFIRM_STONE_LIST_SUCCESS, DELETE_CONFIRM_STONE_LIST_ERROR, DELETE_CONFIRM_STONE_LIST_RESET, DELETE_CONFIRM_STONE_LIST_SUCCESS, GET_CONFIRM_STONE_LIST_ERROR, GET_CONFIRM_STONE_LIST_RESET, GET_CONFIRM_STONE_LIST_SUCCESS } from "../common/constant";

let initialState = {
  ConfirmStoneList: {
    error: false,
    data: null,
    errors: null,
    success: false,
  },
  createConfirmStone: {
    error: false,
    errors: null,
    success: false,
    msg: null,
    count: 0
  },
  deleteConfirmStone: {
    error: false,
    errors: null,
    success: false,
  }
};

export default function confirmStoneReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONFIRM_STONE_LIST_SUCCESS:
      return { ...state, ConfirmStoneList: { ...state.ConfirmStoneList, success: true, error: false, errors: null, data: action.response }, };

    case GET_CONFIRM_STONE_LIST_ERROR:
      return { ...state, ConfirmStoneList: { ...state.ConfirmStoneList, success: false, error: true, errors: action.msg } };

    case GET_CONFIRM_STONE_LIST_RESET:
      return { ...state, ConfirmStoneList: { ...state.ConfirmStoneList, success: false, error: false, errors: null } };

    //  add

    case ADD_CONFIRM_STONE_LIST_SUCCESS:
      return { ...state, createConfirmStone: { ...state.createConfirmStone, success: true, error: false, errors: null, msg: action.msg, count: action.count }, };

    case ADD_CONFIRM_STONE_LIST_ERROR:
      return { ...state, createConfirmStone: { ...state.createConfirmStone, success: false, error: true, errors: action.msg } };

    case ADD_CONFIRM_STONE_LIST_RESET:
      return { ...state, createConfirmStone: { ...state.createConfirmStone, success: false, error: false, errors: null } };



    //  delete

    case DELETE_CONFIRM_STONE_LIST_SUCCESS:
      return { ...state, deleteConfirmStone: { ...state.deleteConfirmStone, success: true, error: false, errors: null }, };

    case DELETE_CONFIRM_STONE_LIST_ERROR:
      return { ...state, deleteConfirmStone: { ...state.deleteConfirmStone, success: false, error: true, errors: action.msg } };

    case DELETE_CONFIRM_STONE_LIST_RESET:
      return { ...state, deleteConfirmStone: { ...state.deleteConfirmStone, success: false, error: false, errors: null } };

    default:
      return state;
  }
}