import axios from "axios";

import { toast } from "react-toastify";
import {
  changePassword_Url,
  checkTerms_Url,
  countryList,
  diamondsType_Url,
  forgotPass,
  getPolicy_Url,
  login_Url,
  logout_Url,
  marketing_Url,
  merchantCountryList_Url,
  merchantRegister_Url,
  profile,
  register_Url,
  updateForgotPass,
  vendorCountryList_Url,
  vendorRegister_Url,
  verifyOtp_Url,
} from "../common/apiSheet";
import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CHECK_DIAMONDS_TYPE_ERROR,
  CHECK_DIAMONDS_TYPE_SUCCESS,
  CHECK_TERMS_ERROR,
  CHECK_TERMS_SUCCESS,
  COOKIE_EXPIRED,
  ERROR_COUNTRY,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  GET_COUNTRY,
  GET_MERCHANT_COUNTRY_LIST_ERROR,
  GET_MERCHANT_COUNTRY_LIST_SUCCESS,
  GET_POLICY_ERROR,
  GET_POLICY_SUCCESS,
  GET_VENDOR_COUNTRY_LIST_ERROR,
  GET_VENDOR_COUNTRY_LIST_SUCCESS,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  MARKETING_USER_ERROR,
  MARKETING_USER_SUCCESS,
  MERCHANT_REGISTER_ERROR,
  MERCHANT_REGISTER_SUCCESS,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  UPDATE_FORGOT_PASSWORD_ERROR,
  UPDATE_FORGOT_PASSWORD_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
  VENDOR_REGISTER_ERROR,
  VENDOR_REGISTER_SUCCESS,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS,
} from "../common/constant";

export const saveSession = (data) => {
  localStorage.setItem("Authorization", data.token);
  localStorage.setItem("id", data.result.id);
};

export const getSession = () => {
  return {
    Authorization: localStorage.getItem("Authorization"),
    user_id: localStorage.getItem("id"),
  };
};

export const clearSession = () => {
  localStorage.removeItem("Authorization");
  localStorage.removeItem("id");
};

export const logout = (user) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: logout_Url,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios(requestOptions)
      .then((resp) => {
        toast.success("Logout Successfully");
        localStorage.removeItem("token");
        dispatch({
          type: LOGOUT_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: LOGOUT_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: LOGOUT_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const login = (user) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: login_Url,
      data: user.details,
    };
    axios(requestOptions)
      .then((resp) => {
        localStorage.setItem("token", resp?.data.data.token);
        if (!!user.remeber) {
          document.cookie = `details=${JSON.stringify(
            user.details
          )}; expires=Thu, 18 Dec 9999 12:00:00 UTC`;
        } else {
          document.cookie = `details=${JSON.stringify(
            user.details
          )}; expires=Thu, 18 Dec 2001 12:00:00 UTC`;
        }
        dispatch({
          type: LOGIN_SUCCESS,
          data: resp.data.data.result
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: LOGIN_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = error.response.data.error;
          dispatch({
            type: LOGIN_ERROR,
            msg: validationError,
          });
        } else {
          dispatch({
            type: LOGIN_ERROR,
            msg: error.response.data.error,
          });
        }
      });
  };
};

export const register = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: register_Url,
      headers: { "content-Type": "multipart/form-data" },
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        toast.success("Register Successfully");
        // localStorage.setItem("token", resp?.data.data.token);
        dispatch({
          type: REGISTER_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: REGISTER_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: REGISTER_ERROR,
            msg: validationError,
          });
        } else {
          dispatch({
            type: REGISTER_ERROR,
            msg: error,
          });
        }
      });
  };
};

export const getCountry = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: countryList,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_COUNTRY,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ERROR_COUNTRY,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ERROR_COUNTRY,
            msg: validationError,
          });
        }
      });
  };
};

export const getProfile = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: profile,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios(requestOptions)
    .then((resp) => {
        console.log(resp.data.data.result)
        // localStorage.setItem('token', resp?.data.data.token)
        dispatch({
          type: PROFILE_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: PROFILE_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: PROFILE_ERROR,
            msg: error,
          });
        } else if (data.status === 401) {
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };
};

export const updateProfile = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: profile,
      data: details,
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .then(() => {
        toast.success("Profile Updated...");
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_PROFILE_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_PROFILE_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const changePassword = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: changePassword_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
        });
        toast.success("Password Changed Successfully....");
      })
      .catch((error) => {
        let data = error.response;

        if (!error.response) {
          dispatch({
            type: CHANGE_PASSWORD_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: CHANGE_PASSWORD_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const forgotPassword = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: forgotPass,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;

        if (!error.response) {
          dispatch({
            type: FORGOT_PASSWORD_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: FORGOT_PASSWORD_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const verifyOtp = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: verifyOtp_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: VERIFY_OTP_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: VERIFY_OTP_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: VERIFY_OTP_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const updateForgotPassword = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: updateForgotPass,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_FORGOT_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;

        if (!error.response) {
          dispatch({
            type: UPDATE_FORGOT_PASSWORD_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: UPDATE_FORGOT_PASSWORD_ERROR,
            msg: validationError,
          });
        }
      });
  };
};


export const getPolicy = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: getPolicy_Url,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_POLICY_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_POLICY_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_POLICY_ERROR,
            msg: error,
          });
        } else if (data.status === 401) {
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };
};

export const 
checkTerms = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: checkTerms_Url,
      // headers: { "content-Type": "multipart/form-data" },
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        toast.success("Register Successfully");
        localStorage.setItem("token", resp?.data.data.token);
        dispatch({
          type: CHECK_TERMS_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CHECK_TERMS_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: CHECK_TERMS_ERROR,
            msg: validationError,
          });
        } else {
          dispatch({
            type: CHECK_TERMS_ERROR,
            msg: error,
          });
        }
      });
  };
};


export const marketingUser = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: marketing_Url,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios(requestOptions)
      .then((resp) => {
        // localStorage.setItem('token', resp?.data.data.token)
        dispatch({
          type: MARKETING_USER_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: MARKETING_USER_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: MARKETING_USER_ERROR,
            msg: error,
          });
        } else if (data.status === 401) {
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };
};


export const checkDiamondsTypeAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: diamondsType_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CHECK_DIAMONDS_TYPE_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CHECK_DIAMONDS_TYPE_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: CHECK_DIAMONDS_TYPE_ERROR,
            msg: validationError,
          });
        } else {
          dispatch({
            type: CHECK_DIAMONDS_TYPE_ERROR,
            msg: error,
          });
        }
      });
  };
};


export const getVendorCountryList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: vendorCountryList_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_VENDOR_COUNTRY_LIST_SUCCESS,
          response: resp.data.data.result
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_VENDOR_COUNTRY_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_VENDOR_COUNTRY_LIST_ERROR,
            msg: validationError,
          });
        } else {
          dispatch({
            type: GET_VENDOR_COUNTRY_LIST_ERROR,
            msg: error,
          });
        }
      });
  };
};

export const vendorRegister = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: vendorRegister_Url,
      data: details
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: VENDOR_REGISTER_SUCCESS,
          response: resp.data.data.result
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: VENDOR_REGISTER_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.error_message;
          dispatch({
            type: VENDOR_REGISTER_ERROR,
            msg: validationError,
          });
        } else {
          dispatch({
            type: VENDOR_REGISTER_ERROR,
            msg: error,
          });
        }
      });
  };
};



export const getMerchantCountryList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: merchantCountryList_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_MERCHANT_COUNTRY_LIST_SUCCESS,
          response: resp.data.data.result
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_MERCHANT_COUNTRY_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_MERCHANT_COUNTRY_LIST_ERROR,
            msg: validationError,
          });
        } else {
          dispatch({
            type: GET_MERCHANT_COUNTRY_LIST_ERROR,
            msg: error,
          });
        }
      });
  };
};

export const merchantRegister = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: merchantRegister_Url,
      data: details
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: MERCHANT_REGISTER_SUCCESS,
          response: resp.data.data.result
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: MERCHANT_REGISTER_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.error_message;
          dispatch({
            type: MERCHANT_REGISTER_ERROR,
            msg: validationError,
          });
        } else {
          dispatch({
            type: MERCHANT_REGISTER_ERROR,
            msg: error,
          });
        }
      });
  };
};

