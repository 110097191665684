import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

const ContactUs = () => {
  return (
    <>
    <Header />
      <Container fluid className="banner">
        <Container>
          <h2 className="pageName" data-aos="zoom-out" data-aos-duration="1000">
            Contact Us
          </h2>
        </Container>
      </Container>
      <main>
        <section className="container">
          <Row className="position-relative">
            <Col lg={3}>
              <div
                className="contact-info"
                data-aos="zoom-out-up"
                data-aos-duration="1000"
              >
                <div>
                  <p className="mb-2">
                    <i className="fa-solid me-2 fa-envelope"></i> Mail Us:
                  </p>
                  <h5 className="mb-4">
                    <a
                      href="mailto: info@delightdiamonds.com"
                      className="text-white"
                    >
                      info@delightdiamonds.com
                    </a>
                  </h5>

                  <p className="mb-2">
                    <i className="fa-solid me-2 fa-phone"></i> Call Us:
                  </p>
                  <h5 className="mb-4">
                    <a href="callto: +91 9998254748" className="text-white">
                      +91 9998254748
                    </a>
                  </h5>

                  <p className="mb-2">
                    <i className="fa-solid me-2 fa-location-dot"></i> Reach Us:
                  </p>
                  <h5 className="mb-4">
                    3rd Floor, Ward no 6,
                    <br /> Plot No 1342 Sumangal,
                    <br /> Jadakhadi Mahidharpura Surat
                  </h5>
                </div>
              </div>
            </Col>
            <Col lg={10} className="ms-auto">
              <h2
                className="heading"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                Get In Touch
              </h2>
              <p
                data-aos="zoom-in-up"
                data-aos-duration="1000"
                className="mb-5"
              >
                We're Happy to answer any questions you have or provide you with
                an estimate.
                <br /> Just send us a message in the form below with any
                question you may have.
              </p>
              <div
                className="contact-form"
                data-aos="zoom-out-up"
                data-aos-duration="1000"
              >
                <form>
                  <Row>
                    <Col md={6} className="mb-3">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Full name"
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <label>Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Phone No."
                      />
                    </Col>
                    <Col md={12} className="mb-3">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Email"
                      />
                    </Col>
                    <Col md={12} className="mb-3">
                      <label>Message</label>
                      <textarea
                        type="text"
                        rows={5}
                        className="form-control"
                        placeholder="Enter your Message."
                      ></textarea>
                    </Col>
                    <Col md={12} className="mb-3">
                      <button
                        className="btn btn-primary text-white py-2 px-4"
                        type="submit"
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default ContactUs;
