import axios from "axios";
import { AddsaveSearch_Url, DeletesaveSearch_Url, saveSearchList_Url } from "../common/apiSheet";
import { ADD_SAVE_SEARCH_ERROR, ADD_SAVE_SEARCH_SUCCESS, DELETE_SAVE_SEARCH_ERROR, DELETE_SAVE_SEARCH_SUCCESS, SAVE_SEARCH_LIST_ERROR, SAVE_SEARCH_LIST_SUCCESS } from "../common/constant";

export const getSaveSearchList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: saveSearchList_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    }
    axios(requestOptions)
      .then((resp) => {
        // localStorage.setItem('token', resp?.data.data.token)
        dispatch({
          type: SAVE_SEARCH_LIST_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SAVE_SEARCH_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: SAVE_SEARCH_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const addSaveSearchList = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: AddsaveSearch_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data: details
    }
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_SAVE_SEARCH_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_SAVE_SEARCH_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_SAVE_SEARCH_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const deleteSaveSearchList = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: DeletesaveSearch_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data: details
    }
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_SAVE_SEARCH_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_SAVE_SEARCH_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_SAVE_SEARCH_ERROR,
            msg: validationError,
          });
        }
      });
  };
};