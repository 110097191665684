import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getCountry,
  getProfile,
  updateProfile,
} from "../userStore/action/authAction";
import { UPDATE_PROFILE_RESET } from "../userStore/common/constant";
import Footer from "./userLayout/Footer";
import Header from "./userLayout/Header";

const UserProfile = (props) => {
  const navigate = useNavigate();
  const [key, setKey] = useState("profile");
  // const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({
    username: "",
    email: "",
    fullname: "",
    country_id: "",
    company: "",
    mobile: "",
    whatsapp: "",
    wechat: "",
    skype: "",
    proof: "",
  })
  const [error] = useState({
    userName: false,
    email: false,
    password: false,
    conPassword: false,
    name: false,
    country: false,
    company: false,
    mobileNo: false,
    whatsapp: false,
    wechat: false,
    skype: false,
    proof: false,
    social: false,
  });
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    props.countryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.profileReducer.success) {
      // setLoading(false);
      const data = props.profileReducer.data;
      setDetails((prevState) => ({
        ...prevState,
        ...data,
      }));
    }
  }, [props.profileReducer]);

  useEffect(() => {
    if (props.countryReducer.success) {
      setCountryList([...props.countryReducer.data]);
    }
  }, [props.countryReducer]);

  const handleInput = (e) => {
    const contactValidation = RegExp(/^(?!0+\s+,?$)\d{10}\s*,?$/);
    // /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    // /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
    const validEmailRegex = RegExp(
      // /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
         /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    );
    const { name, value } = e.target;
    if (name === "proof" && value === "") {
      setDetails((prevState) => ({
        ...prevState,
        [name]: prevState[name],
      }));
    } else if (name === "whatsapp" || name === "mobile") {
      if (value.length <= 10) {
        setDetails((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else {
      setDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }


    if (name === "proof" && value !== "") {
      details["document"] = e.target.files[0];
    }

    switch (name) {
      case "username":
        if (value === "") {
          error["userName"] = "*Required";
        } else {
          error["userName"] = false;
        }
        break;

      case "email":
        if (value === "") {
          error["email"] = "*Required";
        } else if (!validEmailRegex.test(value)) {
          error["email"] = "Invalid Email";
        } else {
          error["email"] = false;
        }
        break;

      case "fullname":
        if (value === "") {
          error["name"] = "*Required";
        } else {
          error["name"] = false;
        }
        break;

      case "country_id":
        if (value === "") {
          error["country"] = "*Required";
        } else {
          error["country"] = false;
        }
        break;

      case "company":
        if (value === "") {
          error["company"] = "*Required";
        } else {
          error["company"] = false;
        }
        break;

      case "mobile":
        if (value === "") {
          error["mobileNo"] = "*Required";
        } else if (!contactValidation.test(value)) {
          error["mobileNo"] = "Enter valid mobile no.";
        } else {
          error["mobileNo"] = false;
          details["mobile"] = value.replace(/[^0-9 ]/gi, "");
        }
        break;

      case "whatsapp":
        if (value === "") {
          error["whatsapp"] = "*Requaired";
          error["social"] = false;
        } else if (!contactValidation.test(value)) {
          error["whatsapp"] = "Enter valid whatsapp no.";
        } else {
          error["whatsapp"] = false;
          error["social"] = false;
        }
        break;

      // case "wechat":
      //   if (value === "") {
      //     error["wechat"] = "*Required";
      //   } else {
      //     error["wechat"] = false;
      //     error["social"] = false;
      //   }
      //   break;

      // case "skype":
      //   if (value === "") {
      //     error["skype"] = "*Required";
      //   } else {
      //     error["skype"] = false;
      //     error["social"] = false;
      //   }
      //   break;

      case "proof":
        if (value === "" && details.document === "") {
          error["proof"] = "*Required";
        } else if (
          !(
            e.target.files[0]?.type === "application/msword" ||
            e.target.files[0]?.type === "application/pdf"
          )
        ) {
          error["proof"] = "Only Accept word or pdf file";
        } else {
          error["proof"] = false;
        }
        break;

      default:
        break;
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (details?.username === "") {
      error["userName"] = "*Required";
    } else if (details?.email === "") {
      error["email"] = "*Required";
    } else if (details.fullname === "") {
      error["name"] = "*Required";
    } else if (details.country_id === "") {
      error["country"] = "*Required";
    } else if (details.company === "") {
      error["company"] = "*Required";
    } else if (details.mobile === "") {
      error["mobileNo"] = "*Required";
    } else if (
      details.whatsapp === "" &&
      details.wechat === "" &&
      details.skype === ""
    ) {
      error["social"] = "Fill atlist one contact detail";
    } else if (details.document === "") {
      error["proof"] = "*Required";
    }

    if (
      details.username !== "" &&
      details.email !== "" &&
      details.fullname !== "" &&
      details.country_id !== "" &&
      details.company !== "" &&
      details.mobile !== "" &&
      (details.whatsapp !== "" ||
        details.wechat !== "" ||
        details.skype !== "") &&
      !error.proof &&
      !error.whatsapp &&
      !error.wechat &&
      !error.skype &&
      !error.social
    ) {
      delete details["proof"];
      props.updateProfile({
        ...details,
      });
      // setLoading(true);
    }
  };

  useEffect(() => {
    if (props.updateProfileReducer.success) {
      // setLoading(false);
      props.getProfile();
      navigate("/dashboard");
      props.resetUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updateProfileReducer]);

  return (
    <>
      <Header />
      {!!details && (
        <main className="userMain pt-5">
          <div className="d-flex justify-content-between   mb-4 flex-wrap">
            <h2 className="heading mb-0">Change Profile</h2>
          </div>
          <div className="content-block mb-5">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="profile" title="Profile">
                <div className="col-md-6 mt-5">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <td>User Name</td>
                        <td>:</td>
                        <td>
                          {details?.username !== "" && !!details?.username
                            ? details?.username
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td> Email</td>
                        <td>:</td>
                        <td>
                          {details?.email !== "" && !!details?.email
                            ? details?.email
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td> Country</td>
                        <td>:</td>
                        <td>
                          {details?.country_id !== "" && !!details?.country_id
                            ? countryList?.find(
                                (e) => e.id === details?.country_id
                              )?.name
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td> Company</td>
                        <td>:</td>
                        <td>
                          {details?.company !== "" && !!details?.company
                            ? details?.company
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td> Mobile No.</td>
                        <td>:</td>
                        <td>
                          {details?.mobile !== "" && !!details?.mobile
                            ? details?.mobile
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td> Whatsapp</td>
                        <td>:</td>
                        <td>
                          {details?.whatsapp !== "" && !!details?.whatsapp
                            ? details?.whatsapp
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td> Wechat</td>
                        <td>:</td>
                        <td>
                          {details?.wechat !== "" && !!details?.wechat
                            ? details?.wechat
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td>Skype</td>
                        <td>:</td>
                        <td>
                          {details?.skype !== "" && !!details?.skype
                            ? details?.skype
                            : "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab>
              <Tab eventKey="editProfile" title="Edit Profile">
                <div className="col-md-6 mt-5">
                  <Form onSubmit={handleUpdate}>
                    {/* Login information */}
                    <h4 className="mb-3">Login Information</h4>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-0">Username</Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            value={details?.username}
                            placeholder="Enter Username"
                            onChange={handleInput}
                          />
                          {error.userName && (
                            <span className="form-error">{error.userName}</span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-0">Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter Email"
                            value={details?.email}
                            onChange={handleInput}
                          />
                          {error.email && (
                            <span className="form-error">{error.email}</span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* Personal information */}
                    <h4 className="mt-5 mb-3">Personal Information</h4>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-0">Full Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="fullname"
                            placeholder="Enter Full name"
                            value={details?.fullname}
                            onChange={handleInput}
                          />
                          {error.fullname && (
                            <span className="form-error">{error.fullname}</span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-0">Country</Form.Label>
                          <Form.Select
                            id="country_id"
                            value={details?.country_id}
                            onChange={handleInput}
                            name="country_id"
                          >
                            <option>Select</option>
                            {countryList?.map((data, i) => {
                              return (
                                <option key={i} value={data.id}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          {error.country && (
                            <span className="form-error">{error?.country}</span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-0">Company</Form.Label>
                          <Form.Control
                            type="text"
                            value={details?.company}
                            onChange={handleInput}
                            name="company"
                            id="company"
                            placeholder="Enter Company name"
                          />
                          {error.company && (
                            <span className="form-error">{error.company}</span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-0">Mobile No.</Form.Label>
                          <Form.Control
                            type="Number"
                            maxLength={10}
                            value={details?.mobile}
                            onChange={handleInput}
                            name="mobile"
                            id="mobile"
                            placeholder="Enter Mobile No."
                          />
                          {error.mobileNo && (
                            <span className="form-error">{error.mobileNo}</span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* Contact information */}
                    <h4 className="mt-5 mb-0">Contact Detail</h4>
                    <small className="mb-3 d-block">
                      Fill at least one contact detail
                    </small>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-0">Whatspp</Form.Label>
                          <Form.Control
                            type="number"
                            value={details?.whatsapp}
                            onChange={handleInput}
                            name="whatsapp"
                            id="whatsapp"
                            placeholder=""
                          />
                          {error.whatsapp && (
                            <span className="form-error">{error.whatsapp}</span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-0">weChat</Form.Label>
                          <Form.Control
                            type="text"
                            value={details?.wechat}
                            onChange={handleInput}
                            name="wechat"
                            id="wechat"
                            placeholder=""
                          />
                          {error.wechat && (
                            <span className="form-error">{error.wechat}</span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-0">Skype</Form.Label>
                          <Form.Control
                            type="text"
                            value={details?.skype}
                            onChange={handleInput}
                            name="skype"
                            id="skype"
                            placeholder=""
                          />
                          {error.skype && (
                            <span className="form-error">{error.skype}</span>
                          )}
                          {error.social && (
                            <span className="form-error">{error.social}</span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <label htmlFor="formFile" className="form-label">
                        Business Proof Document *
                      </label>
                      {details?.document === "" ? (
                        <input
                          className="form-control form-control-lg w-100"
                          type="file"
                          id="proof"
                          accept="application/msword, application/pdf"
                          name="proof"
                          value={details?.proof}
                          onChange={handleInput}
                        />
                      ) : (
                        <div className="position-relative">
                          <input
                            className="form-control w-100"
                            value={
                              typeof details?.document === "string"
                                ? details?.document
                                : details?.document?.name
                            }
                            type="text"
                            style={{ fontSize: "16px" }}
                            disabled
                          />
                          <div
                            className="position-absolute"
                            style={{
                              cursor: "pointer",
                              top: "8px",
                              right: "20px",
                            }}
                            onClick={() => {
                              setDetails((prev) => ({
                                ...prev,
                                proof: "",
                                document: "",
                              }));
                            }}
                          >
                            <i className="fa fa-times-circle"></i>
                          </div>
                        </div>
                      )}
                      {error.proof && (
                        <span className="form-error">{error.proof}</span>
                      )}
                    </div>
                    <Button type="submit" className="mt-4 text-white">
                      Submit
                    </Button>
                  </Form>
                </div>
              </Tab>
              <Tab eventKey="t&c" title="Terms & Condition">
                <h4 className="mt-5 mb-4">Terms & Conditiom</h4>

                <p className="mb-4">
                  We are Working On the Motto “Ethics & Honesty Having More
                  Value than Money”
                </p>

                <h5 className="fw-bold mb-3">Customer Satisfaction</h5>

                <p className="mb-4">
                  “DELIGHT DIAMONDS” is an established institution in the
                  diamond business since 2021. We are members of the Surat
                  Diamond Bourse Diamond, Polygon, and our sterling reputation
                  is international. We are truly dedicated to satisfying our
                  customers as best as we can with our prompt and reliable
                  service and hope to make this your most comfortable online
                  shopping experience.
                </p>

                <p className="mb-4">
                  Upon request, we will provide a complete and accurate
                  description compiled by our highly experienced gemmologists /
                  graders for all purchases. We also provide Make to order and
                  customization on as per requirement.
                </p>

                <p>
                  All diamonds, and jewelry sold by Delight Diamonds are
                  authentically Sourced as specified and of a consistently high
                  quality and value. Independent laboratory certificates from
                  GIA, IGI, EGL, AGS, HRD or other are obtainable on request for
                  all diamonds and colored diamonds.
                </p>
              </Tab>
            </Tabs>
          </div>
        </main>
      )}
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  profileReducer: state.authReducer.profile,
  updateProfileReducer: state.authReducer.updateProfile,
  countryReducer: state.authReducer.getCountry,
});
const mapDispatchToProps = (dispatch) => ({
  countryList: () => dispatch(getCountry()),
  getProfile: (payload) => dispatch(getProfile(payload)),
  updateProfile: (details) => dispatch(updateProfile(details)),
  resetUpdate: (details) => dispatch({ type: UPDATE_PROFILE_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
