import React from "react";
import { Modal } from "react-bootstrap";

const ConfirmStoneModal = (props) => {
      return (
            <Modal show={props.show} size="lg" onHide={props.onHide} dialogClassName="detail-modal">
                  <Modal.Header closeButton>
                        <Modal.Title>Confirm Stone</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                        <div className="row mb-5">
                              <div className="col-md-3">
                                    <label>Type</label>
                                    <h6>HPHT</h6>
                              </div>
                              <div className="col-md-3">
                                    <label>Shape</label>
                                    <h6>L-CUSHION</h6>
                              </div>
                              <div className="col-md-3">
                                    <label>Size</label>
                                    <h6>1.010</h6>
                              </div>
                              <div className="col-md-3">
                                    <label>Color</label>
                                    <h6>D</h6>
                              </div>
                              <div className="col-md-3">
                                    <label>Clarity</label>
                                    <h6>VVS2</h6>
                              </div>
                              <div className="col-md-3">
                                    <label>Cut</label>
                                    <h6>NAN</h6>
                              </div>
                              <div className="col-md-3">
                                    <label>Polish</label>
                                    <h6>EX</h6>
                              </div>
                              <div className="col-md-3">
                                    <label>Sym</label>
                                    <h6>EX</h6>
                              </div>
                              <div className="col-md-3">
                                    <label>Flor</label>
                                    <h6>NON</h6>
                              </div>
                              <div className="col-md-3">
                                    <label>Amount($)</label>
                                    <h6>1,016.40</h6>
                              </div>
                        </div>
                        <textarea className="mb-3 form-control"rows={5} placeholder="Write your message..."></textarea>
                        <button type="submit" className="btn btn-primary text-white">Submit</button>
                  </Modal.Body>
            </Modal>
      )
}

export default ConfirmStoneModal